import { useContext, useEffect, useRef, useState } from "react";
import "../../../../src/styles/component.css";
import fileupload from "../../../images/fileupload.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import React from "react";
import pdfImg from "../../../images/pdfimg.svg";
import AuthContext, { AuthContextType } from "../../../Contexts/AuthContext";
import FileUploadInfo from "./FileUploadInfo";
import error from "../../../images/error.svg";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import {
  GET_STARTUP_LISTING,
  GET_SUBSEQUENT_UPLOADS,
  Get_St_Application_File,
  SUBSEQUENT_ST_APPLICATIONS,
  NODE_URL,
  STORE_TRANCHE_REQUEST,
  SUBSEQUENT_UPLOAD,
  Subsequent_ST_File_upload,
  UPDATE_FILE_STATUS,
  secgreybox,
} from "../../../constants/api";
import { post } from "../../../effects/api/api.common";
import { userInfo } from "os";
import moment from "moment";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import axios from "axios";

const fieldnames = [
  "utilization_certificate_file_name",
  "audited_statement_file_name",
  "interest_earned_file_name",
  "ismsc_meetings_file_name",
  "proof_of_achievement_file_name",
  "instrument_type_details_file_name",
  "grant_disbursed_dtls_file_name",
  "selected_startup_declation_file_name",
  "List_of_Startups_file_name",
  "proof_of_pfms_file_name",
  "previous_sanction_order_file_name",
  "tra_agreement_file_name",
  "optional_other_1_file_name",
  "optional_other_2_file_name",
  "optional_other_3_file_name",
  "optional_other_4_file_name",
  "optional_other_5_file_name",
];

const inc_pathnames = [
  "utilization_certificate_file_path",
  "audited_statement_file_path",
  "interest_earned_file_path",
  "ismsc_meetings_file_path",
  "proof_of_achievement_file_path",
  "instrument_type_details_file_path",
  "grant_disbursed_dtls_file_path",
  "selected_startup_declation_file_path",
  "List_of_Startups_path_name",
  "proof_of_pfms_file_path",
  "previous_sanction_order_file_path",
  "tra_agreement_file_path",
  "optional_other_1_file_path",
  "optional_other_2_file_path",
  "optional_other_3_file_path",
  "optional_other_4_file_path",
  "optional_other_5_file_path",
];

const st_pathnames = [
  "ca_file_path_file_path",
  "uc_file_path_file_path",
  "pr_file_path_file_path",
  "legal_agreement_file_path",
];
const st_pathnames1 = [
  "ca_file_path_file_path",
  "legal_agreement_file_path",
  "pr_file_path_file_path",
  "uc_file_path_file_path",
];
const colorIndication = ["Submittedbg", "Readbg", "Acceptedbg", "Rejectedbg"];

const statusfields = [
  "utilization_certificate_status_ind",
  "audited_statement_status_ind",
  "interest_earned_status_ind",
  "ismsc_meetings_status_ind",
  "proof_of_achievement_status_ind",
  "instrument_type_details_status_ind",
  "grant_disbursed_dtls_status_ind",
  "selected_startup_declation_status_ind",
  "List_of_Startups_status_ind",
  "proof_of_pfms_status_ind",
  "previous_sanction_order_status_ind",
  "tra_agreement_status_ind",
];

const st_status_fields = [
  "ca_file_path_status_ind",
  "uc_file_path_status_ind",
  "pr_file_path_status_ind",
  "legal_file_path_status_ind",
];

const all_inc_status_fields = [
  "utilization_certificate_status_ind",
  "audited_statement_status_ind",
  "interest_earned_status_ind",
  "ismsc_meetings_status_ind",
  "proof_of_achievement_status_ind",
  "instrument_type_details_status_ind",
  "grant_disbursed_dtls_status_ind",
  "selected_startup_declation_status_ind",
  "List_of_Startups_status_ind",
  "proof_of_pfms_status_ind",
  "previous_sanction_order_status_ind",
  "tra_agreement_status_ind",
  "optional_other_1_status_ind",
  "optional_other_2_status_ind",
  "optional_other_3_status_ind",
  "optional_other_4_status_ind",
  "optional_other_5_status_ind",
];

const inc_status_fields = [
  "optional_other_1_status_ind",
  "optional_other_2_status_ind",
  "optional_other_3_status_ind",
  "optional_other_4_status_ind",
  "optional_other_5_status_ind",
];
// import SubsequentFileUploads from './SubsequentFileUploads';

interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function SubsequentTrancheTwo() {
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFile2, setSelectedFile2] = useState<any>();
  const [selectedFile3, setSelectedFile3] = useState<any>();
  const [selectedFile4, setSelectedFile4] = useState<any>();
  const [selectedFile5, setSelectedFile5] = useState<any>();
  const [selectedFile6, setSelectedFile6] = useState<any>();
  const [filename, setFilename] = useState("");
  const fileInputRef = useRef<any>(null);
  const history = useHistory();
  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const { utilizationFile, setUtizationFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { auditedFile, setAuditedFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { interestFile, setInterestFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { ismcFile, setIsmcFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { achievementFile, setachievementFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { debentureFile, setdebentureFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { disbursedFile, setdisbursedFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { declarationFile, setdeclarationFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { legalAgreementFile, setlegalAgreementFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { pfmsFile, setpfmsFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { sanctionFile, setsanctionFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { traFIle, settraFIle } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { listStartups, setListStartups } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const [loader, setLoader] = useState(false);
  const { getFilesData, setGetFilesData } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { getFilesData1, setGetFilesData1 } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const [getStartupsData, setGetStartupsData] = useState<any>();
  const [errorBox, setErrorBox] = useState(false);
  const [errorBox1, setErrorBox1] = useState(false);
  const [exceedFileSize, setExceedFileSize] = useState(false);
  const [exceedSTFileSize, setExceedSTFileSize] = useState(false);
  const [otherDocExceedSize, setOtherDocExceedSize] = useState(false);
  const [exceedFileSize1, setExceedFileSize1] = useState(false);
  const [draftPopUp, setDraftPopup] = useState(false);
  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const [showSubmitFailureModal, setSubmitFailureModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [submitPopup, setSubmitPopup] = useState(false);
  const [getStUploadsData, setGetStUploaadsData] = useState<any>();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [getAllZipFiles, setGetZipFile] = useState(false);
  const [newState, setNewState] = useState<any>();
  const [tranche_message, setMessage] = useState("");
  const location: any = useLocation();
  const [propData, setPropData] = useState(location);
  const [checkValue, setCheckValue] = useState(false);
  const [getGreyboxDtls, setGetGreyboxDtls] = useState<any>();
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [stGetBackgrounds, setStBackgrounds] = useState<any>([]);
  const [getIncBgColor, setIncBgColor] = useState<any>();
  const [submitBtn, setSubmitBtn] = useState(true);
  const [submitBtnForST, setSubmitBtnForST] = useState(true);
  const [status, setStatus] = useState();
  const [showUploadFailureModal, setShowUploadFailureModal] = useState(false);
  const [fileConfirmationPopup, setFileConfirmationPopup] = useState(false);
  const [readFileFirst, setReadFileFirst] = useState<any>();
  const [incFileConfirmationPopup, setIncFileConfirmationPopup] =
    useState(false);
  const [get_ZipFiles, setGetZipFiles] = useState<any>([[], []]);
  const [getIncZipFile, setIncZipFile] = useState();
  const [getSTZipFiles, setSTZipFiles] = useState();
  const [rejectedInfo, ssetRejectedInfo] = useState(false);
  const [rejectedInfo1, ssetRejectedInfo1] = useState(false);
  const [currentFileDetails, setCurrentFileDetails] = useState({
    name: "",
    status: "",
    action: "",
    file_no: "",
    comment: [],
    st_application_id: "",
  });
  const [rejectedText, setRejectedText] = useState<any>();
  const [loggedInRole, setLoggedInRole] = useState("");
  const [loggedInId, setLoggedInId] = useState("");
  const params: any = useParams();
  const [all_IncubatorFiles, setAllIncubatorFiles] = useState<any>([]);
  const [all_StartupFiles, setAllStartupFiles] = useState<any>([]);
  const [incNo, setincNo] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    getFileUploads();
  }, []);
  // useEffect(() => {
  //   if (getSessionStorageValue("user")) {
  //     const user: any = getSessionStorageValue("user");
  //     const userInfo = JSON.parse(user);
  //     setLoggedInRole(userInfo.roles);
  //     setLoggedInId(userInfo.id);
  //   }
  //   // console.log(getFilesData);
  //   if (
  //     (getFilesData && getFilesData.optional_other_1_status_ind != null) ||
  //     (getFilesData && getFilesData.optional_other_2_status_ind != null) ||
  //     (getFilesData && getFilesData.optional_other_3_status_ind != null) ||
  //     (getFilesData && getFilesData.optional_other_4_status_ind != null)
  //   ) {
  //     setCheckValue(true);
  //   }
  // }, [submitBtn, getFilesData?.status_ind]);
  useEffect(() => {
    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      setLoggedInRole(userInfo.roles);
      setLoggedInId(userInfo.id);
    }

    // Ensure getFilesData is available before accessing status_ind
    if (getFilesData) {
      // Check if getFilesData has the status_ind property and update checkValue
      const hasRequiredData =
        getFilesData.optional_other_1_status_ind != null ||
        getFilesData.optional_other_2_status_ind != null ||
        getFilesData.optional_other_3_status_ind != null ||
        getFilesData.optional_other_4_status_ind != null;

      console.log("getFilesData:", getFilesData);
      setCheckValue(hasRequiredData);
    }
  }, [submitBtn, getFilesData, getFilesData?.status_ind]); // Add getFilesData?.status_ind to dependencies

  useEffect(() => {
    getStartupsNames();
    getSTFileUploads();
  }, []);

  const getFileUploads = async () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = params?.id ? params?.id : userInfo.id;
    setLoader(true);
    setTimeout(() => {
      console.log("params", params);
      post(GET_SUBSEQUENT_UPLOADS, {
        userId: userId,
        TrancheNumber: params.tranche_number,
        application_ind: params.app_index,
        incubator_application_no: params.incubator_application_no,
      }).then((response: any) => {
        // console.log(response.data.data[0], "detailsss")
        if (!response.data || !response.data.data) return;
        setGetFilesData(response.data.data[0]);
        const newArray: any = [];

        fieldnames.forEach((fieldname: any, index: any) => {
          const matchingData = response.data.data.find(
            (item: any) => item[fieldname]
          );

          if (matchingData) {
            newArray.push({
              file_original_name: "incubator",
              name: matchingData[fieldname],
              url: NODE_URL + matchingData[inc_pathnames[index]],
            });
          }
        });
        setGetZipFiles((prevState: any) => [
          [...prevState[0], ...newArray],
          prevState[1],
        ]);
        // console.log(response.data.data)
        setIncZipFile(response.data.incubatrfilesZip);
        setGetFilesData1(response.data.data);
        setStatus(response.data.data[0].status_ind);
        if (
          response.data.data[0].optional_other_1_file_name ||
          response.data.data[0].optional_other_2_file_name ||
          response.data.data[0].optional_other_3_file_name ||
          response.data.data[0].optional_other_4_file_name ||
          response.data.data[0].optional_other_5_file_name
        ) {
          setCheckValue(true);
        }

        setListStartups(response.data.data[0].List_of_Startups_file_name);
        setUtizationFile(
          response?.data?.data[0].utilization_certificate_file_name
        );
        setAuditedFile(response.data.data[0].audited_statement_file_name);
        setInterestFile(response.data.data[0].interest_earned_file_name);
        setIsmcFile(response.data.data[0].ismsc_meetings_file_name);
        setachievementFile(
          response.data.data[0].proof_of_achievement_file_name
        );
        setdebentureFile(
          response.data.data[0].instrument_type_details_file_name
        );
        setdisbursedFile(response.data.data[0].grant_disbursed_dtls_file_name);
        setdeclarationFile(
          response.data.data[0].selected_startup_declation_file_name
        );
        setlegalAgreementFile(response.data.data[0].legal_agreements_file_name);
        setpfmsFile(response.data.data[0].proof_of_pfms_file_name);
        setsanctionFile(
          response.data.data[0].previous_sanction_order_file_name
        );
        settraFIle(response.data.data[0].tra_agreement_file_name);

        let incBackgroundColors: any = [];
        let tranche_label = localStorage.getItem("trancheLabel");
        for (let i = 0; i < inc_status_fields.length; i++) {
          if (response.data.data[0][inc_status_fields[i]] == 0) {
            setSubmitBtn(false);
            // console.log("111111")
            ssetRejectedInfo1(true);
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              incBackgroundColors.push(`Acceptedbg`);
            } else {
              incBackgroundColors.push("Rejectedbg");
            }
          }
          if (response.data.data[0][inc_status_fields[i]] == 1) {
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              incBackgroundColors.push(`Acceptedbg`);
            } else {
              incBackgroundColors.push("savedbg");
            }
          }
          if (response.data.data[0][inc_status_fields[i]] == 2) {
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              incBackgroundColors.push(`Acceptedbg`);
            } else {
              incBackgroundColors.push("Submittedbg");
            }
          }
          if (response.data.data[0][inc_status_fields[i]] == 3) {
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              incBackgroundColors.push(`Acceptedbg`);
            } else {
              incBackgroundColors.push("Readbg");
            }
          }
          if (response.data.data[0][inc_status_fields[i]] == 4) {
            incBackgroundColors.push("Acceptedbg");
          }
          if (response.data.data[0][inc_status_fields[i]] == null) {
            incBackgroundColors.push("");
          }
          // if(i%2 == 0){
          //     incBackgroundColors.push('readbg')
          // }else{
          //     incBackgroundColors.push('approvedbg')
          // }
        }
        if (response?.data.data[0].status_ind !== null) {
          setIncBgColor(incBackgroundColors);
        }

        // setSubmitBtn(isAnyValueLessThanTwo(response.data.data, statusfields));
      });
      setLoader(false);
    }, 1000);
  };

  const getSTFileUploads = async () => {
    var currentdatenew = moment().format("YYYY-MM-DD");
    // console.log("mohaaaaaaaaaaaaaaaaaaaaaaaaaannnnnnnnnnnnn");
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = params?.id ? params?.id : userInfo.id;
    // setLoader(true);
    let inc_application_num: any;
    await post(secgreybox, { user_id: userId }).then(async (response: any) => {
      // setLoader(false);
      for (let i = 0; i < response.data.Data.length; i++) {
        if (response.data.Data[i].application_index === params.app_index) {
          // console.log(response.data.Data[i])
          setGetGreyboxDtls(response.data.Data[i]);
          setincNo(response.data.Data[i].incubator_application_no);
          inc_application_num = response.data.Data[i].incubator_application_no;
        }
      }
    });

    post(GET_SUBSEQUENT_UPLOADS, {
      userId: userId,
      TrancheNumber: params.tranche_number,
      application_ind: params.app_index,
      incubator_application_no: inc_application_num,
    }).then((response: any) => {
      if (!response.data || !response.data.data) return;
      setIncZipFile(response.data.incubatrfilesZip);
      setGetFilesData1(response.data.data);
    });
    // console.log(inc_application_num)
    // setLoader(true);
    const data0 = await post(Get_St_Application_File, {
      userId: userId,
      TrancheNumber: params.tranche_number,
      applicationId: params.app_index,
      incubator_application_no: inc_application_num,
    }).then((response: any) => {
      if (!response || !response.data) return;
      let getStFiles: any = [];
      for (let i = 0; i < response.data.data.length; i++) {
        getStFiles.push({
          file_original_name: "CA_Certificate",
          name: response.data.data[i].ca_file_path_file_name,
          url: NODE_URL + response.data.data[i].ca_file_path_file_path,
        });
        getStFiles.push({
          file_original_name: "UC_Certificate",
          name: response.data.data[i].uc_file_path_file_name,
          url: NODE_URL + response.data.data[i].uc_file_path_file_path,
        });
        getStFiles.push({
          file_original_name: "PR_Certificate",
          name: response.data.data[i].pr_file_path_file_name,
          url: NODE_URL + response.data.data[i].pr_file_path_file_path,
        });
        getStFiles.push({
          file_original_name: "LA_Certificate",
          name: response.data.data[i].legal_agreement_file_name,
          url: NODE_URL + response.data.data[i].legal_agreement_file_path,
        });
      }

      setSTZipFiles(response.data.startupFilesZip);
      setGetZipFiles((prevState: any) => [
        prevState[0],
        [...prevState[1], ...getStFiles],
      ]);
      // setGetZipFiles([getStFiles]);
      // setLoader(false);
      if (response.data.data[0].status_ind == 4) {
        setGetZipFile(true);
      }
      setGetStUploaadsData(response.data.data[0]);
      return response;
    });

    var payload = {
      instrument_applying_for: "",
      logged_user_id: userId,
      sector_id: null,
      state_id: null,
      status_id: [7, 8],
      submitted_date: "",
      userRole: "incubator",
      logged_role: userInfo.roles,
    };
    setLoader(true);
    var allotedToYou: any;
    var earlyClose: any;
    var fullClosed: any;
    await post(SUBSEQUENT_ST_APPLICATIONS, payload).then((response: any) => {
      setLoader(false);
      console.log(response.data.applications);
      // allotedToYou = response.data.applications.filter(
      //   (item: any) =>

      //     item.already_selected_flag != 1 &&
      //     item.incubator_preferences_data == 7 &&
      //     item.startup_status_id == 7 && item.incubator_preferences_data == 8 &&
      //     item.evaluation_to_be_completed_by < currentdatenew
      // );
      allotedToYou = response.data.applications.filter((item: any) => {
        const evaluationDate = new Date(item.evaluation_to_be_completed_by);
        const currentDate = new Date(currentdatenew);

        return (
          item.already_selected_flag != 1 &&
          (item.incubator_preferences_data == 7 ||
            item.incubator_preferences_data == 8) &&
          (item.startup_status_id == 7 || item.startup_status_id == 8) &&
          evaluationDate < currentDate
        );
      });
      earlyClose = response.data.applications.filter(
        (item: any) => item.early_closure == "yes"
      );

      fullClosed = response.data.applications.filter(
        (item: any) =>
          item.total_applroved_amount == item.total_disbursed_amount &&
          item.incubator_preferences_data == 8
      );
      setGetStartupsData(response.data.applications);
      return response;
    });
    // ...earlyClose, ...fullClosed
    let data1 = [...allotedToYou];
    // console.log(earlyClose)
    // console.log(fullClosed)
    console.log(data1);
    let newArr: any = [];

    // console.log(data0)
    const renamedApplicationData =
      data0 === undefined
        ? []
        : data0.data.data.map((app: any) => {
            const { id, name_of_entity, ...rest } = app;
            return {
              ...rest,
              startup_tbl_id: id,
              st_name_of_entity: name_of_entity,
            };
          });
    // console.log(renamedApplicationData);
    let combinedData: any;
    if (
      renamedApplicationData.length > 0 &&
      (renamedApplicationData[0].status_ind == 2 ||
        renamedApplicationData[0].status_ind == 4)
    ) {
      combinedData =
        renamedApplicationData &&
        renamedApplicationData.map((startup: any) => {
          const matchingApplication =
            data1 &&
            data1.find((app: any) => app.id === startup.startup_application_id);

          let arr = { ...startup, ...matchingApplication };
          return arr;
        });
    } else {
      combinedData =
        data1 &&
        data1.map((startup: any) => {
          const matchingApplication =
            renamedApplicationData &&
            renamedApplicationData.find(
              (app: any) => app.startup_application_id === startup.id
            );

          let arr = { ...startup, ...matchingApplication };
          return arr;
        });
    }
    console.log("combinedData", combinedData);
    setNewState(combinedData);
    let stBackgroundColors: any = [];
    let tranche_label = localStorage.getItem("trancheLabel");
    for (let i = 0; i < combinedData.length; i++) {
      for (let j = 0; j < st_status_fields.length; j++) {
        if (combinedData[i][st_status_fields[j]] == 0) {
          ssetRejectedInfo(true);
          if (
            tranche_label == "Verification of Documents Completed" &&
            loggedInRole != "secretariat"
          ) {
            stBackgroundColors.push(`Acceptedbg`);
          } else {
            stBackgroundColors.push(`Rejectedbg`);
          }
        }
        if (combinedData[i][st_status_fields[j]] == 1) {
          setSubmitBtnForST(true);
          if (
            tranche_label == "Verification of Documents Completed" &&
            loggedInRole != "secretariat"
          ) {
            stBackgroundColors.push(`Acceptedbg`);
          } else {
            stBackgroundColors.push(`savedbg`);
          }
        }
        if (combinedData[i][st_status_fields[j]] == 2) {
          if (
            tranche_label == "Verification of Documents Completed" &&
            loggedInRole != "secretariat"
          ) {
            stBackgroundColors.push(`Acceptedbg`);
          } else {
            stBackgroundColors.push(`Submittedbg`);
          }
        }
        if (combinedData[i][st_status_fields[j]] == 3) {
          if (
            tranche_label == "Verification of Documents Completed" &&
            loggedInRole != "secretariat"
          ) {
            stBackgroundColors.push(`Acceptedbg`);
          } else {
            stBackgroundColors.push(`Readbg`);
          }
        }
        if (combinedData[i][st_status_fields[j]] == 4) {
          setSubmitBtnForST(false);
          stBackgroundColors.push(`Acceptedbg`);
        }
        if (combinedData[i][st_status_fields[j]] == undefined) {
          stBackgroundColors.push(`null`);
        }
      }
    }
    const twoDimensionalArray = [];

    for (let i = 0; i < stBackgroundColors.length; i += 4) {
      const chunk = stBackgroundColors.slice(i, i + 4);
      twoDimensionalArray.push(chunk);
    }
    if (data0 !== undefined && data0.data.data[0].status_ind !== null) {
      setStBackgrounds(twoDimensionalArray);
    }
    // for submit button disable
    // console.log(getFilesData1)
    // console.log(combinedData)
    let getAllUploads: any = [];
    let allIncubatorFiles: any = [];
    let allStartupFiles: any = [];
    let fields: any = [getFilesData1, combinedData].flat();

    if (getFilesData1?.length && combinedData.length) {
      for (let i = 0; i < fields.length; i++) {
        for (let j = 0; j < st_status_fields.length; j++) {
          if (
            fields[i][st_status_fields[j]] == 0 ||
            fields[i][st_status_fields[j]] == 1 ||
            fields[i][st_status_fields[j]] == 2 ||
            fields[i][st_status_fields[j]] == 3 ||
            fields[i][st_status_fields[j]] == 4 ||
            fields[i][st_status_fields[j]] == null
          ) {
            getAllUploads.push(fields[i][st_status_fields[j]]);
          }
          if (fields[i][st_pathnames[j]]) {
            allStartupFiles.push(NODE_URL + fields[i][st_pathnames[j]]);
          }
        }
        for (let k = 0; k < all_inc_status_fields.length; k++) {
          if (
            fields[i][all_inc_status_fields[k]] == 0 ||
            fields[i][all_inc_status_fields[k]] == 1 ||
            fields[i][all_inc_status_fields[k]] == 2 ||
            fields[i][all_inc_status_fields[k]] == 3 ||
            fields[i][all_inc_status_fields[k]] == 4
          ) {
            getAllUploads.push(fields[i][all_inc_status_fields[k]]);
          }
          if (fields[i][inc_pathnames[k]]) {
            allIncubatorFiles.push(NODE_URL + fields[i][inc_pathnames[k]]);
          }
        }
        // for (let k = 0; k < inc_status_fields.length; k++) {
        //     if (fields[i][inc_status_fields[k]] == 0 || fields[i][inc_status_fields[k]] == 1 || fields[i][inc_status_fields[k]] == 2 || fields[i][inc_status_fields[k]] == 3 || fields[i][inc_status_fields[k]] == 4) {
        //         getAllUploads.push(fields[i][inc_status_fields[k]]);
        //     }
        //     if (fields[i][inc_pathnames[k]]) {
        //         allIncubatorFiles.push(NODE_URL + fields[i][inc_pathnames[k]]);
        //     }
        // }
      }
    }
    // console.log(fields)
    // console.log(getAllUploads)
    // console.log(allIncubatorFiles);
    // console.log(allStartupFiles);
    setAllIncubatorFiles(allIncubatorFiles);
    setAllStartupFiles(allStartupFiles);
    if (getAllUploads.includes(0)) {
      setSubmitBtn(true);
      // console.log("2222222")
    } else if (getAllUploads.includes(2) && !getAllUploads.includes(1)) {
      //    console.log("333333")
      setSubmitBtn(true);
    } else if (!getAllUploads.includes(null) && getAllUploads.includes(1)) {
      // console.log("4444444")
      setSubmitBtn(false);
    }

    // console.log(getAllUploads);
  };
  const getStartupsNames = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    // var payload = {
    //     instrument_applying_for: "",
    //     logged_user_id: userId,
    //     sector_id: null,
    //     state_id: null,
    //     status_id: "7",
    //     submitted_date: "",
    //     userRole: "incubator"
    // }

    // post(GET_STARTUP_LISTING, payload).then((response: any) => {
    //     setGetStartupsData(response)

    // })
  };

  const dismissModal = () => {
    setFileConfirmationPopup(false);
    setIncFileConfirmationPopup(false);
    setExceedFileSize(false);
    // setExceedFileSize1(false);
    setDraftPopup(false);
    setSubmitPopup(false);
    setShowFailureModal(false);
    setShowSuccessModal(false);
    setReadFileFirst("");
    setSubmitFailureModal(false);
  };
  const hanndleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setRejectedText(value);
  };

  const downloadAsZip = async () => {
    const zip = new JSZip();

    try {
      const file1Url = `${NODE_URL + "/" + getIncZipFile}`;
      const file2Url = `${NODE_URL + "/" + getSTZipFiles}`;

      const response1 = await fetch(file1Url);
      const response2 = await fetch(file2Url);

      const blob1 = await response1.blob();
      const blob2 = await response2.blob();

      const customFolderName1 = "Incubator";
      const customFolderName2 = "Startup";

      const folder1 = zip.folder(customFolderName1);
      const folder2 = zip.folder(customFolderName2);

      if (folder1 != null) {
        folder1.file("IncubatorFiles.zip", blob1);
      }
      if (folder2 != null) {
        folder2.file("StartupFiles.zip", blob2);
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(zipBlob);
      link.setAttribute(
        "download",
        `${getGreyboxDtls.incubator_application_no}_${params.tranche_number}.zip`
      ); // Set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading ZIP files:", error);
    }
    // try {
    //     setLoader(true);
    //     const zip = new JSZip();
    //     await Promise.all(
    //         get_ZipFiles.map(async (photoGroup: any, index: any) => {
    //             const groupName = index === 0 ? "Incubator" : "Startups"; // Use custom names based on index
    //             const groupFolder = zip.folder(groupName);

    //             if (groupName === "Startups") {
    //                 // Only for the "startup" group, create folders based on file_original_name
    //                 if (groupFolder != null) {
    //                     await Promise.all(
    //                         photoGroup.map(async (photo: any) => {
    //                             const response = await fetch(photo.url);
    //                             const blob = await response.blob();
    //                             const subFolderName = photo.file_original_name;
    //                             const subFolder = groupFolder.folder(subFolderName);
    //                             subFolder != null && subFolder.file(photo.name, blob, { binary: true });
    //                         })
    //                     );
    //                 }
    //             } else {
    //                 // For other groups, simply add files to the group folder
    //                 if (groupFolder != null) {

    //                     await Promise.all(
    //                         photoGroup.map(async (photo: any) => {
    //                             const response = await fetch(photo.url);
    //                             const blob = await response.blob();
    //                             groupFolder.file(photo.name, blob, { binary: true });
    //                         })
    //                     );
    //                 }
    //             }
    //         })
    //     );

    //     zip.generateAsync({ type: "blob" }).then((blob) => {
    //         saveAs(blob, `${getGreyboxDtls.incubator_application_no}_${params.tranche_number}`);
    //     });
    // } catch (error) {
    //     console.error('Error during download:', error);
    // } finally {
    //     setLoader(false);
    // }
  };
  useEffect(() => {}, [rejectedText]);
  const handleFileUpload = (event: any, id: any, entityName: any) => {
    const name = event.target.name;
    let file_no = name == "upload1" ? "0" : name == "upload2" ? "1" : "2";

    if (event.target.files[0]?.size > 2 * 1024 * 1024) {
      setExceedFileSize1(false);
      setOtherDocExceedSize(false);
      setExceedSTFileSize(true);
      setExceedFileSize(true);
      return;
    }
    const file = event.target.files[0];
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;

    const formData = new FormData();
    // return;
    // console.log(name, "dsddsds")
    // console.log(file_no, "dsddsds")
    // console.log(entityName, "dsddsds")
    // console.log(id)
    formData.append("file", file);
    formData.append("user_id", userId);
    formData.append("start_up_id", id);
    formData.append("FileNo", file_no);
    formData.append("name_of_entity", entityName);
    formData.append("TrancheNumber", params.tranche_number);
    formData.append("applicationId", params.app_index);
    setLoader(true);
    post(Subsequent_ST_File_upload, formData).then((response: any) => {
      setLoader(false);
      // console.log(response);
      if (!response?.data) {
        setFailDialogData({
          header: "",
          text: response.response.data.message,
        });
        setShowUploadFailureModal(true);
      }
      getSTFileUploads();
    });
  };
  const handleFileChange = (event: any, id: any, entityName: any) => {
    const name = event.target.name;
    // console.log(name)
    if (event.target.files[0]?.size > 15 * 1024 * 1024) {
      setOtherDocExceedSize(false);
      setExceedSTFileSize(false);
      setExceedFileSize1(true);
      setExceedFileSize(true);
      return;
    }
    const file = event.target.files[0];

    const file_no = "3";
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userId);
    formData.append("start_up_id", id);
    formData.append("FileNo", file_no);
    formData.append("name_of_entity", entityName);
    formData.append("TrancheNumber", params.tranche_number);
    formData.append("applicationId", params.app_index);
    setLoader(true);
    post(Subsequent_ST_File_upload, formData).then((response: any) => {
      setLoader(false);
      // console.log(response)
      if (!response?.data) {
        setFailDialogData({
          header: "",
          text: response.response.data.message,
        });
        setShowUploadFailureModal(true);
      }
      getSTFileUploads();
    });

    // if (name == "listStartup") {
    //     setListStartups(file)
    //     fileUpload(file, 12)
    //     getFileUploads();
    // }
  };
  const handleOtherFileUpload = (event: any) => {
    const name = event.target.name;
    let count = 12;

    const file = event.target.files[0];
    // if (files.length <= 5) {
    //     for (const file of files) {
    if (file.size > 19 * 1024 * 1024) {
      // count +=1;

      setExceedSTFileSize(false);
      setExceedFileSize1(false);
      setOtherDocExceedSize(true);
      setExceedFileSize(true);
      // return;
    } else {
      // const fileUpload = (file: any, file_no: any) => {

      var file_no: any;
      if (name == "upload4") {
        file_no = 12;
      }
      if (name == "upload5") {
        file_no = 13;
      }
      if (name == "upload6") {
        file_no = 14;
      }
      if (name == "upload7") {
        file_no = 15;
      }
      if (name == "upload8") {
        file_no = 16;
      }
      // console.log(name)
      // console.log(file_no)

      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      const userId = userInfo.id;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("FileNo", file_no);
      formData.append("user_id", userId);
      formData.append("status", "1");
      formData.append("TrancheNumber", params.tranche_number);
      formData.append("appplication_id", params.application_id);
      formData.append("application_ind", params.app_index);
      setLoader(true);

      // return;
      post(SUBSEQUENT_UPLOAD, formData).then((response: any) => {
        setLoader(false);
        getFileUploads();
        // console.log(response)
        if (!response?.data) {
          setFailDialogData({
            header: "",
            text: response.response.data.message,
          });
          setShowUploadFailureModal(true);
        }
        // console.log(response.message)
        // console.log(response?.data?.message[0])
      });
      // }
      count += 1;
    }
  };

  const previous = () => {
    history.push(
      params?.id
        ? `/secretariat/subsequent_tranche/${params?.id}/${params.tranche_number}/${params.app_index}/${params.application_id}/${params.trancheID}/${params.tranche_status}/${params.incubator_application_no}`
        : `/subsequent_tranche/${params.application_id}/${params.tranche_id}/${params.fund_request}/${params.cash_in_hand}/${params.tranche_number}/${params.app_index}/${params.incubator_application_no}`
    );
  };
  const submit = () => {
    const allObjectsHaveFields =
      newState &&
      newState.every(
        (obj: any) =>
          obj.ca_file_path_file_name &&
          obj.uc_file_path_file_name &&
          obj.pr_file_path_file_name
      );
    if (
      utilizationFile == null ||
      auditedFile == null ||
      interestFile == null ||
      ismcFile == null ||
      achievementFile == null ||
      debentureFile == null ||
      disbursedFile == null ||
      declarationFile == null ||
      pfmsFile == null ||
      sanctionFile == null ||
      traFIle == null ||
      listStartups == null
    ) {
      // console.log(utilizationFile, auditedFile, interestFile, debentureFile, disbursedFile, declarationFile, ismcFile, achievementFile, pfmsFile, sanctionFile, traFIle, listStartups)
      localStorage.setItem("errorKey", "false");
      setErrorBox1(true);
      if (!allObjectsHaveFields) {
        setErrorBox(true);
        // return;
      }
      return;
    } else {
      setErrorBox1(false);
    }
    // console.log(allObjectsHaveFields);
    if (!allObjectsHaveFields) {
      setErrorBox(true);
      return;
    }
    setConfirmationPopup(true);
  };
  const confirmSubmit = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    // return
    setConfirmationPopup(false);

    if (loggedInRole == "secretariat") {
      let sec_payload = {
        type: "APPLICATIONSTATUS",
        user_id: params.id,
        statusNo: 4,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: userId,
        tranche_id: params.trancheID,
      };
      post(UPDATE_FILE_STATUS, sec_payload).then((response: any) => {
        if (response.data.result == 500) {
          setErrorMessage(response.data.message);
          // setConfirmationPopup(false);
          setSubmitFailureModal(true);
          return;
        } else {
          setErrorMessage(response.data.data);
          // setConfirmationPopup(false);
          setSubmitFailureModal(true);
        }
      });
    } else {
      // const data_input1 = {
      //     application_id: params.application_id,
      //     tranche_id: params.tranche_id,
      //     funds_requested: params.fund_request,
      //     cash_in_hand: params.cash_in_hand,
      //     logged_user_id: userInfo.id,
      //     application_ind:params.app_index
      // };
      // console.log(data_input1)
      // return
      const payload_st = {
        userId: userId,
        status: 2,
        TrancheNumber: params.tranche_number,
        application_No: params.application_id,
        applicationId: params.app_index,
        incubator_application_no: getGreyboxDtls.incubator_application_no,
      };
      post(Get_St_Application_File, payload_st).then((response: any) => {
        // console.log(response, "responseeee22")
        if (!response.data) return;
        const areAllKeysEqual = st_status_fields.every((key) => {
          return response.data.data.every((obj: any) => obj[key] == 2);
        });
        setConfirmationPopup(false);
        // setSubmitPopup(true)
      });
      const payload_inc = {
        userId: userId,
        status: 2,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        incubator_application_no: getGreyboxDtls.incubator_application_no,
      };
      post(GET_SUBSEQUENT_UPLOADS, payload_inc).then((response: any) => {
        // console.log(response, "responseeee22")
        if (!response.data) return;
        setConfirmationPopup(false);
        const areAllKeysEqual = statusfields.every((key) => {
          return response.data.data.every((obj: any) => obj[key] == 2);
        });
        //   console.log(areAllKeysEqual)
      });
      const data_input = {
        application_id: params.application_id,
        tranche_id: params.tranche_id,
        funds_requested: params.fund_request,
        cash_in_hand: params.cash_in_hand,
        logged_user_id: userInfo.id,
        application_ind: params.app_index,
      };
      // setLoader(true);

      post(STORE_TRANCHE_REQUEST, data_input).then((response: any) => {
        setLoader(false);
        if (!response || !response.data || response.data == "") return;
        if (response.data != undefined && response.data != "") {
          if (response.data.result == "success") {
            setMessage("Tranche Request Submitted Successfully !!");
            setShowSuccessModal(true);

            // window.location.reload();
          } else {
            setShowFailureModal(true);
          }
        }
      });
    }

    // console.log(typeof propData)
    // console.log(propData.state.application_id)
  };
  const goToFundDetailsTab = () => {
    history.push({
      pathname: "/incubator_view_funddetails",
    });
  };
  const goToSecFundDetails = () => {
    if (loggedInRole == "secretariat") {
      history.replace({
        pathname: "/merged_Incubator_Details_list",
        state: {
          application_id: getGreyboxDtls.incubator_application_no,
          isFundDetails: false,
          userId: getGreyboxDtls.user_id,
          id: getGreyboxDtls.application_id,
          activeTab: "1",
          status_id: getGreyboxDtls.status_id,
          flag: "status",
        },
      });
    }
  };
  const setModalPopup = (name: string) => {
    setFilename(name);
  };
  // const handleCheckBox = (event: any) => {
  //   // console.log(getFilesData)
  //   if (event.target.checked) {
  //     setCheckValue(true);

  //     const allNUllValues = inc_status_fields.every(
  //       (key: any) => getFilesData[key] == null
  //     );

  //     if (allNUllValues) {
  //       setSubmitBtn(true);
  //       // console.log("55555555")
  //     } else {
  //       // console.log("666666666666")
  //       setSubmitBtn(false);
  //     }
  //   } else {
  //     setCheckValue(false);
  //     const user: any = getSessionStorageValue("user");
  //     const userInfo = JSON.parse(user);
  //     const userId = userInfo.id;
  //     var payload = {
  //       otherDocumentsDelete: true,
  //       userId: userId,
  //       incubator_application_no: getGreyboxDtls.incubator_application_no,
  //     };
  //     post(GET_SUBSEQUENT_UPLOADS, payload).then((response: any) => {
  //       setSubmitBtn(false);
  //       getFileUploads();
  //     });
  //   }
  // };
  const handleCheckBox = async (event: any) => {
    const isChecked = event.target.checked;
    setCheckValue(isChecked);

    console.log("Checkbox checked:", isChecked);

    if (isChecked) {
      if (getFilesData) {
        const allNullValues = inc_status_fields.every(
          (key: any) => getFilesData[key] == null
        );
        console.log("All null values:", allNullValues);
        setSubmitBtn(allNullValues);
      } else {
        console.warn("getFilesData is null or undefined");
        setSubmitBtn(false);
      }
    } else {
      console.log("Checkbox unchecked, preparing to call API");

      const user: any = getSessionStorageValue("user");
      if (!user) {
        console.error("User data is not available");
        return;
      }

      const userInfo = JSON.parse(user);
      const userId = params?.id ? params?.id : userInfo.id;

      const payload = {
        TrancheNumber: params.tranche_number,
        application_ind: params.app_index,
        userId: userId,
        incubator_application_no: getGreyboxDtls.incubator_application_no,
      };

      console.log("API payload:", payload);

      try {
        const response = await post(GET_SUBSEQUENT_UPLOADS, payload);
        console.log("API response:", response);
        setSubmitBtn(false);
        getFileUploads();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const removeOtherDocs = (FileNo: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    var payload = {
      otherDocumentsDelete: true,
      userId: userId,
      FileNo: FileNo,
      TrancheNumber: params.tranche_number,
      trancheAppId: params.application_id,
      application_ind: params.app_index,
      incubator_application_no: getGreyboxDtls.incubator_application_no,
    };
    post(GET_SUBSEQUENT_UPLOADS, payload).then((response: any) => {
      getFileUploads();
    });
  };
  const fileCommentsPopup = (
    name: any,
    status: any,
    action: any,
    fileNo: any,
    comment: any,
    st_id: any
  ) => {
    let splitComments =
      comment &&
      comment.split("//**//").filter((str: any) => str.trim() !== "");
    // console.log(splitComments);
    setCurrentFileDetails({
      name: name,
      status: status,
      action: action,
      file_no: fileNo,
      comment: splitComments,
      st_application_id: st_id,
    });
    setReadFileFirst("");
    setRejectedText("");
    setFileConfirmationPopup(true);
  };
  const removeFilePopup = (
    name: any,
    status: any,
    action: any,
    fileNo: any,
    comment: any,
    st_id: any
  ) => {
    setCurrentFileDetails({
      name: name,
      status: status,
      action: action,
      file_no: fileNo,
      comment: comment,
      st_application_id: st_id,
    });
    setRejectedText("");
    setReadFileFirst("");
    setFileConfirmationPopup(true);
  };
  const acceptFilePopup = (
    name: any,
    status: any,
    action: any,
    fileNo: any,
    comment: any,
    st_id: any
  ) => {
    setCurrentFileDetails({
      name: name,
      status: status,
      action: action,
      file_no: fileNo,
      comment: comment,
      st_application_id: st_id,
    });
    setRejectedText("");
    setReadFileFirst("");
    setFileConfirmationPopup(true);
  };

  const removeSTFileUpload = (file_no: any, comment: any, st_id: any) => {
    if (rejectedText == "") {
      return;
    }
    if (params.id) {
      var payload = {
        type: "STARTUP",
        user_id: st_id,
        FileNo: file_no,
        comment: comment,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };
      // return;
      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        if (response.data.result == 500) {
          setReadFileFirst(response.data.message);
          return;
        } else {
          setReadFileFirst("");
        }
        getSTFileUploads();
        setFileConfirmationPopup(false);
      });
    }
  };
  const acceptSTFileUpload = (file_no: any, comment: any, st_id: any) => {
    if (rejectedText == "") {
      return;
    }
    if (params.id) {
      var payload = {
        type: "STARTUP",
        user_id: st_id,
        FileNo: file_no,
        Accept: comment,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };
      // return;
      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        if (response.data.result == 500) {
          setReadFileFirst(response.data.message);
          return;
        } else {
          setReadFileFirst("");
        }
        // if(!)
        getSTFileUploads();
        setFileConfirmationPopup(false);
      });
    }
  };
  const removeIncFileUpload = (file_no: any, comment: any) => {
    if (rejectedText == "") {
      return;
    }
    if (params.id) {
      var payload = {
        type: "INCUBATOR",
        user_id: params.id,
        FileNo: file_no,
        comment: comment,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };
      // return;
      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        if (response.data.result == 500) {
          setReadFileFirst(response.data.message);
          return;
        } else {
          setReadFileFirst("");
        }
        getFileUploads();
        setIncFileConfirmationPopup(false);
      });
    }
  };
  const acceptIncFileUpload = (file_no: any, comment: any) => {
    if (rejectedText == "") {
      return;
    }
    if (params.id) {
      var payload = {
        type: "INCUBATOR",
        user_id: params.id,
        FileNo: file_no,
        Accept: comment,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };
      // return;
      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        if (response.data.result == 500) {
          setReadFileFirst(response.data.message);
          return;
        } else {
          setReadFileFirst("");
        }
        getFileUploads();
        setIncFileConfirmationPopup(false);
      });
    }
  };
  const getFileVisibiltyStatus = (id: any, type: any, st_id?: any) => {
    if (params.id) {
      var payload = {
        type: type,
        user_id: type == "STARTUP" ? st_id : params.id,
        FileNo: id,
        statusNo: 3,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };
      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        if (payload.type == "INCUBATOR") getFileUploads();
        if (payload.type == "STARTUP") getSTFileUploads();
      });
    }
  };
  const incFileCommentsPopup = (
    name: any,
    status: any,
    action: any,
    fileNo: any,
    comment: any
  ) => {
    let splitComments =
      comment &&
      comment.split("//**//").filter((str: any) => str.trim() !== "");

    setCurrentFileDetails({
      name: name,
      status: status,
      action: action,
      file_no: fileNo,
      comment: splitComments,
      st_application_id: "",
    });
    setRejectedText("");
    setIncFileConfirmationPopup(true);
  };
  const removeIncFilePopup = (
    name: any,
    status: any,
    action: any,
    fileNo: any,
    comment: any
  ) => {
    setCurrentFileDetails({
      name: name,
      status: status,
      action: action,
      file_no: fileNo,
      comment: comment,
      st_application_id: "",
    });
    setRejectedText("");
    setIncFileConfirmationPopup(true);
  };
  const acceptIncFilePopup = (
    name: any,
    status: any,
    action: any,
    fileNo: any,
    comment: any
  ) => {
    setCurrentFileDetails({
      name: name,
      status: status,
      action: action,
      file_no: fileNo,
      comment: comment,
      st_application_id: "",
    });
    setRejectedText("");
    setIncFileConfirmationPopup(true);
  };

  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        <div className="row">
          {/* {console.log(all_IncubatorFiles)} */}
          <div className="col-1"></div>
          <div className="col-10">
            <div className="row">
              <span className="tranche-head1">
                Request for the Subsequent Tranche (2/2) :
              </span>
            </div>
            {getStUploadsData && getStUploadsData.status_ind >= 3 && (
              <div className="row mb-2">
                <div className="col-12 indicationSection">
                  {loggedInRole == "secretariat"
                    ? colorIndication.map((item: any) => (
                        <div className="indicationAlign">
                          <div className="indicationBox ml-3">
                            <div className={item + " indicationDot"}></div>
                          </div>
                          <div>&nbsp;&nbsp; {item.slice(0, -2)}</div>
                        </div>
                      ))
                    : colorIndication
                        .filter(
                          (res: any) =>
                            res == "Submittedbg" ||
                            res == "Acceptedbg" ||
                            res == "Rejectedbg"
                        )
                        .map((item: any) => (
                          <div className="indicationAlign">
                            <div className="indicationBox ml-3">
                              <div className={item + " indicationDot"}></div>
                            </div>
                            <div>&nbsp;&nbsp; {item.slice(0, -2)}</div>
                          </div>
                        ))}
                </div>
              </div>
            )}
            <div className="row susequentTable mt-3">
              <div
                className="col-12 section-bg p-4 mb-0"
                style={{ backgroundColor: "#D2EAFF70" }}
              >
                <div className="d-flex">
                  <div className="sNo">13. </div>
                  <div className="sectionHead ml-2">
                    Interim progress report and UC of startups (para-8.7) and a
                    certification from a Practicing CA that Startup has not
                    received more than Rs 10 lakh of monetary support under any
                    other Central or State Government scheme (para-3.1, point-5)
                    <span className="required ml-1">*</span>
                  </div>
                </div>
                {loggedInRole == "incubator" && (
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="st-info">* All fields are mandatory.</div>
                      <div className="st-info">
                        * CA, UC and PR file size should be less than or equal
                        to 2MB.
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="st-info">
                        * LA file size should be less than or equal to 15MB.
                      </div>
                      {rejectedInfo && (
                        <div className="st-info">
                          * If any file rejected, please read the comments
                          before uploading
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {newState == undefined || newState.length == 0 ? (
                <h5
                  className="text-danger text-center mt-4"
                  style={{ width: "100%" }}
                >
                  No Selected startups found for this incubator
                </h5>
              ) : (
                <table style={{ marginTop: "0" }}>
                  <thead>
                    <tr>
                      <td style={{ textAlign: "left" }}>NAME OF THE STARTUP</td>
                      <td>
                        CA CERTIFICATE <br /> (CA)
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("CA")}
                        >
                          ℹ️
                        </span>
                      </td>
                      <td>
                        UTILIZATION CERTIFICATE <br /> (UC)
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Interim")}
                        >
                          ℹ️
                        </span>
                      </td>
                      <td>
                        PROGRESS REPORTS <br /> (PR)
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Interim")}
                        >
                          ℹ️
                        </span>
                      </td>
                      <td>
                        LEGAL AGREEMENT <br /> (LA)
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("LegalAgreement")}
                        >
                          ℹ️
                        </span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log("newState", newState)}
                    {newState &&
                      newState.map((item: any, i: any) => {
                        if (item.total_disbursed_amount > 0) {
                          return (
                            <tr key={item.id} style={{ cursor: "default" }}>
                              <td style={{ textAlign: "left" }}>
                                <div style={{ lineHeight: "1.1" }}>
                                  <small
                                    style={{
                                      color: "#506EB9",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item.recognition_number}
                                  </small>{" "}
                                  <br />
                                  <span
                                    style={{
                                      color: "#000000",
                                      fontWeight: "650",
                                    }}
                                  >
                                    {item.name_of_entity}
                                  </span>
                                </div>
                              </td>
                              <td
                                className={
                                  errorBox && !item.ca_file_path_file_name
                                    ? "alignContents"
                                    : stGetBackgrounds[i] &&
                                      stGetBackgrounds[i][0] &&
                                      stGetBackgrounds[i][0]
                                }
                              >
                                <br />
                                {loggedInRole == "incubator" && (
                                  <>
                                    <input
                                      disabled={
                                        item.ca_file_path_status_ind == 4
                                      }
                                      type="file"
                                      accept=".pdf"
                                      id={item.name_of_entity + "upload1"}
                                      name="upload1"
                                      onChange={(e) =>
                                        handleFileUpload(
                                          e,
                                          item.id,
                                          item.name_of_entity
                                        )
                                      }
                                      hidden
                                    />
                                    <label
                                      className="ml-2"
                                      htmlFor={item.name_of_entity + "upload1"}
                                    >
                                      <img
                                        src={fileupload}
                                        alt="Upload1"
                                        style={{
                                          cursor: "pointer",
                                          marginBottom: "7px",
                                        }}
                                      />
                                    </label>
                                  </>
                                )}
                                <div className="uploadText">
                                  {item.ca_file_path_file_name ? (
                                    <a
                                      href={
                                        NODE_URL + item.ca_file_path_file_path
                                      }
                                      onClick={() =>
                                        getFileVisibiltyStatus(
                                          0,
                                          "STARTUP",
                                          item.startup_application_id
                                        )
                                      }
                                      target="_blank"
                                    >
                                      {item.ca_file_path_file_name.length > 12
                                        ? item.ca_file_path_file_name.slice(
                                            0,
                                            12
                                          ) + ".."
                                        : item.ca_file_path_file_name}
                                    </a>
                                  ) : (
                                    "Upload"
                                  )}
                                </div>

                                <div className="statusSection">
                                  {loggedInRole == "secretariat" && (
                                    <>
                                      <span
                                        className="fileRemove text-center"
                                        onClick={() =>
                                          (item.ca_file_path_status_ind == 2 ||
                                            item.ca_file_path_status_ind ==
                                              3) &&
                                          acceptFilePopup(
                                            item.ca_file_path_file_name,
                                            item.ca_file_path_status_ind,
                                            "accept",
                                            "0",
                                            item.ca_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="acceptFile">
                                          {item.ca_file_path_status_ind == 4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : item.ca_file_path_status_ind ==
                                              2 ||
                                            item.ca_file_path_status_ind ==
                                              3 ? (
                                            <i
                                              className="fa fa-check-circle"
                                              style={{
                                                color: "green",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          (item.ca_file_path_status_ind == 2 ||
                                            item.ca_file_path_status_ind >=
                                              3) &&
                                          removeFilePopup(
                                            item.ca_file_path_file_name,
                                            item.ca_file_path_status_ind,
                                            "reject",
                                            "0",
                                            item.ca_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="rejectFile">
                                          {item.ca_file_path_status_ind == 0 ? (
                                            "Rejected"
                                          ) : params.tranche_status ==
                                              "Disbursed" ||
                                            params.tranche_status ==
                                              "Disbursement in Process" ||
                                            params.tranche_status ==
                                              "Incomplete" ? (
                                            ""
                                          ) : item.ca_file_path_status_ind ==
                                              2 ||
                                            item.ca_file_path_status_ind >=
                                              3 ? (
                                            <i
                                              className="fa fa-times-circle"
                                              style={{
                                                color: "red",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {loggedInRole == "incubator" && (
                                    <>
                                      <span className="fileRemove text-center">
                                        <span className="acceptFile">
                                          {item.ca_file_path_status_ind == 4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span className="fileRemove text-center ml-2">
                                        <span className="rejectFile">
                                          {item.status_ind > 2 &&
                                          item.ca_file_path_status_ind == 0
                                            ? "Rejected"
                                            : ""}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {item.ca_file_path_file_name != null &&
                                    (item.ca_file_path_status_ind == 0 ||
                                      item.ca_file_path_status_ind == 4) && (
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          fileCommentsPopup(
                                            item.ca_file_path_file_name,
                                            item.ca_file_path_status_ind,
                                            "comment",
                                            "0",
                                            item.ca_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="commentsFile">
                                          Comments
                                        </span>
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td
                                className={
                                  errorBox && !item.uc_file_path_file_name
                                    ? "alignContents"
                                    : stGetBackgrounds[i] &&
                                      stGetBackgrounds[i][1] &&
                                      stGetBackgrounds[i][1]
                                }
                              >
                                <br />
                                {loggedInRole == "incubator" && (
                                  <>
                                    <input
                                      disabled={
                                        item.uc_file_path_status_ind == 4
                                      }
                                      type="file"
                                      accept=".pdf"
                                      id={item.name_of_entity + "upload2"}
                                      name="upload2"
                                      onChange={(e) =>
                                        handleFileUpload(
                                          e,
                                          item.id,
                                          item.name_of_entity
                                        )
                                      }
                                      hidden
                                    />
                                    <label
                                      className="ml-2"
                                      htmlFor={item.name_of_entity + "upload2"}
                                    >
                                      <img
                                        src={fileupload}
                                        alt="Upload2"
                                        style={{
                                          cursor: "pointer",
                                          marginBottom: "7px",
                                        }}
                                      />
                                    </label>
                                  </>
                                )}
                                <div className="uploadText">
                                  {item.uc_file_path_file_name ? (
                                    <a
                                      href={
                                        NODE_URL + item.uc_file_path_file_path
                                      }
                                      onClick={() =>
                                        getFileVisibiltyStatus(
                                          1,
                                          "STARTUP",
                                          item.startup_application_id
                                        )
                                      }
                                      target="_blank"
                                    >
                                      {item.uc_file_path_file_name.length > 12
                                        ? item.uc_file_path_file_name.slice(
                                            0,
                                            12
                                          ) + ".."
                                        : item.uc_file_path_file_name}
                                    </a>
                                  ) : (
                                    "Upload"
                                  )}
                                </div>

                                <div className="statusSection">
                                  {loggedInRole == "secretariat" && (
                                    <>
                                      <span
                                        className="fileRemove text-center"
                                        onClick={() =>
                                          (item.uc_file_path_status_ind == 2 ||
                                            item.uc_file_path_status_ind ==
                                              3) &&
                                          acceptFilePopup(
                                            item.uc_file_path_file_name,
                                            item.uc_file_path_status_ind,
                                            "accept",
                                            "1",
                                            item.uc_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="acceptFile">
                                          {item.uc_file_path_status_ind == 4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : item.uc_file_path_status_ind ==
                                              2 ||
                                            item.uc_file_path_status_ind ==
                                              3 ? (
                                            <i
                                              className="fa fa-check-circle"
                                              style={{
                                                color: "green",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          (item.uc_file_path_status_ind == 2 ||
                                            item.uc_file_path_status_ind >=
                                              3) &&
                                          removeFilePopup(
                                            item.uc_file_path_file_name,
                                            item.uc_file_path_status_ind,
                                            "reject",
                                            "1",
                                            item.uc_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="rejectFile">
                                          {item.status_ind > 2 &&
                                          item.uc_file_path_status_ind == 0 ? (
                                            "Rejected"
                                          ) : params.tranche_status ==
                                              "Disbursed" ||
                                            params.tranche_status ==
                                              "Disbursement in Process" ||
                                            params.tranche_status ==
                                              "Incomplete" ? (
                                            ""
                                          ) : item.uc_file_path_status_ind ==
                                              2 ||
                                            item.uc_file_path_status_ind >=
                                              3 ? (
                                            <i
                                              className="fa fa-times-circle"
                                              style={{
                                                color: "red",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {loggedInRole == "incubator" && (
                                    <>
                                      <span className="fileRemove text-center">
                                        <span className="acceptFile">
                                          {item.uc_file_path_status_ind == 4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span className="fileRemove text-center ml-2">
                                        <span className="rejectFile">
                                          {item.status_ind > 2 &&
                                          item.uc_file_path_status_ind == 0
                                            ? "Rejected"
                                            : ""}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {item.uc_file_path_file_name != null &&
                                    (item.uc_file_path_status_ind == 0 ||
                                      item.uc_file_path_status_ind == 4) && (
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          fileCommentsPopup(
                                            item.uc_file_path_file_name,
                                            item.uc_file_path_status_ind,
                                            "comment",
                                            "1",
                                            item.uc_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="commentsFile">
                                          Comments
                                        </span>
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td
                                className={
                                  errorBox && !item.pr_file_path_file_name
                                    ? "alignContents"
                                    : stGetBackgrounds[i] &&
                                      stGetBackgrounds[i][2] &&
                                      stGetBackgrounds[i][2]
                                }
                              >
                                <br />
                                {loggedInRole == "incubator" && (
                                  <>
                                    <input
                                      disabled={
                                        item.pr_file_path_status_ind == 4
                                      }
                                      type="file"
                                      accept=".pdf"
                                      id={item.name_of_entity + "upload3"}
                                      name="upload3"
                                      onChange={(event) =>
                                        handleFileUpload(
                                          event,
                                          item.id,
                                          item.name_of_entity
                                        )
                                      }
                                      hidden
                                    />
                                    <label
                                      className="ml-2"
                                      htmlFor={item.name_of_entity + "upload3"}
                                    >
                                      <img
                                        src={fileupload}
                                        alt="Upload3"
                                        style={{
                                          cursor: "pointer",
                                          marginBottom: "7px",
                                        }}
                                      />
                                    </label>
                                  </>
                                )}
                                <div className="uploadText">
                                  {item.pr_file_path_file_name ? (
                                    <a
                                      href={
                                        NODE_URL + item.pr_file_path_file_path
                                      }
                                      onClick={() =>
                                        getFileVisibiltyStatus(
                                          2,
                                          "STARTUP",
                                          item.startup_application_id
                                        )
                                      }
                                      target="_blank"
                                    >
                                      {item.pr_file_path_file_name.length > 12
                                        ? item.pr_file_path_file_name.slice(
                                            0,
                                            12
                                          ) + ".."
                                        : item.pr_file_path_file_name}
                                    </a>
                                  ) : (
                                    "Upload"
                                  )}
                                </div>

                                <div className="statusSection">
                                  {loggedInRole == "secretariat" && (
                                    <>
                                      <span
                                        className="fileRemove text-center"
                                        onClick={() =>
                                          (item.pr_file_path_status_ind == 2 ||
                                            item.pr_file_path_status_ind ==
                                              3) &&
                                          acceptFilePopup(
                                            item.pr_file_path_file_name,
                                            item.pr_file_path_status_ind,
                                            "accept",
                                            "2",
                                            item.pr_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="acceptFile">
                                          {item.pr_file_path_status_ind == 4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : item.pr_file_path_status_ind ==
                                              2 ||
                                            item.pr_file_path_status_ind ==
                                              3 ? (
                                            <i
                                              className="fa fa-check-circle"
                                              style={{
                                                color: "green",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          (item.pr_file_path_status_ind == 2 ||
                                            item.pr_file_path_status_ind >=
                                              3) &&
                                          removeFilePopup(
                                            item.pr_file_path_file_name,
                                            item.pr_file_path_status_ind,
                                            "reject",
                                            "2",
                                            item.pr_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="rejectFile">
                                          {item.pr_file_path_status_ind == 0 ? (
                                            "Rejected"
                                          ) : params.tranche_status ==
                                              "Disbursed" ||
                                            params.tranche_status ==
                                              "Disbursement in Process" ||
                                            params.tranche_status ==
                                              "Incomplete" ? (
                                            ""
                                          ) : item.pr_file_path_status_ind ==
                                              2 ||
                                            item.pr_file_path_status_ind >=
                                              3 ? (
                                            <i
                                              className="fa fa-times-circle"
                                              style={{
                                                color: "red",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {loggedInRole == "incubator" && (
                                    <>
                                      <span className="fileRemove text-center">
                                        <span className="acceptFile">
                                          {item.pr_file_path_status_ind == 4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span className="fileRemove text-center ml-2">
                                        <span className="rejectFile">
                                          {item.status_ind > 2 &&
                                          item.pr_file_path_status_ind == 0
                                            ? "Rejected"
                                            : ""}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {item.pr_file_path_file_name != null &&
                                    (item.pr_file_path_status_ind == 0 ||
                                      item.pr_file_path_status_ind == 4) && (
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          fileCommentsPopup(
                                            item.pr_file_path_file_name,
                                            item.pr_file_path_status_ind,
                                            "comment",
                                            "2",
                                            item.pr_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="commentsFile">
                                          Comments
                                        </span>
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td
                                className={
                                  errorBox && !item.legal_agreement_file_name
                                    ? "alignContents"
                                    : stGetBackgrounds[i] &&
                                      stGetBackgrounds[i][3] &&
                                      stGetBackgrounds[i][3]
                                }
                              >
                                <br />
                                {/* {console.log(item.legal_file_path_status_ind,"itemmmmmmm@@@")} */}
                                {loggedInRole == "incubator" && (
                                  <>
                                    <input
                                      disabled={
                                        item.legal_file_path_status_ind == 4
                                      }
                                      type="file"
                                      accept=".pdf"
                                      id={item.name_of_entity + "upload4"}
                                      name="upload4"
                                      onChange={(event) =>
                                        handleFileChange(
                                          event,
                                          item.id,
                                          item.name_of_entity
                                        )
                                      }
                                      hidden
                                    />
                                    <label
                                      className="ml-2"
                                      htmlFor={item.name_of_entity + "upload4"}
                                    >
                                      <img
                                        src={fileupload}
                                        alt="Upload4"
                                        style={{
                                          cursor: "pointer",
                                          marginBottom: "7px",
                                        }}
                                      />
                                    </label>
                                  </>
                                )}
                                <div className="uploadText">
                                  {item.legal_agreement_file_name ? (
                                    <a
                                      href={
                                        NODE_URL +
                                        item.legal_agreement_file_path
                                      }
                                      onClick={() =>
                                        getFileVisibiltyStatus(
                                          3,
                                          "STARTUP",
                                          item.startup_application_id
                                        )
                                      }
                                      target="_blank"
                                    >
                                      {item.legal_agreement_file_name.length >
                                      12
                                        ? item.legal_agreement_file_name.slice(
                                            0,
                                            12
                                          ) + ".."
                                        : item.legal_agreement_file_name}
                                    </a>
                                  ) : (
                                    "Upload"
                                  )}
                                </div>

                                <div className="statusSection">
                                  {loggedInRole == "secretariat" && (
                                    <>
                                      <span
                                        className="fileRemove text-center"
                                        onClick={() =>
                                          (item.legal_file_path_status_ind ==
                                            2 ||
                                            item.legal_file_path_status_ind ==
                                              3) &&
                                          acceptFilePopup(
                                            item.legal_agreement_file_name,
                                            item.legal_file_path_status_ind,
                                            "accept",
                                            "3",
                                            item.legal_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="acceptFile">
                                          {item.legal_file_path_status_ind ==
                                          4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : item.legal_file_path_status_ind ==
                                              2 ||
                                            item.legal_file_path_status_ind ==
                                              3 ? (
                                            <i
                                              className="fa fa-check-circle"
                                              style={{
                                                color: "green",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          (item.legal_file_path_status_ind ==
                                            2 ||
                                            item.legal_file_path_status_ind >=
                                              3) &&
                                          removeFilePopup(
                                            item.legal_agreement_file_name,
                                            item.legal_file_path_status_ind,
                                            "reject",
                                            "3",
                                            item.legal_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="rejectFile">
                                          {item.legal_file_path_status_ind ==
                                          0 ? (
                                            "Rejected"
                                          ) : params.tranche_status ==
                                              "Disbursed" ||
                                            params.tranche_status ==
                                              "Disbursement in Process" ||
                                            params.tranche_status ==
                                              "Incomplete" ? (
                                            ""
                                          ) : item.legal_file_path_status_ind ==
                                              2 ||
                                            item.legal_file_path_status_ind >=
                                              3 ? (
                                            <i
                                              className="fa fa-times-circle"
                                              style={{
                                                color: "red",
                                                fontSize: "14px",
                                              }}
                                            ></i>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {loggedInRole == "incubator" && (
                                    <>
                                      <span className="fileRemove text-center">
                                        <span className="acceptFile">
                                          {item.legal_file_path_status_ind ==
                                          4 ? (
                                            <span className="acceptFile1">
                                              Accepted
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                      <span className="fileRemove text-center ml-2">
                                        <span className="rejectFile">
                                          {item.status_ind > 2 &&
                                          item.legal_file_path_status_ind == 0
                                            ? "Rejected"
                                            : ""}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {item.legal_agreement_file_name != null &&
                                    (item.legal_file_path_status_ind == 0 ||
                                      item.legal_file_path_status_ind == 4) && (
                                      <span
                                        className="fileRemove text-center ml-2"
                                        onClick={() =>
                                          fileCommentsPopup(
                                            item.legal_agreement_file_name,
                                            item.legal_file_path_status_ind,
                                            "comment",
                                            "3",
                                            item.legal_file_path_comments,
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        <span className="commentsFile">
                                          Comments
                                        </span>
                                      </span>
                                    )}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              )}
            </div>

            <div className="row susequentTable mt-3">
              <div
                className="col-12 section-bg mb-0"
                style={{ backgroundColor: "#D2EAFF70" }}
              >
                <div className="d-flex">
                  <div className="sNo">
                    <input
                      type="checkbox"
                      name="additional"
                      onChange={handleCheckBox}
                      checked={checkValue}
                      // disabled={
                      //   getFilesData?.status_ind == 2 ||
                      //   getFilesData?.status_ind == 4
                      // }
                    />
                  </div>
                  <div className="sectionHead ml-2">
                    Additional Documents (optional) <br />
                    {loggedInRole == "incubator" && checkValue && (
                      <>
                        <span
                          className="required ml-1"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "small",
                          }}
                        >
                          *File size should be less than or equal to 19MB,
                          Maximum File uploads - upto 5.
                        </span>
                        <br />
                        {rejectedInfo1 && (
                          <span
                            className="required ml-1"
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "small",
                            }}
                          >
                            * If any rejected, please read the comments before
                            upload.
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {checkValue && (
                  <div className="row  mt-3">
                    <div
                      className={
                        getIncBgColor
                          ? getIncBgColor[0] + " col-2 text-center m-auto p-3"
                          : "col-2 text-center m-auto p-3"
                      }
                    >
                      {/* <span className='uploadText' >{item.ca_file_path_file_name ? <a href={NODE_URL + item.ca_file_path_file_path} target='_blank'>{item.ca_file_path_file_name.length > 12 ? item.ca_file_path_file_name.slice(0, 12) + ".." : item.ca_file_path_file_name}</a> : "Upload"}</span> */}
                      {loggedInRole == "incubator" && (
                        <>
                          <input
                            disabled={
                              getFilesData?.optional_other_1_status_ind == 4
                            }
                            type="file"
                            multiple
                            accept=".pdf"
                            id="upload4"
                            name="upload4"
                            onChange={(event) => handleOtherFileUpload(event)}
                            hidden
                          />
                          <label className="ml-2 mb-0" htmlFor="upload4">
                            <img
                              src={fileupload}
                              alt="Upload4"
                              style={{ cursor: "pointer", marginBottom: "7px" }}
                            />
                          </label>
                          <br />
                        </>
                      )}
                      {getFilesData?.optional_other_1_file_name ? (
                        <a
                          className="uploadText ml-0"
                          href={`${
                            NODE_URL + getFilesData?.optional_other_1_file_path
                          }`}
                          onClick={() =>
                            getFileVisibiltyStatus(12, "INCUBATOR")
                          }
                          target="_blank"
                        >
                          {getFilesData?.optional_other_1_file_name
                            ? getFilesData?.optional_other_1_file_name.slice(
                                0,
                                12
                              ) + ".."
                            : getFilesData?.optional_other_1_file_name}
                        </a>
                      ) : (
                        "Upload"
                      )}
                      {getFilesData?.optional_other_1_status_ind == 1 && (
                        <span
                          className="removeIcon"
                          onClick={() => removeOtherDocs(12)}
                        >
                          &times;
                        </span>
                      )}
                      <div className="statusSection">
                        {getFilesData?.optional_other_1_status_ind !== null &&
                          loggedInRole == "secretariat" &&
                          params.tranche_status != "Incomplete" && (
                            <>
                              <span
                                className="fileRemove text-center"
                                onClick={() =>
                                  (getFilesData?.optional_other_1_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_1_status_ind ==
                                      3) &&
                                  acceptIncFilePopup(
                                    getFilesData?.optional_other_1_file_name,
                                    getFilesData?.optional_other_1_status_ind,
                                    "accept",
                                    "12",
                                    getFilesData?.optional_other_1_comments
                                  )
                                }
                              >
                                <span className="acceptFile">
                                  {getFilesData?.optional_other_1_status_ind ==
                                  4 ? (
                                    <span className="acceptFile1">
                                      Accepted
                                    </span>
                                  ) : getFilesData?.optional_other_1_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_1_status_ind ==
                                      3 ? (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{
                                        color: "green",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                              <span
                                className="fileRemove text-center ml-2"
                                onClick={() =>
                                  (getFilesData?.optional_other_1_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_1_status_ind >=
                                      3) &&
                                  removeIncFilePopup(
                                    getFilesData?.optional_other_1_file_name,
                                    getFilesData?.optional_other_1_status_ind,
                                    "reject",
                                    "12",
                                    getFilesData?.optional_other_1_comments
                                  )
                                }
                              >
                                <span className="rejectFile">
                                  {getFilesData?.optional_other_1_status_ind ==
                                  0 ? (
                                    <span className="rejectFile1">
                                      Rejected
                                    </span>
                                  ) : params.tranche_status == "Disbursed" ||
                                    params.tranche_status ==
                                      "Disbursement in Process" ||
                                    params.tranche_status == "Incomplete" ? (
                                    ""
                                  ) : getFilesData?.optional_other_1_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_1_status_ind >=
                                      3 ? (
                                    <i
                                      className="fa fa-times-circle"
                                      style={{ color: "red", fontSize: "14px" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                            </>
                          )}
                        {loggedInRole == "incubator" && (
                          <>
                            <span className="fileRemove text-center">
                              <span className="acceptFile">
                                {getFilesData?.optional_other_1_status_ind ==
                                4 ? (
                                  <span className="acceptFile1">Accepted</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                            <span className="fileRemove text-center ml-2">
                              <span className="rejectFile">
                                {getFilesData?.status_ind > 2 &&
                                getFilesData?.optional_other_1_status_ind ==
                                  0 ? (
                                  <span className="rejectFile1">Rejected</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                          </>
                        )}
                        {(getFilesData?.optional_other_1_status_ind == 0 ||
                          getFilesData?.optional_other_1_status_ind == 4) && (
                          <span
                            className="fileRemove text-center ml-2"
                            onClick={() =>
                              incFileCommentsPopup(
                                getFilesData?.optional_other_1_file_name,
                                getFilesData?.optional_other_1_status_ind,
                                "comment",
                                "12",
                                getFilesData?.optional_other_1_comments
                              )
                            }
                          >
                            <span className="commentsFile">Comments</span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        getIncBgColor
                          ? getIncBgColor[1] + " col-2 text-center m-auto p-3"
                          : "col-2 text-center m-auto p-3"
                      }
                    >
                      {loggedInRole == "incubator" && (
                        <>
                          <input
                            disabled={
                              getFilesData?.optional_other_2_status_ind == 4
                            }
                            type="file"
                            accept=".pdf"
                            id="upload5"
                            name="upload5"
                            onChange={(event) => handleOtherFileUpload(event)}
                            hidden
                          />
                          <label className="ml-2 mb-0" htmlFor="upload5">
                            <img
                              src={fileupload}
                              alt="Upload5"
                              style={{ cursor: "pointer", marginBottom: "7px" }}
                            />
                          </label>
                          <br />
                        </>
                      )}
                      {getFilesData?.optional_other_2_file_name ? (
                        <a
                          className="uploadText ml-0"
                          href={`${
                            NODE_URL + getFilesData?.optional_other_2_file_path
                          }`}
                          onClick={() =>
                            getFileVisibiltyStatus(13, "INCUBATOR")
                          }
                          target="_blank"
                        >
                          {getFilesData?.optional_other_2_file_name
                            ? getFilesData?.optional_other_2_file_name.slice(
                                0,
                                12
                              ) + ".."
                            : getFilesData?.optional_other_2_file_name}
                        </a>
                      ) : (
                        "Upload"
                      )}
                      {getFilesData?.optional_other_2_status_ind == 1 && (
                        <span
                          className="removeIcon"
                          onClick={() => removeOtherDocs(13)}
                        >
                          &times;
                        </span>
                      )}
                      <div className="statusSection">
                        {getFilesData?.optional_other_2_status_ind !== null &&
                          loggedInRole == "secretariat" &&
                          params.tranche_status != "Incomplete" && (
                            <>
                              <span
                                className="fileRemove text-center"
                                onClick={() =>
                                  (getFilesData?.optional_other_2_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_2_status_ind ==
                                      3) &&
                                  acceptIncFilePopup(
                                    getFilesData?.optional_other_2_file_name,
                                    getFilesData?.optional_other_2_status_ind,
                                    "accept",
                                    "13",
                                    getFilesData?.optional_other_2_comments
                                  )
                                }
                              >
                                <span className="acceptFile">
                                  {getFilesData?.optional_other_2_status_ind ==
                                  4 ? (
                                    <span className="acceptFile1">
                                      Accepted
                                    </span>
                                  ) : getFilesData?.optional_other_2_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_2_status_ind ==
                                      3 ? (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{
                                        color: "green",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                              <span
                                className="fileRemove text-center ml-2"
                                onClick={() =>
                                  (getFilesData?.optional_other_2_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_2_status_ind >=
                                      3) &&
                                  removeIncFilePopup(
                                    getFilesData?.optional_other_2_file_name,
                                    getFilesData?.optional_other_2_status_ind,
                                    "reject",
                                    "13",
                                    getFilesData?.optional_other_2_comments
                                  )
                                }
                              >
                                <span className="rejectFile">
                                  {getFilesData?.optional_other_2_status_ind ==
                                  0 ? (
                                    <span className="rejectFile1">
                                      Rejected
                                    </span>
                                  ) : params.tranche_status == "Disbursed" ||
                                    params.tranche_status ==
                                      "Disbursement in Process" ||
                                    params.tranche_status == "Incomplete" ? (
                                    ""
                                  ) : getFilesData?.optional_other_2_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_2_status_ind >=
                                      3 ? (
                                    <i
                                      className="fa fa-times-circle"
                                      style={{ color: "red", fontSize: "14px" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                            </>
                          )}
                        {loggedInRole == "incubator" && (
                          <>
                            <span className="fileRemove text-center">
                              <span className="acceptFile">
                                {getFilesData?.optional_other_2_status_ind ==
                                4 ? (
                                  <span className="acceptFile1">Accepted</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                            <span className="fileRemove text-center ml-2">
                              <span className="rejectFile">
                                {getFilesData?.status_ind > 2 &&
                                getFilesData?.optional_other_2_status_ind ==
                                  0 ? (
                                  <span className="rejectFile1">Rejected</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                          </>
                        )}
                        {(getFilesData?.optional_other_2_status_ind == 0 ||
                          getFilesData?.optional_other_2_status_ind == 4) && (
                          <span
                            className="fileRemove text-center ml-2"
                            onClick={() =>
                              incFileCommentsPopup(
                                getFilesData?.optional_other_2_file_name,
                                getFilesData?.optional_other_2_status_ind,
                                "comment",
                                "13",
                                getFilesData?.optional_other_2_comments
                              )
                            }
                          >
                            <span className="commentsFile">Comments</span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        getIncBgColor
                          ? getIncBgColor[2] + " col-2 text-center m-auto p-3"
                          : "col-2 text-center m-auto p-3"
                      }
                    >
                      {loggedInRole == "incubator" && (
                        <>
                          <input
                            disabled={
                              getFilesData?.optional_other_3_status_ind == 4
                            }
                            type="file"
                            accept=".pdf"
                            id="upload6"
                            name="upload6"
                            onChange={(event) => handleOtherFileUpload(event)}
                            hidden
                          />
                          <label className="ml-2 mb-0" htmlFor="upload6">
                            <img
                              src={fileupload}
                              alt="Upload6"
                              style={{ cursor: "pointer", marginBottom: "7px" }}
                            />
                          </label>
                          <br />
                        </>
                      )}
                      {getFilesData?.optional_other_3_file_name ? (
                        <a
                          className="uploadText ml-0"
                          href={`${
                            NODE_URL + getFilesData?.optional_other_3_file_path
                          }`}
                          onClick={() =>
                            getFileVisibiltyStatus(14, "INCUBATOR")
                          }
                          target="_blank"
                        >
                          {getFilesData?.optional_other_3_file_name
                            ? getFilesData?.optional_other_3_file_name.slice(
                                0,
                                12
                              ) + ".."
                            : getFilesData?.optional_other_3_file_name}
                        </a>
                      ) : (
                        "Upload"
                      )}
                      {getFilesData?.optional_other_3_status_ind == 1 && (
                        <span
                          className="removeIcon"
                          onClick={() => removeOtherDocs(14)}
                        >
                          &times;
                        </span>
                      )}
                      <div className="statusSection">
                        {getFilesData?.optional_other_3_status_ind !== null &&
                          loggedInRole == "secretariat" &&
                          params.tranche_status != "Incomplete" && (
                            <>
                              <span
                                className="fileRemove text-center"
                                onClick={() =>
                                  (getFilesData?.optional_other_3_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_3_status_ind ==
                                      3) &&
                                  acceptIncFilePopup(
                                    getFilesData?.optional_other_3_file_name,
                                    getFilesData?.optional_other_3_status_ind,
                                    "accept",
                                    "14",
                                    getFilesData?.optional_other_3_comments
                                  )
                                }
                              >
                                <span className="acceptFile">
                                  {getFilesData?.optional_other_3_status_ind ==
                                  4 ? (
                                    <span className="acceptFile1">
                                      Accepted
                                    </span>
                                  ) : getFilesData?.optional_other_3_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_3_status_ind ==
                                      3 ? (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{
                                        color: "green",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                              <span
                                className="fileRemove text-center ml-2"
                                onClick={() =>
                                  (getFilesData?.optional_other_3_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_3_status_ind >=
                                      3) &&
                                  removeIncFilePopup(
                                    getFilesData?.optional_other_3_file_name,
                                    getFilesData?.optional_other_3_status_ind,
                                    "reject",
                                    "14",
                                    getFilesData?.optional_other_3_comments
                                  )
                                }
                              >
                                <span className="rejectFile">
                                  {getFilesData?.optional_other_3_status_ind ==
                                  0 ? (
                                    <span className="rejectFile1">
                                      Rejected
                                    </span>
                                  ) : params.tranche_status == "Disbursed" ||
                                    params.tranche_status ==
                                      "Disbursement in Process" ||
                                    params.tranche_status == "Incomplete" ? (
                                    ""
                                  ) : getFilesData?.optional_other_3_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_3_status_ind >=
                                      3 ? (
                                    <i
                                      className="fa fa-times-circle"
                                      style={{ color: "red", fontSize: "14px" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                            </>
                          )}
                        {loggedInRole == "incubator" && (
                          <>
                            <span className="fileRemove text-center">
                              <span className="acceptFile">
                                {getFilesData?.optional_other_3_status_ind ==
                                4 ? (
                                  <span className="acceptFile1">Accepted</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                            <span className="fileRemove text-center ml-2">
                              <span className="rejectFile">
                                {getFilesData?.status_ind > 2 &&
                                getFilesData?.optional_other_3_status_ind ==
                                  0 ? (
                                  <span className="rejectFile1">Rejected</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                          </>
                        )}
                        {(getFilesData?.optional_other_3_status_ind == 0 ||
                          getFilesData?.optional_other_3_status_ind == 4) && (
                          <span
                            className="fileRemove text-center ml-2"
                            onClick={() =>
                              incFileCommentsPopup(
                                getFilesData?.optional_other_3_file_name,
                                getFilesData?.optional_other_3_status_ind,
                                "comment",
                                "14",
                                getFilesData?.optional_other_3_comments
                              )
                            }
                          >
                            <span className="commentsFile">Comments</span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        getIncBgColor
                          ? getIncBgColor[3] + " col-2 text-center m-auto p-3"
                          : "col-2 text-center m-auto p-3"
                      }
                    >
                      {loggedInRole == "incubator" && (
                        <>
                          <input
                            disabled={
                              getFilesData?.optional_other_4_status_ind == 4
                            }
                            type="file"
                            accept=".pdf"
                            id="upload7"
                            name="upload7"
                            onChange={(event) => handleOtherFileUpload(event)}
                            hidden
                          />
                          <label className="ml-2 mb-0" htmlFor="upload7">
                            <img
                              src={fileupload}
                              alt="Upload7"
                              style={{ cursor: "pointer", marginBottom: "7px" }}
                            />
                          </label>
                          <br />
                        </>
                      )}
                      {getFilesData?.optional_other_4_file_name ? (
                        <a
                          className="uploadText ml-0"
                          href={`${
                            NODE_URL + getFilesData?.optional_other_4_file_path
                          }`}
                          onClick={() =>
                            getFileVisibiltyStatus(15, "INCUBATOR")
                          }
                          target="_blank"
                        >
                          {getFilesData?.optional_other_4_file_name
                            ? getFilesData?.optional_other_4_file_name.slice(
                                0,
                                12
                              ) + ".."
                            : getFilesData?.optional_other_4_file_name}
                        </a>
                      ) : (
                        "Upload"
                      )}
                      {getFilesData?.optional_other_4_status_ind == 1 && (
                        <span
                          className="removeIcon"
                          onClick={() => removeOtherDocs(15)}
                        >
                          &times;
                        </span>
                      )}
                      <div className="statusSection">
                        {getFilesData?.optional_other_4_status_ind !== null &&
                          loggedInRole == "secretariat" &&
                          params.tranche_status != "Incomplete" && (
                            <>
                              <span
                                className="fileRemove text-center"
                                onClick={() =>
                                  (getFilesData?.optional_other_4_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_4_status_ind ==
                                      3) &&
                                  acceptIncFilePopup(
                                    getFilesData?.optional_other_4_file_name,
                                    getFilesData?.optional_other_4_status_ind,
                                    "accept",
                                    "15",
                                    getFilesData?.optional_other_4_comments
                                  )
                                }
                              >
                                <span className="acceptFile">
                                  {getFilesData?.optional_other_4_status_ind ==
                                  4 ? (
                                    <span className="acceptFile1">
                                      Accepted
                                    </span>
                                  ) : getFilesData?.optional_other_4_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_4_status_ind ==
                                      3 ? (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{
                                        color: "green",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                              <span
                                className="fileRemove text-center ml-2"
                                onClick={() =>
                                  (getFilesData?.optional_other_4_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_4_status_ind >=
                                      3) &&
                                  removeIncFilePopup(
                                    getFilesData?.optional_other_4_file_name,
                                    getFilesData?.optional_other_4_status_ind,
                                    "reject",
                                    "15",
                                    getFilesData?.optional_other_4_comments
                                  )
                                }
                              >
                                <span className="rejectFile">
                                  {getFilesData?.optional_other_4_status_ind ==
                                  0 ? (
                                    <span className="rejectFile1">
                                      Rejected
                                    </span>
                                  ) : params.tranche_status == "Disbursed" ||
                                    params.tranche_status ==
                                      "Disbursement in Process" ||
                                    params.tranche_status == "Incomplete" ? (
                                    ""
                                  ) : getFilesData?.optional_other_4_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_4_status_ind >=
                                      3 ? (
                                    <i
                                      className="fa fa-times-circle"
                                      style={{ color: "red", fontSize: "14px" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                            </>
                          )}
                        {loggedInRole == "incubator" && (
                          <>
                            <span className="fileRemove text-center">
                              <span className="acceptFile">
                                {getFilesData?.optional_other_4_status_ind ==
                                4 ? (
                                  <span className="acceptFile1">Accepted</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                            <span className="fileRemove text-center ml-2">
                              <span className="rejectFile">
                                {getFilesData?.status_ind > 2 &&
                                getFilesData?.optional_other_4_status_ind ==
                                  0 ? (
                                  <span className="rejectFile1">Rejected</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                          </>
                        )}
                        {(getFilesData?.optional_other_4_status_ind == 0 ||
                          getFilesData?.optional_other_4_status_ind == 4) && (
                          <span
                            className="fileRemove text-center ml-2"
                            onClick={() =>
                              incFileCommentsPopup(
                                getFilesData?.optional_other_4_file_name,
                                getFilesData?.optional_other_4_status_ind,
                                "comment",
                                "15",
                                getFilesData?.optional_other_4_comments
                              )
                            }
                          >
                            <span className="commentsFile">Comments</span>
                          </span>
                        )}
                      </div>
                    </div>
                    {/* {console.log(getIncBgColor && getIncBgColor[12],getIncBgColor[13],getIncBgColor[14],getIncBgColor[15],getIncBgColor[16])} */}
                    <div
                      className={
                        getIncBgColor
                          ? getIncBgColor[4] + " col-2 text-center m-auto p-3"
                          : "col-2 text-center m-auto p-3"
                      }
                    >
                      {loggedInRole == "incubator" && (
                        <>
                          <input
                            disabled={
                              getFilesData?.optional_other_5_status_ind == 4
                            }
                            type="file"
                            accept=".pdf"
                            id="upload8"
                            name="upload8"
                            onChange={(event) => handleOtherFileUpload(event)}
                            hidden
                          />
                          <label className="ml-2 mb-0" htmlFor="upload8">
                            <img
                              src={fileupload}
                              alt="Upload8"
                              style={{ cursor: "pointer", marginBottom: "7px" }}
                            />
                          </label>
                          <br />
                        </>
                      )}
                      {getFilesData?.optional_other_5_file_name ? (
                        <a
                          className="uploadText ml-0"
                          href={`${
                            NODE_URL + getFilesData?.optional_other_5_file_path
                          }`}
                          onClick={() =>
                            getFileVisibiltyStatus(16, "INCUBATOR")
                          }
                          target="_blank"
                        >
                          {getFilesData?.optional_other_5_file_name
                            ? getFilesData?.optional_other_5_file_name.slice(
                                0,
                                12
                              ) + ".."
                            : getFilesData?.optional_other_5_file_name}
                        </a>
                      ) : (
                        "Upload"
                      )}
                      {getFilesData?.optional_other_5_status_ind == 1 && (
                        <span
                          className="removeIcon"
                          onClick={() => removeOtherDocs(16)}
                        >
                          &times;
                        </span>
                      )}
                      <div className="statusSection">
                        {getFilesData?.optional_other_5_status_ind !== null &&
                          loggedInRole == "secretariat" &&
                          params.tranche_status != "Incomplete" && (
                            <>
                              <span
                                className="fileRemove text-center"
                                onClick={() =>
                                  (getFilesData?.optional_other_5_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_5_status_ind ==
                                      3) &&
                                  acceptIncFilePopup(
                                    getFilesData?.optional_other_5_file_name,
                                    getFilesData?.optional_other_5_status_ind,
                                    "accept",
                                    "16",
                                    getFilesData?.optional_other_5_comments
                                  )
                                }
                              >
                                <span className="acceptFile">
                                  {getFilesData?.optional_other_5_status_ind ==
                                  4 ? (
                                    <span className="acceptFile1">
                                      Accepted
                                    </span>
                                  ) : getFilesData?.optional_other_5_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_5_status_ind ==
                                      3 ? (
                                    <i
                                      className="fa fa-check-circle"
                                      style={{
                                        color: "green",
                                        fontSize: "14px",
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                              <span
                                className="fileRemove text-center ml-2"
                                onClick={() =>
                                  (getFilesData?.optional_other_5_status_ind ==
                                    2 ||
                                    getFilesData?.optional_other_5_status_ind >=
                                      3) &&
                                  removeIncFilePopup(
                                    getFilesData?.optional_other_5_file_name,
                                    getFilesData?.optional_other_5_status_ind,
                                    "reject",
                                    "16",
                                    getFilesData?.optional_other_5_comments
                                  )
                                }
                              >
                                <span className="rejectFile">
                                  {getFilesData?.optional_other_5_status_ind ==
                                  0 ? (
                                    <span className="rejectFile1">
                                      Rejected
                                    </span>
                                  ) : params.tranche_status == "Disbursed" ||
                                    params.tranche_status ==
                                      "Disbursement in Process" ||
                                    params.tranche_status == "Incomplete" ? (
                                    ""
                                  ) : getFilesData?.optional_other_5_status_ind ==
                                      2 ||
                                    getFilesData?.optional_other_5_status_ind >=
                                      3 ? (
                                    <i
                                      className="fa fa-times-circle"
                                      style={{ color: "red", fontSize: "14px" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </span>
                            </>
                          )}
                        {loggedInRole == "incubator" && (
                          <>
                            <span className="fileRemove text-center">
                              <span className="acceptFile">
                                {getFilesData?.optional_other_5_status_ind ==
                                4 ? (
                                  <span className="acceptFile1">Accepted</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                            <span className="fileRemove text-center ml-2">
                              <span className="rejectFile">
                                {getFilesData?.status_ind > 2 &&
                                getFilesData?.optional_other_5_status_ind ==
                                  0 ? (
                                  <span className="rejectFile1">Rejected</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                          </>
                        )}
                        {(getFilesData?.optional_other_5_status_ind == 0 ||
                          getFilesData?.optional_other_5_status_ind == 4) && (
                          <span
                            className="fileRemove text-center ml-2"
                            onClick={() =>
                              incFileCommentsPopup(
                                getFilesData?.optional_other_5_file_name,
                                getFilesData?.optional_other_5_status_ind,
                                "comment",
                                "16",
                                getFilesData?.optional_other_5_comments
                              )
                            }
                          >
                            <span className="commentsFile">Comments</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row text-center mt-4">
              <div className="m-auto">
                {errorBox1 && (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    * Some of the Files are missing in the Previous Page{" "}
                  </span>
                )}
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-12 text-right">
                <button
                  className="btn btn-sisfs btn-yellow-primary mr-5"
                  onClick={previous}
                >
                  Previous
                </button>
                {loggedInRole == "secretariat" && getAllZipFiles && (
                  <button
                    className="btn btn-sisfs btn-yellow-primary mr-5"
                    // onClick={downloadAsZip}
                  >
                    <a
                      href={NODE_URL + "/" + getIncZipFile}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#25429c" }}
                    >
                      Download Incubator Files
                    </a>
                  </button>
                )}
                {loggedInRole == "secretariat" && getAllZipFiles && (
                  <button
                    className="btn btn-sisfs btn-yellow-primary mr-5"
                    // onClick={downloadAsZip}
                  >
                    <a
                      href={NODE_URL + "/" + getSTZipFiles}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#25429c" }}
                    >
                      Download Startup Files
                    </a>
                  </button>
                )}
                {/* {console.log(submitBtn,getFilesData?.status_ind)} */}
                {newState == undefined || newState.length == 0 ? (
                  <button className="btn btn-sisfs btn-yellow-primary" disabled>
                    Submit
                  </button>
                ) : loggedInRole == "secretariat" ? (
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    disabled={
                      params.tranche_status == "Disbursed" ||
                      params.tranche_status == "Disbursement in Process" ||
                      params.tranche_status == "Incomplete"
                    }
                    onClick={submit}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    disabled={
                      submitBtn ||
                      getFilesData?.status_ind == 2 ||
                      getFilesData?.status_ind == 4
                    }
                    onClick={submit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
      </fieldset>

      {/* exceeded file popup */}
      {exceedFileSize && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={error}
                  className="search mb-2"
                  alt="search"
                  style={{ width: "2rem" }}
                />
                <h4
                  className="message-orange"
                  style={{ fontFamily: "Roboto Slab" }}
                >
                  Oops!!!
                </h4>
                <h5
                  className="message-orange"
                  style={{ fontFamily: "Roboto Slab" }}
                >
                  File size should not exceed{" "}
                  {otherDocExceedSize
                    ? "19MB"
                    : exceedFileSize1
                    ? "15MB"
                    : "2MB"}
                  <br />
                  <br />
                  Please upload again.
                </h5>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUploadFailureModal && (
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowUploadFailureModal(false)}
          iconClass="showImage"
        />
      )}
      {showEditSuccessModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You !</h3>
                  <div className="modal-text">{tranche_message}</div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      // onClick={() => window.location.reload()}
                      onClick={goToFundDetailsTab}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showSubmitFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    {errorMessage}
                  </h3>
                  <div className="modal-action">
                    {errorMessage ==
                    "please Accept or reject the uploaded files" ? (
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={dismissModal}
                      >
                        OK !
                      </button>
                    ) : (
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={goToSecFundDetails}
                      >
                        OK !
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEditFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    Oops! Something is Wrong
                  </h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please Try Again !!!
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* saved as draft popup */}
      {draftPopUp && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <i
                  style={{ fontSize: "40px", color: "green" }}
                  className="fa fa-check-circle-o"
                ></i>

                <h5
                  className="message"
                  style={{
                    fontFamily: "Roboto Slab",
                    color: "green",
                    fontSize: "26px",
                  }}
                >
                  Saved Successfully
                </h5>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* confirmation popup */}
      {confirmationPopup && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <h3 className="modal-message mt-2">
                  Are you sure you want to submit.
                </h3>
                <h6 className=" message-orange">
                  Note: Once you submit, you are no longer able to edit again.
                </h6>
                <div className="modal-action mt-2">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    onClick={() => setConfirmationPopup(false)}
                  >
                    NO
                  </button>
                  <button
                    className="btn btn-sisfs btn-yellow-primary ml-2"
                    onClick={confirmSubmit}
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* startup action taking popup */}
      {fileConfirmationPopup && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <h6 className="modal-message mt-2">
                  {currentFileDetails.action == "accept"
                    ? "Are you sure you want to accept the file?"
                    : currentFileDetails.action == "reject"
                    ? "Are you sure you want to reject the file?"
                    : "Comments"}
                </h6>
                {<span className="text-danger">{readFileFirst}</span>}
                {currentFileDetails.action == "comment" && (
                  <>
                    {currentFileDetails.comment == null ? (
                      "No Comments"
                    ) : (
                      <div style={{ maxHeight: "40vh", overflow: "scroll" }}>
                        <table className="table-bordered mt-0">
                          <thead>
                            <th className="text-center">Status</th>
                            <th className="text-center">Comment</th>
                            <th className="text-center">Date</th>
                          </thead>
                          <tbody>
                            {currentFileDetails.comment &&
                              currentFileDetails.comment.map((item: any, i) => (
                                <tr key={i}>
                                  <td
                                    style={{
                                      verticalAlign: "baseline",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.split(":")[0]}
                                  </td>
                                  <td
                                    className="commentsColumn"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item.split("ted:")[1].split("Time$#")[0]}
                                  </td>
                                  <td
                                    style={{
                                      verticalAlign: "baseline",
                                      textAlign: "center",
                                    }}
                                  >
                                    {moment(item.split("Time$#")[1]).format(
                                      "DD-MM-YYYY hh:mm:a"
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}

                {currentFileDetails.action != "comment" && (
                  <>
                    <div className="rejectInput">
                      <textarea
                        className={!rejectedText ? "errorVal" : ""}
                        onChange={hanndleChange}
                        name="reject"
                        placeholder={
                          "Please add comment for" +
                          `${
                            currentFileDetails.action == "accept"
                              ? " Acceptance"
                              : " Rejection"
                          }`
                        }
                        maxLength={2000}
                      />
                    </div>
                    {/* {!rejectedText && <><small className="text-danger" style={{fontSize:'14px',fontWeight:'500'}}>Comment is required</small> <br /></>  }  */}

                    <small>
                      <i>Max.characters - 2000</i>
                    </small>
                  </>
                )}
                <div className="modal-action mt-2">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    // onClick={() => setConfirmationPopup(false)}
                    onClick={dismissModal}
                  >
                    {currentFileDetails.action == "comment" ? "OK" : "NO"}
                  </button>
                  {currentFileDetails.action != "comment" && (
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      // onClick={confirmSubmit}
                      onClick={() =>
                        currentFileDetails.action == "reject"
                          ? removeSTFileUpload(
                              currentFileDetails.file_no,
                              rejectedText,
                              currentFileDetails.st_application_id
                            )
                          : acceptSTFileUpload(
                              currentFileDetails.file_no,
                              rejectedText,
                              currentFileDetails.st_application_id
                            )
                      }
                    >
                      YES
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* incubator action taking popup */}
      {incFileConfirmationPopup && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <h6 className="modal-message mt-2">
                  {currentFileDetails.action == "accept"
                    ? "Are you sure you want to accept the file?"
                    : currentFileDetails.action == "reject"
                    ? "Are you sure you want to reject the file?"
                    : "Comments"}
                </h6>
                {<span className="text-danger">{readFileFirst}</span>}
                {currentFileDetails.action == "comment" && (
                  <>
                    {currentFileDetails.comment == null ? (
                      "No Comments"
                    ) : (
                      <div style={{ maxHeight: "40vh", overflow: "scroll" }}>
                        <table className="table-bordered mt-0">
                          <thead>
                            <th className="text-center">Status</th>
                            <th className="text-center">Comment</th>
                            <th className="text-center">Date</th>
                          </thead>
                          <tbody>
                            {currentFileDetails.comment &&
                              currentFileDetails.comment.map((item: any, i) => (
                                <tr key={i}>
                                  <td
                                    style={{
                                      verticalAlign: "baseline",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.split(":")[0]}
                                  </td>
                                  <td
                                    className="commentsColumn"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item.split("ted:")[1].split("Time$#")[0]}
                                  </td>
                                  <td
                                    style={{
                                      verticalAlign: "baseline",
                                      textAlign: "center",
                                    }}
                                  >
                                    {moment(item.split("Time$#")[1]).format(
                                      "DD-MM-YYYY hh:mm:a"
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}
                {currentFileDetails.action != "comment" && (
                  <>
                    <div className="rejectInput">
                      <textarea
                        className={!rejectedText ? "errorVal" : ""}
                        onChange={hanndleChange}
                        name="reject"
                        placeholder={
                          "Please add comment for" +
                          `${
                            currentFileDetails.action == "accept"
                              ? " Acceptance"
                              : " Rejection"
                          }`
                        }
                        maxLength={2000}
                      />
                    </div>
                    {/* {!rejectedText && <><small className="text-danger" style={{fontSize:'14px',fontWeight:'500'}}>Comment is required</small> <br /></>  }  */}

                    <small>
                      <i>Max.characters - 2000</i>
                    </small>
                  </>
                )}
                <div className="modal-action mt-2">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    // onClick={() => setConfirmationPopup(false)}
                    onClick={dismissModal}
                  >
                    {currentFileDetails.action == "comment" ? "OK" : "NO"}
                  </button>
                  {currentFileDetails.action != "comment" && (
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      // onClick={confirmSubmit}
                      onClick={() =>
                        currentFileDetails.action == "reject"
                          ? removeIncFileUpload(
                              currentFileDetails.file_no,
                              rejectedText
                            )
                          : acceptIncFileUpload(
                              currentFileDetails.file_no,
                              rejectedText
                            )
                      }
                    >
                      YES
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* saved as draft popup */}
      {submitPopup && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <i
                  style={{ fontSize: "40px", color: "green" }}
                  className="fa fa-check-circle-o"
                ></i>

                <h5
                  className="message"
                  style={{
                    fontFamily: "Roboto Slab",
                    color: "green",
                    fontSize: "26px",
                  }}
                >
                  Tranche Requested Successfully
                </h5>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="fileInfo"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none pb-0">
              <div className="text-center">
                {/* <h5 className="modal-title " id="exampleModalLongTitle">

                                    <span>ISFS Subsequent Tranche SOP – Do's and Don’ts
                                    </span>
                                </h5> */}
              </div>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className="row mb-3">
                                <div className="col-12 text-center">
                                    <h5 className="modal-title " id="exampleModalLongTitle">

                                        <span>SISFS Subsequent Tranche SOP – Do's and Don’ts
                                        </span>
                                    </h5>
                                </div>
                            </div> */}
              <div className="pl-3">
                {filename == "ListStartups" && (
                  <>
                    <span className="Info-subhead">
                      List of Startups Selected
                    </span>{" "}
                  </>
                )}
                {filename == "CA" && (
                  <>
                    <span className="Info-subhead">
                      Certification from a Practicing CA that Startup has not
                      received more than Rs 10 lakh of monetary support under
                      any other Central or State Government scheme (para-3.1,
                      point-5)
                    </span>{" "}
                  </>
                )}
                {filename == "Interim" && (
                  <>
                    <span className="Info-subhead">
                      Interim Progress Reports and Utilization Certificate of
                      startups (para-8.7){" "}
                    </span>{" "}
                  </>
                )}
                {filename == "LegalAgreement" && (
                  <>
                    <span className="Info-subhead">
                      Copy of legal agreements executed between Incubator and
                      Startups (para-8.4){" "}
                    </span>{" "}
                  </>
                )}
              </div>

              <FileUploadInfo fileName={filename} />
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubsequentTrancheTwo;
