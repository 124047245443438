import React, { Component, createRef, useEffect, useRef, useState } from "react";
import DashboardHeaderComponent from "../common/DashboardHeaderComponent";
import DashboardFooterComponent from "../../common/footer/DashboardFooterComponent";
import EACReview from "../incubator/EACReviewComponent";

import filter from "../../../images/filter.svg";
import edit from "../../../images/edit.svg";
import search from "../../../images/search.svg";
import error from "../../../images/error.svg";
import ReApplied from "./ReAppliedComponent"
import ReactPaginate from 'react-paginate';
import '../../../styles/dashboard.css';
import {
  GET_ALL_LEGAL_ENTITY,
  GET_ALL_SECTOR,
  GET_ALL_STATES,
  //   GET_INCUBATOR_LISTING_COUNT,
  SCHEDULE_MEETING,
  GET_APPLICATION_STATUS,
  GET_QUICK_EDIT,
  QUICK_EDIT,
  DOWNLOAD_EXCEL,
  MERGED_BUCKET_COUNT,
  MERGED_ALL_TABLE_LIST,
  MERGED_SCHEDULE_MEETING,
  DOWNLOAD_EXCEL_MERGED,
  GET_Re_QUICK_EDIT,
  QUICK_Re_EDIT,
  MERGED_FILTER,
  MERGED_FILTER_BUCKET_LIST,
  File_node_url
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import "../../../styles/dashboard.css";
import { Modal, Button } from "react-bootstrap";
import MultiSelectDropdown from "../../common/controls/MultiSelectDropdown";
import { DropdownModel } from "../../../models/DropdownModel";
import Calendar from "../../common/controls/Calendar";
//import { strict } from 'node:assert';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { compareTime, FormatDate } from "../../../utils/DateFormat";
import { formatTime } from "../../../utils/DateFormat";
import MultiSelect from "../../common/controls/MultiSelect";
import { QuickEditPopupData } from "../incubator/ApplicationGeneralDetails";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import IncubatorReportComponent from "../../incubator/dashboard/IncubatorReportComponent";
// import * as XLSX from 'xlsx';
import {
  changeNumberFormat,
  downloadDocument,
  filterByValue,
} from "../../../utils/CommonMethods";
import moment from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { exportToExcelMultipleSheets } from "../../../constants/ExcelServices";

function IncAppliedReappliedContainer(props: any) {
  const ExcelDataId: any = [];
  const ExcelDataIdF1: any = [];
  const [Excelpaylod, setExcelpaylod] = useState('');
  const [ExcelpaylodF1, setExcelpaylodF1] = useState('');
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [timeValidation, setTimeValidation] = useState(false);
  // const [timeValidationCurrentTime, setTimeValidationCurrentTime] =
  //   useState(false);
  const [linkValidation, setLinkValidation] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [checkBoxHide, setCheckBoxHide] = useState(true);
  const [showEditFailureModal, setShowEditFailureModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [selectFlag, setSelectFlag] = useState(true);
  const [noDataFilter, setNoDataFilter] = useState("");
  const [incubatorListingCounts, setIncubatorListingCounts] = useState<any>({
    total_id: "0",
    incomplete: "0",
    onHold: "0",
    pendingReview: "0",
    rejected: "0",
    selected: "0",
    submitted: "0",
    reviewCount: "0",
    trancheRequest: "0",
    cancel: "0",
    closed: "0",
    cancelled: "0",
    entity: '0'
  });
  const [incubatorList, setincubatorList] = useState([
    {
      application_id: "",
      //  incubator_application_id:"",
      application_date: "",
      user_id: "",
      government_assisted: "",
      state_name: "",
      incubator_name: "",
      total_approved_amt: "",
      total_disbursed_amt: "",
      meeting_date: "",
      meeting_time: "",
      cancellation_status: "",
      meeting_id: "",
      meeting_status: "",
      status_id: "",
      count_startups_supported: "",
      amount_disbursed: "",
      returns_approved: "",
      incubator_application_no: "",
      designation: '',
      dipp_number: '',
      email: '',
      same_legal_entity: '',
      secondapplicationdate: '',
      registered_name: '',
      application_index: '',
      formattedDate: ''
    },
  ]);
  const [incompleteApplications, setIncompleteApplications] = useState([]);
  const [pendingDataAll, setpendingDataAll] = useState([]);
  const [filteredPendingDataAll, setFilteredPendingDataAll] = useState([]);
  const [pendingDataReview, setpendingDataReview] = useState([]);
  const [filteredPendingDataReview, setFilteredPendingDataReview] = useState(
    []
  );
  const [pendingData, setpendingData] = useState([]);
  const [filteredPendingData, setFilteredPendingData] = useState([]);
  const [filteredIncubatorList, setFilteredIncubatorList] = useState([
    {
      application_id: "",
      application_date: "",
      user_id: "",
      government_assisted: "",
      application_index: "",
      state_name: "",
      incubator_name: "",
      total_approved_amt: "",
      total_disbursed_amt: "",
      meeting_date: "",
      meeting_time: "",
      cancellation_status: "",
      meeting_id: "",
      meeting_status: "",
      status_id: "",
      count_startups_supported: "",
      amount_disbursed: "",
      returns_approved: "",
      incubator_application_no: "",
      designation: '',
      dipp_number: '',
      email: '',
      same_legal_entity: '',
      secondapplicationdate: '',
      registered_name: '',
      formattedDate: ''
    },
  ]);
  const [incubatorListCountHead, setincubatorListCountHead] = useState<any>("");
  const [incubatorListId, setIncubatorListId] = useState<any>({
    total_id: "",
    draft: "",
    incomplete_id: "",
    onHold_id: "",
    pendingReview_id: "",
    rejected_id: "",
    selected_id: "",
    submitted_id: "",
    trancheRequest_id: "",
    closed_id: "",
  });
  const history = useHistory();
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [DontMoveToSubmit, setDontMoveToSubmit] = useState(false);
  const [countryStates, setCountryStates] = useState([]);
  const [sectorDetails, setSectorDetails] = useState([]);
  const [entityDetails, setEntityDetails] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [oneStartupCheckbox, setOneStartup] = useState(false);
  const [columnHideFlag, setColumnHideFlag] = useState(true);
  const [tableHeading, setTableHeading] = useState("Total Applications");
  const [noStartupCheckbox, setNoStartup] = useState(false);
  const [selectedStateValue, setStateValue] = useState<Array<string>>([]);
  const [selectedSectorValue, setSectorValue] = useState<Array<string>>([]);
  const [selectedEntityValue, setEntityValue] = useState<Array<string>>([]);
  const [checkedArray, setCheckedArray] = useState<Array<string>>([]);
  const [fromTime, setFromTime] = useState(String);
  const [toTime, setToTime] = useState(String);
  const [incubatorMeetingType, setincubatorMeetingType] = useState("1");
  const [scheduleDate, setScheduleDate] = useState(String);
  const [selectedDateControl, setSelectedDateControl] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState<any>("");
  const [Active_Sub_Index, setActive_Sub_Index] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [showFilterDot, setShowFilterDot] = useState(false);
  const [showEditTranche, setShowEditTranche] = useState(false);
  const [mouseOverFlag, setMouseOverFlag] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [meetingComment, setMeetingComment] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [RoneIndex, setRoneIndex] = useState<any>('');
  const [quickEditPopupData, setquickEditPopupData] = useState({
    application_comments: "",
    application_id: "",
    application_status: "",
    corporate_id_no: "",
    incubator_name: "",
    no_of_tranches: "",
    date_of_evaluation: "",
    total_approved_amt: "",
    incubator_application_no: "",
    total_disbursed_amt: "",
    Amount_Disbursed_to_Startups: ""
  });
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: "",
    text: "",
  });
  const [loggedInRole, setLoggedInRole] = useState("");
  const count = useRef(0);
  const [duetime, setDueTime] = useState<any>("")
  const [applications, setApplications] = useState([])
  const [applicationsAll, setApplicationsAll] = useState([])
  const [incReview, setIncReview] = useState<any>([
    {
      application_id: "",
      application_date: "",
      government_assisted: "",
      application_index: "",
      state_name: "",
      incubator_name: "",
      total_approved_amt: "",
      total_disbursed_amt: "",
      meeting_date: "",
      meeting_time: "",
      cancellation_status: "",
      meeting_id: "",
      meeting_status: "",
      status_id: "",
      count_startups_supported: "",
      amount_disbursed: "",
      incubator_application_no: "",
      designation: '',
      dipp_number: '',
      email: '',
      same_legal_entity: '',
      secondapplicationdate: '',
      registered_name: '',
      user_id: ''
    },
  ])
  const [reapplyData, setReapplyData] = useState<any>([
    {
      application_id: "",
      application_date: "",
      government_assisted: "",
      application_index: "",
      state_name: "",
      incubator_name: "",
      total_approved_amt: "",
      total_disbursed_amt: "",
      meeting_date: "",
      meeting_time: "",
      cancellation_status: "",
      meeting_id: "",
      meeting_status: "",
      status_id: "",
      count_startups_supported: "",
      amount_disbursed: "",
      incubator_application_no: "",
      designation: '',
      dipp_number: '',
      email: '',
      same_legal_entity: '',
      secondapplicationdate: '',
      registered_name: '',
      user_id: "",
    },
  ])
  const [columnHideFlag1, setColumnHideFlag1] = useState(false);
  const [responsedata, setResponsedata] = useState([])
  const [legaldata, setLegaldata] = useState([])

  const [loader, setLoader] = useState(false);
  const [meetingstatus, setMeetingstatus] = useState("")
  var currentdate = moment().format("YYYY-MM-DD")
  var date = new Date(currentdate)
  useEffect(() => {
    // console.log(moment(date.setDate(date.getDate() + 90)).format("YYYY-MM-DD"), "dateeeee");
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    console.log(userInfo)
    if (userInfo !== null) {

      setLoggedInRole(userInfo.roles);
    }
    // console.log(loggedInRole,"rolesssdd")

    window.scrollTo(0, 0);
    // getAllStates();
    getIncubatorCount();
    // getDataByStatus(0)
    // getAllSectors();
    // getAllLegalEntity();
    localStorage.removeItem("value")
    localStorage.setItem("value", "1")
    if (props.location.state?.flags == 'schedule') {
      getDataByStatus('3')
    } else {
      getDataByStatus(0)
    }
    // console.log(count.current, "cuRentttt")
  }, []);
  // get initial count of incubators
  const getIncubatorCount = () => {
    setCheckBoxHide(true);
    setButtonDisable(true);
    setColumnHideFlag(true);
    setColumnHideFlag1(false);

    get(MERGED_BUCKET_COUNT).then((response: any) => {
      // console.log(response.data.TranccheRequestCount,"dsssssssssssssssssssss")
      if (!response || !response.data) return;
      const counts = {
        total_id: 0,
        draft: 0,
        incomplete: 0,
        onHold: 0,
        pendingReview: 0,
        rejected: 0,
        selected: 0,
        submitted: 0,
        reviewCount: response.data.reviewCount
          ? response.data.reviewCount
          : "0",
        trancheRequest: response.data.TranccheRequestCount,
        cancel: response.data.cancelRequestCount,
        closed: 0,
        cancelled: 0,
        entity: response.data.reapplyLegalEntityCount
      };
      const status_id = {
        total_id: 0,
        draft: 0,
        incomplete_id: "",
        onHold_id: "",
        pendingReview_id: "",
        rejected_id: "",
        selected_id: "",
        submitted_id: "",
        trancheRequest_id: "",
        closed_id: "",
      };
      for (var i = 0; i < response.data.Data.length; i++) {
        // if (response.data.Data[i].name == "incomplete") {
        //   counts.incomplete = response.data.Data[i].bucket_count;
        //   status_id.incomplete_id = response.data.Data[i].id;
        // } else if (response.data.Data[i].name == "draft") {
        //   counts.draft = response.data.Data[i].bucket_count;
        //   status_id.draft = response.data.Data[i].id;
        // }

        // else if (response.data.Data[i].name == "pending_review") {
        //   counts.pendingReview = response.data.Data[i].bucket_count;
        //   status_id.pendingReview_id = response.data.Data[i].id;
        // } else if (response.data.Data[i].name == "submitted") {
        //   counts.submitted = response.data.Data[i].bucket_count;
        //   status_id.submitted_id = response.data.Data[i].id;
        // } else if (response.data.Data[i].name == "on_hold") {
        //   counts.onHold = response.data.Data[i].bucket_count;
        //   status_id.onHold_id = response.data.Data[i].id;
        // } else if (response.data.Data[i].name == "rejected") {
        //   counts.rejected = response.data.Data[i].bucket_count;
        //   status_id.rejected_id = response.data.Data[i].id;
        // } else if (response.data.Data[i].name == "selected") {
        //   counts.selected = response.data.Data[i].bucket_count
        //   //   - response.data.cancellationApprovedCount - response.data.cancellationRequestedCount;
        //   status_id.selected_id = response.data.Data[i].id;
        // } else if (response.data.Data[i].name == "closed") {
        //   counts.closed = response.data.Data[i].bucket_count;
        //   status_id.closed_id = response.data.Data[i].id;
        // } else if (response.data.Data[i].name == "cancelled") {
        //   counts.cancelled = response.data.Data[i].bucket_count
        //   // counts.cancelled = response.data.application_cancelled_count
        //   //    + response.data.cancellationApprovedCount;

        // }
        // console.log(counts, "counts")
        // console.log(response.data.Data, "counts")
        if (response.data.Data[i].id === 2) {
          counts.submitted += parseInt(response.data.Data[i].appln_count)
          // console.log(counts)
        }
        else if (response.data.Data[i].id === 3) {
          counts.pendingReview += parseInt(response.data.Data[i].appln_count)
        }
        else if (response.data.Data[i].id === 4) {
          counts.incomplete += parseInt(response.data.Data[i].appln_count)
        }
        else if (response.data.Data[i].id === 5) {
          counts.onHold += parseInt(response.data.Data[i].appln_count)
        }
        else if (response.data.Data[i].id === 7) {
          counts.selected += parseInt(response.data.Data[i].appln_count)
        }
        else if (response.data.Data[i].id === 6) {
          counts.rejected += parseInt(response.data.Data[i].appln_count)
        }
        else if (response.data.Data[i].id === 8) {
          counts.closed += parseInt(response.data.Data[i].appln_count)
        }
        else if (response.data.Data[i].id === 9) {
          counts.cancelled += parseInt(response.data.Data[i].appln_count)
        }
        else if (response.data.Data[i].id === 1) {
          counts.draft += parseInt(response.data.Data[i].appln_count)
        }
      }
      // response.data.Data.map((item:any)=>{
      //   console.log(item,"bucket")
      // })
      response.data.Data.map((item: any) => item.appln_count).reduce((prev: any, next: any) =>
        counts.total_id = prev + next);

      counts.total_id = counts.total_id - counts.draft


      setIncubatorListingCounts(counts);
      // console.log(incubatorListingCounts, "countssss")
      setIncubatorListId(status_id);
      getAllStates();
    });
  };

  // get states in dropdown
  const getAllStates = () => {
    get(GET_ALL_STATES).then((response: any) => {
      if (!response || !response.data) return;
      const states = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.name,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setCountryStates(states);
      getAllSectors();
    });
  };
  //get application status
  const getApplicationStatus = () => {
    get(GET_APPLICATION_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data);
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));
      setApplicationStatus(status);
    });
  };

  // get all sectors in dropdown
  const getAllSectors = () => {
    // console.log("sectors!!!!")
    // const param = sectorDetails.length == 0 ? '0' : sectorDetails
    const param = '0'
    get(`${GET_ALL_SECTOR}/0`).then((response: any) => {
      if (!response || !response.data) return;
      const sectors = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setSectorDetails(sectors);
      getAllLegalEntity();
    });
  };

  // get entity values in dropdown
  const getAllLegalEntity = () => {
    get(GET_ALL_LEGAL_ENTITY).then((response: any) => {
      if (!response || !response.data) return;
      const entity = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setEntityDetails(entity);
    });
  };

  // apply filter button click function
  const applyFilter = () => {
    setIncReview([])
    setReapplyData([])

    if (
      selectedEntityValue.length != 0 ||
      selectedStateValue.length != 0 ||
      selectedSectorValue.length != 0 ||
      oneStartupCheckbox == true ||
      noStartupCheckbox == true
    ) {
      setShowFilterDot(true);
    } else {
      setShowFilterDot(false);
    }


    setTableHeading("Total Applications");
    setActiveIndex("");
    var payload = {};
    payload = {
      legal_entity_id:
        selectedEntityValue.length != 0 ? selectedEntityValue : "",
      state_id: selectedStateValue.length != 0 ? selectedStateValue : "",
      sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : "",
      status_id: 0,
      startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
      no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
    };

    if (
      selectedEntityValue.length == 0 &&
      selectedStateValue.length == 0 &&
      selectedSectorValue.length == 0 &&
      oneStartupCheckbox == false &&
      noStartupCheckbox == false
    ) {
      // console.log("sri####")
      setFailDialogData({
        header: "Oops",
        text: "Please select any of the category to Filter",
      });
      setShowIncubatorFailureModal(true);
      getIncubatorCount()
      setActiveIndex('0')
      getDataByStatus("0")

    } else {
      setLoader(true);

      post(MERGED_FILTER, payload).then((response: any) => {
        setLoader(false);

        if (!response || !response.data) return;
        const counts: any = {
          total_id: 0,
          draft: 0,
          incomplete: "0",
          onHold: "0",
          pendingReview: "0",
          rejected: "0",
          selected: 0,
          submitted: "0",
          reviewCount: "0",
          trancheRequest: '0',
          cancel: '0',
          closed: "0",
          cancelled: '0',
          entity: '0',
          // cancellationRequest:'0'
          // entityRequest:response

        };
        const status_id = {
          total_id: 0,
          draft: 0,
          incomplete_id: "",
          onHold_id: "",
          pendingReview_id: "",
          rejected_id: "",
          selected_id: "",
          submitted_id: "",
          trancheRequest_id: "",
          closed_id: "",
        };

        counts.submitted = response.data.bucket_count.submitted;
        status_id.submitted_id = "2";

        counts.pendingReview = response.data.bucket_count.reviewed;
        status_id.pendingReview_id = "3";

        counts.incomplete = response.data.bucket_count.incomplete;
        status_id.incomplete_id = "4";

        counts.onHold = response.data.bucket_count.on_hold;
        status_id.onHold_id = "5";

        counts.rejected = response.data.bucket_count.rejected;
        status_id.rejected_id = "6";

        counts.selected = response.data.bucket_count.selected
        status_id.selected_id = "7";

        counts.closed = response.data.bucket_count.closed
        status_id.closed_id = "8";

        counts.cancelled = response.data.bucket_count.cancelled

        counts.entity = response.data.bucket_count.entityRequest

        counts.trancheRequest = response.data.bucket_count.trancheRequest

        counts.cancel = response.data.bucket_count.cancellationRequest
        // console.log(response.data,"@@@@key")

        let totalSum = 0;
        for (let key in counts) {
          if (key == 'entity') {
            continue; // skip the 'incomplete' key
          }
          if (key == 'trancheRequest') {
            continue;
          }
          if (key == 'cancel') {
            continue;
          }
          if (typeof counts[key] === 'number' && !isNaN(counts[key])) {
            totalSum += counts[key];
          }
        }
        // console.log(counts, "countsssssss")
        counts.total_id = totalSum



        setIncubatorListingCounts(counts);

        setIncubatorListId(status_id);


        // setincubatorListCountHead(response.data.length);

      });
      // getDataByStatus(0)
      setActiveIndex(0);
      getDataByStatus("0")

    }
  };

  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    // setShowEditFailureModal(false);
    // setShowEditSuccessModal(false);
    window.location.reload();
  };

  // handeling multiselect checkbox in dropdown value
  const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
    if (name == "state") {
      setStateValue(selectedIds);
    } else if (name == "sector") {
      setSectorValue(selectedIds);
    } else if (name == "entity") {
      setEntityValue(selectedIds);
    }
  };

  // reset button function
  const resetFilter = () => {
    setTableHeading("Total Applications");
    setActiveIndex("");
    setStateValue([]);
    setSectorValue([]);
    setEntityValue([]);
    setOneStartup(false);
    setShowFilterDot(false);
    setNoStartup(false);
    getIncubatorCount();
    window.location.reload()
  };
  // const [totalPages,setTotalPages] = useState<any>()
  // get data on click status card
  const getDataByStatus = (selected_id: any) => {

    setCurrentPage(0)
    // count.current = 0
    setColumnHideFlag1(false);
    // if(selectedEntityValue.length == 0&&selectedStateValue.length == 0&&selectedSectorValue.length == 0){
    //   getIncubatorCount();

    // }

    // console.log(selected_id, selected_id == "entity", "selected_id")
    if (selected_id == "2") {
      // count.current == 0
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Submitted");
      setincubatorListCountHead(incubatorListingCounts.submitted);
    } else if (selected_id == "3") {
      setincubatorMeetingType("1");
      setButtonDisable(false);
      setCheckBoxHide(false);
      setCheckedArray([]);
      setTableHeading("Applications to be Reviewed");
      setincubatorListCountHead(incubatorListingCounts.pendingReview);
    } else if (selected_id == "4") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Incomplete");
      setincubatorListCountHead(incubatorListingCounts.incomplete);
    } else if (selected_id == "5") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications On Hold");
      setincubatorListCountHead(incubatorListingCounts.onHold);
    } else if (selected_id == "6") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Rejected");
      setincubatorListCountHead(incubatorListingCounts.rejected);
    } else if (selected_id == "7") {
      setincubatorMeetingType("2");
      setButtonDisable(false);
      setCheckBoxHide(false);
      setCheckedArray([]);
      setTableHeading("Applications Selected");
      setincubatorListCountHead(incubatorListingCounts.selected);
    } else if (selected_id == "8") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Closed");
      setincubatorListCountHead(incubatorListingCounts.closed);
    } else if (selected_id == "tranche") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Tranche Requested");
      setincubatorListCountHead(incubatorListingCounts.trancheRequest);
    } else if (selected_id == "cancel") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Cancellation Requested");
      setincubatorListCountHead(incubatorListingCounts.cancel);
    } else if (selected_id == "cancelled") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Cancelled");
      setincubatorListCountHead(incubatorListingCounts.cancelled);
    } else if (selected_id == 0) {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Total Applications");
      setincubatorListCountHead(incubatorListingCounts.total_id);
    } else if (selected_id == "entity") {
      setColumnHideFlag1(true);
      setColumnHideFlag(true);
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Incubators with different legal entity");
      // console.log(incubatorListingCounts, "7800000")
      setincubatorListCountHead(incubatorListingCounts.entity);
    }
    setActiveIndex(selected_id);
    var payload = {};
    if (selected_id == "tranche") {
      // console.log(selectedEntityValue.length == 0 && selectedStateValue.length == 0 && selectedSectorValue.length == 0, "trueeee")
      if (selectedEntityValue.length == 0 &&
        selectedStateValue.length == 0 &&
        selectedSectorValue.length == 0 &&
        oneStartupCheckbox == false &&
        noStartupCheckbox == false
      ) {
        payload = {
          "tranche_request": "Yes",
          "cancel_request": "",
          "cancel_approved": "",
          "status_id": 0,
          "same_legal_entity": ""

          // "incubator":count.current
        };
      } else {
        // console.log("sri$$$$")
        payload = {
          "tranche_request": "Yes",
          "cancel_request": "",
          "cancel_approved": "",
          "status_id": 0,
          "same_legal_entity": "",
          legal_entity_id:
            selectedEntityValue.length != 0 ? selectedEntityValue : "",
          state_id: selectedStateValue.length != 0 ? selectedStateValue : "",
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : "",
          startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
          no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
          // "incubator":count.current
        };
        // console.log(payload, "payloadPPPPPP")
      }
    } else if (selected_id == "cancel") {
      if (selectedEntityValue.length == 0 &&
        selectedStateValue.length == 0 &&
        selectedSectorValue.length == 0 &&
        oneStartupCheckbox == false &&
        noStartupCheckbox == false
      ) {
        payload = {
          "cancel_request": "Yes",
          "tranche_request": "",
          "cancel_approved": "",
          "status_id": 0,
          "same_legal_entity": ""
        }
      } else {
        payload = {
          "cancel_request": "Yes",
          "tranche_request": "",
          "cancel_approved": "",
          "status_id": 0,
          "same_legal_entity": "",
          legal_entity_id:
            selectedEntityValue.length != 0 ? selectedEntityValue : "",
          state_id: selectedStateValue.length != 0 ? selectedStateValue : "",
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : "",
          startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
          no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
        }
      }
    } else if (selected_id == "cancelled") {
      if (selectedEntityValue.length == 0 &&
        selectedStateValue.length == 0 &&
        selectedSectorValue.length == 0 &&
        oneStartupCheckbox == false &&
        noStartupCheckbox == false
      ) {
        payload = {
          "cancel_approved": "Yes",
          "tranche_request": "",
          "status_id": 0,
          "cancel_request": "",
          "same_legal_entity": ""
        }
      } else {
        payload = {
          "cancel_approved": "Yes",
          "tranche_request": "",
          "status_id": 0,
          "cancel_request": "",
          "same_legal_entity": "",
          legal_entity_id:
            selectedEntityValue.length != 0 ? selectedEntityValue : "",
          state_id: selectedStateValue.length != 0 ? selectedStateValue : "",
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : "",
          startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
          no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
        }
      }
    } else if (selected_id == "entity") {
      if (selectedEntityValue.length == 0 &&
        selectedStateValue.length == 0 &&
        selectedSectorValue.length == 0 &&
        oneStartupCheckbox == false &&
        noStartupCheckbox == false
      ) {
        payload = {
          "cancel_approved": "",
          "tranche_request": "",
          "status_id": 0,
          "cancel_request": "",
          "same_legal_entity": 'No'
        }
      } else {
        payload = {
          "cancel_approved": "",
          "tranche_request": "",
          "status_id": 0,
          "cancel_request": "",
          "same_legal_entity": 'No',
          legal_entity_id:
            selectedEntityValue.length != 0 ? selectedEntityValue : "",
          state_id: selectedStateValue.length != 0 ? selectedStateValue : "",
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : "",
          startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
          no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
        }
      }

    }


    else {
      setColumnHideFlag1(false);
      if (selectedEntityValue.length == 0 &&
        selectedStateValue.length == 0 &&
        selectedSectorValue.length == 0 &&
        oneStartupCheckbox == false &&
        noStartupCheckbox == false
      ) {
        payload = {
          status_id: parseInt(selected_id),
          "tranche_request": "",
          "cancel_request": "",
          "cancel_approved": "",
          "same_legal_entity": "",

        };
      } else {
        payload = {
          status_id: parseInt(selected_id),
          legal_entity_id:
            selectedEntityValue.length != 0 ? selectedEntityValue : "",
          state_id: selectedStateValue.length != 0 ? selectedStateValue : "",
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : "",
          startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
          no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
        }
      }

    }
    // console.log(selectedEntityValue.length, "payloaddd@@@@@")
    setLoader(true);

    const test = (selectedEntityValue.length == 0 &&
      selectedStateValue.length == 0 &&
      selectedSectorValue.length == 0 &&
      oneStartupCheckbox == false &&
      noStartupCheckbox == false
    ) ? MERGED_ALL_TABLE_LIST : MERGED_FILTER_BUCKET_LIST
    // if((selectedEntityValue.length !== 0||selectedStateValue.length !== 0||selectedSectorValue.length !== 0) ){

    // }
    // const newPayload = (selectedEntityValue.length == 0&&selectedStateValue.length == 0&&selectedSectorValue.length == 0) ?payload:filterPayload

    post(test, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      // console.log(response.data.Data, "dat%%%%%")
      if (response.data.Data.length == 0) {
        setincubatorList([])
        setincubatorListCountHead(0)
      }
      setApplications(response.data.Data);
      setApplicationsAll(response.data.Data);

      // console.log(response.data.Data, "incReview@@")
      setIncReview(
        response.data.Data.filter(
          (item: any) => item.application_index == 'F1',
        ),
      );
      setReapplyData(
        response.data.Data.filter(
          (item: any) => item.application_index == 'R1',
        ),
      );


      if (response.data.Data.length != 0) {
        response.data.Data.forEach((employee: any, index: any) => {
          if (employee.application_index == "R1") {
            ExcelDataId.push(
              employee.id
            );
          } else {
            ExcelDataIdF1.push(
              employee.id
            );
          }
        });
        setExcelpaylod(ExcelDataId);
        setExcelpaylodF1(ExcelDataIdF1)
        const currentDate = new Date(); // Get the current date
        const futureDate = moment(currentDate).add(90, 'days'); // Add 90 days using moment.js
        const formattedDate1 = new Date(currentDate.setDate(currentDate.getDate() + 90)).toString()

        // response.data.Data.map((item:any)=>{
        //    item.formattedDate = formattedDate1
        // })


        const incubatorListData = response.data.Data.map(
          (item: any) => ({
            application_id: item.id,
            incubator_application_no: item.incubator_application_no,
            user_id: item.user_id,
            application_date:
              item.first_submitted_date != null
                ? FormatDate(item.first_submitted_date)
                : null,
            application_index: item.application_index,
            government_assisted: item.government_assisted,
            state_name: item.state_name,
            incubator_name: item.registered_name,
            total_approved_amt: item.amount_approved,
            total_disbursed_amt: item.total_disbursed_amt,
            meeting_date: (item.status_id === 3)
              ? ((item.meeting_date === undefined) || (item.meeting_date === null))
                ? null
                : item.meeting_date

              : (item.status_id === 7)
                ? ((item.meeting_date == undefined) || (item.meeting_date == null))
                  ? formattedDate1
                  : item.meeting_date
                : null,
            meeting_time: ((item.status_id == "3" || item.status_id == "7") ? ((item.meeting_from_time != undefined || item.meeting_from_time !== "") ? item.meeting_from_time : 'NA') : 'NA'),
            meeting_status: (item.status_id === 3)
              ? ((item.meeting_date === undefined) || (item.meeting_date === null))
                ? 'NA'
                : (new Date(item.meeting_date).toDateString() === currentDate.toDateString()) ? "Today" : 'Scheduled'

              : (item.status_id === 7)
                ? ((item.meeting_date === undefined) || (item.meeting_date === null))
                  ? 'Due Review'
                  : (new Date(item.meeting_date).toDateString() === currentDate.toDateString()) ? "Today" : 'Scheduled'
                : 'NA',
            cancellation_status: item.cancellation_status
              ? item.cancellation_status
              : "",

            status_id: item.status_id,
            count_startups_supported:
              item.No_Startups_Supported != null
                ? item.No_Startups_Supported
                : "0",
            amount_disbursed:
              item.Amount_Disbursed_to_Startups != null ? item.Amount_Disbursed_to_Startups : "0",
            returns_approved: item.total_returns_approved != null ? item.total_returns_approved : "0",
            designation: item.designation,
            dipp_number: item.dipp_number,
            email: item.email,
            same_legal_entity: item.same_legal_entity,
            secondapplicationdate: item.secondapplicationdate,
            formattedDate: item.formattedDate
            // registered_name: item.registered_name,
          })
        );
        // console.log(response.data.Data,"data@@@@@@@@@")
        setFilteredIncubatorList([
          {
            application_id: "",
            application_date: "",
            user_id: "",
            application_index: "",
            government_assisted: "",
            state_name: "",
            incubator_name: "",
            total_approved_amt: "",
            total_disbursed_amt: "",
            meeting_date: "",
            meeting_time: "",
            cancellation_status: "",
            meeting_id: "",
            meeting_status: "",
            status_id: "",
            count_startups_supported: "",
            amount_disbursed: "",
            returns_approved: "",
            incubator_application_no: "",
            designation: '',
            dipp_number: '',
            email: '',
            same_legal_entity: '',
            secondapplicationdate: '',
            registered_name: '',
            formattedDate: ''
          },
        ]);

        setResponsedata(incubatorListData)
        setincubatorListCountHead(response.data.Data.length);
        setincubatorList(incubatorListData);
      }
      tabActive('');
      // console.log(incubatorList, "List!!!!!")
    });
    if (selected_id == "7" || selected_id == "8" || selected_id == "tranche") {
      setColumnHideFlag(true);
      // setincubatorList(reapplyData)

    } else {
      setColumnHideFlag(false);
    }
  };

  // checkbox checked event
  const handleOnCheck = (e: any /*index:number*/) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    if (isChecked) {
      if (item == "one_startup") {
        setOneStartup(true);
        setNoStartup(false);
      } else if (item == "any_startup") {
        setNoStartup(true);
        setOneStartup(false);
      }
    } else {
      if (item == "one_startup") {
        setOneStartup(false);
      } else if (item == "any_startup") {
        setNoStartup(false);
      }
    }
  };

  // setCheckedArrayCount(checkedArray.length);
  const handleListChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var isChecked = e.target.checked;

    if (isChecked) {
      setButtonDisable(false);
      if (checkedArray.includes(value) == false) {

        setCheckedArray((oldArray) => [...oldArray, value]);

      }
    } else if (isChecked == false) {
      if (checkedArray.includes(value)) {
        setCheckedArray(checkedArray.filter((item) => item !== value));
      }
    }
  };
  // allcheckbox check
  const handleAllCheck = (e: any /*index:number*/) => {
    var name = e.target.name;
    // console.log(e.target.name, "nameeeee")
    var isChecked = e.target.checked;
    if (isChecked) {
      setCheckedArray([]);
      incubatorList.map(({ incubator_application_no }) =>
        setCheckedArray((oldArray) => [...oldArray, incubator_application_no])
      );
      setIsChecked(true);
    } else if (isChecked == false) {
      setIsChecked(false);
      setCheckedArray([]);
    }
  };

  const quickEditCall = () => {
    setSelectFlag(true);

    if (quickEditPopupData.application_status == '2') {
      setDontMoveToSubmit(true);
      return;
    } else if (quickEditPopupData.application_comments == null || quickEditPopupData.application_comments == "") {
      return;
    }
    else {
      var payload = {};
      if (quickEditPopupData.application_status == "7") {
        payload = {
          application_comments: quickEditPopupData.application_comments,
          application_id: quickEditPopupData.application_id,
          incubator_application_no: quickEditPopupData.incubator_application_no,
          application_status: quickEditPopupData.application_status,
          no_of_tranches: quickEditPopupData.no_of_tranches,
          total_approved_amt: quickEditPopupData.total_approved_amt,
          date_of_evaluation: quickEditPopupData.date_of_evaluation,
        };
      } else if (quickEditPopupData.application_status != "7") {
        payload = {
          application_comments: quickEditPopupData.application_comments,
          application_id: quickEditPopupData.application_id,
          incubator_application_no: quickEditPopupData.incubator_application_no,
          application_status: quickEditPopupData.application_status,
          date_of_evaluation: quickEditPopupData.date_of_evaluation,
        };
      }
      // console.log(quickEditPopupData.Amount_Disbursed_to_Startups, quickEditPopupData.total_approved_amt, "data#####")
      if (quickEditPopupData.Amount_Disbursed_to_Startups > quickEditPopupData.total_approved_amt) {
        setFailDialogData({
          "header": "Oops",
          "text": "Entered amount is lesser than total Funds Disbursed to Startup",

        })

        setShowIncubatorFailureModal(true);
        setSelectFlag(false);
      } else if (quickEditPopupData.total_disbursed_amt > quickEditPopupData.total_approved_amt) {
        setFailDialogData({
          "header": "Oops",
          "text": "Entered amount is lesser than total Funds Disbursed to Incubator"
        })

        setShowIncubatorFailureModal(true);
        setSelectFlag(false);




      } else {
        setDisabled(true);
        setLoader(true)
        post(QUICK_EDIT, payload).then((response: any) => {
          setLoader(false)
          if (!response || !response.data) return;
          // console.log(response.data);
          // console.log("else");

          getIncubatorCount();
          setTableHeading("Total Applications");
          setActiveIndex("");
          if (response.data.result == "success") {
            setDialogData({
              header: "Thank You!",
              text: "Application has been Updated Successfully !!!",
            });
            setShowIncubatorSuccessModal(true);
            setDisabled(false);
          } else {
            setFailDialogData({
              header: "Oops,Something went wrong",
              text: "Please Try Again !!",
            });
            setShowIncubatorFailureModal(true);
            setDisabled(false);
          }
          // console.log(response.data);
        });
      }
      // setquickEditPopupData({
      //   application_comments: "",
      //   application_id: "",
      //   application_status: "",
      //   corporate_id_no: "",
      //   incubator_name: "",
      //   no_of_tranches: "",
      //   date_of_evaluation: "",
      //   total_approved_amt: "",
      //   incubator_application_no: "",
      // });
    }

  };

  const ReapplyquickEditCall = () => {
    // console.log(quickEditPopupData, "repply")
    const user: any = getSessionStorageValue('user');
    const userInfo = JSON.parse(user);

    // setValidationFlag({status_id:false,popup_comments:false})
    if (quickEditPopupData.application_comments == null || quickEditPopupData.application_comments == "") {
      return;
    } else if (
      quickEditPopupData.application_comments == '' ||
      quickEditPopupData.application_comments == null
    ) {
      // setValidationFlag(values => ({
      //   ...values,
      //   popup_comments: 'true',
      // }));

    } else if (
      quickEditPopupData.total_approved_amt == '' ||
      quickEditPopupData.total_approved_amt == null
    ) {
      // setValidationFlag(values => ({
      //   ...values,
      //   total_approved_amt: 'true',
      // }));
    } else if (
      quickEditPopupData.no_of_tranches == '' ||
      quickEditPopupData.no_of_tranches == null
    ) {
      // setValidationFlag(values => ({
      //   ...values,
      //   no_of_tranches: 'true',
      // }));
    } else if (quickEditPopupData.application_status == '2') {
      setDontMoveToSubmit(true);
      return;
    }
    else {
      // setValidationFlag(values => ({
      //   ...values,
      //   total_approved_amt: 'false',
      //   no_of_tranches: 'false',
      //   // registrationDate:"false",
      //   // status_id:"false",
      //   popup_comments: 'false',
      // }));
      setSelectFlag(true);
      var payload = {};
      if (quickEditPopupData.application_status == '7') {
        payload = {
          application_comments: quickEditPopupData.application_comments,
          application_id: quickEditPopupData.application_id,
          incubator_application_no: quickEditPopupData.incubator_application_no,
          application_status: quickEditPopupData.application_status,
          no_of_tranches: quickEditPopupData.no_of_tranches,
          total_approved_amt: quickEditPopupData.total_approved_amt,
          date_of_evaluation: quickEditPopupData.date_of_evaluation,
          user_role: userInfo.roles,
          logged_user_id: userInfo.id,
        };
      } else if (quickEditPopupData.application_status != '7') {
        payload = {
          application_comments: quickEditPopupData.application_comments,
          application_id: quickEditPopupData.application_id,
          incubator_application_no: quickEditPopupData.incubator_application_no,
          application_status: quickEditPopupData.application_status,
          date_of_evaluation: quickEditPopupData.date_of_evaluation,
          no_of_tranches: quickEditPopupData.no_of_tranches,
          total_approved_amt: quickEditPopupData.total_approved_amt,
          user_role: userInfo.roles,
          logged_user_id: userInfo.id,
        };
      }
      // console.log(quickEditPopupData.total_disbursed_amt, quickEditPopupData.total_approved_amt, "hhh")
      if (quickEditPopupData.Amount_Disbursed_to_Startups > quickEditPopupData.total_approved_amt) {
        setFailDialogData({
          "header": "Oops",
          "text": "Entered amount is lesser than total Funds Disbursed to Startup",

        })

        setShowIncubatorFailureModal(true);
        setSelectFlag(false);
      } else if (quickEditPopupData.total_disbursed_amt > quickEditPopupData.total_approved_amt) {
        setFailDialogData({
          "header": "Oops",
          "text": "Entered amount is lesser than total Funds Disbursed to Incubator"
        })

        setShowIncubatorFailureModal(true);
        setSelectFlag(false);


      } else {
        setDisabled(true);
        setLoader(true)
        post(QUICK_Re_EDIT, payload).then((response: any) => {
          setLoader(false)
          if (!response || !response.data) return;
          //console.log(response.data.date_of_evaluation,"sr");
          // console.log("Lanke")
          getIncubatorCount();
          setTableHeading('All Applications');
          setActiveIndex('');
          if (response.data.result == 'success') {
            setDialogData({
              header: 'Thank You!',
              text: 'Application has been Updated Successfully !!!',
            });
            setShowIncubatorSuccessModal(true);
            setDisabled(false);
          } else {
            setFailDialogData({
              header: 'Oops,Something went wrong',
              text: 'Please Try Again !!',
            });
            setShowIncubatorFailureModal(true);
            setDisabled(false);
          }
          //console.log(response.data);
        });
      }
      // setquickEditPopupData({
      //   application_comments: '',
      //   application_id: '',
      //   application_status: '',
      //   corporate_id_no: '',
      //   incubator_name: '',
      //   no_of_tranches: '',
      //   date_of_evaluation: '',
      //   total_approved_amt: '',
      //   incubator_application_no: '',
      //   // user_role:'',
      //   // logged_user_id:''
      // });
    }
  };
  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    setScheduleDate(formatDate(date));
    setSelectedDateControl(date);
  };

  const handlePopupDateChange = (date: Date, field: string) => {
    setquickEditPopupData((prevState) => ({
      ...prevState,
      date_of_evaluation: formatDate(date),
    }));
  };

  // on change time;
  const onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setFromTime(value);
    } else if (name == "toTime") {
      setToTime(value);
    }
  };

  // popup change
  const handlePopupChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "status_id") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_status: value,
      }));
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_comments: "",
      }));
      if (value == "7") {
        setSelectFlag(false);
      } else if (value != "7") {
        setSelectFlag(true);
      }
    } else if (name == "popup_comments") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_comments: value,
      }));
    } else if (name == "no_of_tranches") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        no_of_tranches: value,
      }));
    } else if (name == "total_approved_amt") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        total_approved_amt: value.toString().split(".").map((el: any, i: any) => i ? el.split("").slice(0, 2).join("") : el).join("."),
      }));
    } else if (name == "total_disbursed_amt") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        total_disbursed_amt: value.toString().split(".").map((el: any, i: any) => i ? el.split("").slice(0, 2).join("") : el).join("."),
      }));
    } else if (name == "Amount_Disbursed_to_Startups") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        Amount_Disbursed_to_Startups: value.toString().split(".").map((el: any, i: any) => i ? el.split("").slice(0, 2).join("") : el).join("."),
      }));
    }




  };


  // on incubator dropdown change
  const handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setincubatorMeetingType(value);
  };

  const scheduleMeetingFunction = () => {
    var time = new Date();
    // var curTime = moment(time, "HH:mm").format("HH:mm");
    if (!compareTime(fromTime, toTime)) {
      setTimeValidation(true);
      // setTimeValidationCurrentTime(false);
      return false;
    } else if (compareTime(fromTime, toTime)) {
      setTimeValidation(false);
      // setTimeValidationCurrentTime(false);
    }
    const user: any = getSessionStorageValue('user');
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const data = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: incubatorMeetingType != "" ? incubatorMeetingType : "",
      incubator_application_ids: checkedArray.length != 0 ? checkedArray : [],
      // incubator_reapplication_ids: checkedArray.length != 0 ? checkedArray : [],
      link: meetingLink,
      comment: meetingComment,
      warning_ignore: 0,
      logged_user_id: userId
    };
    console.log(data);
    if (meetingLink == "") {
      setLinkValidation(true);
      return false;
    }
    // if (
    //   formatDate(new Date()) == data.meeting_date 
    //   // compareTime(fromTime, curTime)
    // ) {
    //   // setTimeValidationCurrentTime(true);
    //   setTimeValidation(false);
    //   return false;
    // }
    if (checkedArray.length == 0) {
      setFailDialogData({
        header: "",
        text: "Please select atleast one Incubator!!",
      });
      setShowIncubatorFailureModal(true);
      return;
    }
    setDisabled(true);
    setTimeValidation(false);
    setLinkValidation(false);
    // setTimeValidationCurrentTime(false);
    // console.log("first  meetingssss")

    post(MERGED_SCHEDULE_MEETING, data).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });
        setShowIncubatorSuccessModal(true);

        setDisabled(false);
      } else if (response.data.result == "warning") {
        setConfirmationDialogData({
          header: "Meeting Already Exist",
          text: response.data.message,
        });
        setShowConfirmationBox(true);
        setDisabled(false);
      } else if (response.data.result == "error") {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: "Internal Server Error",
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };
  const finalSchedule = () => {
    const user: any = getSessionStorageValue('user');
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const data = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: incubatorMeetingType != "" ? incubatorMeetingType : "",
      incubator_application_ids: checkedArray.length != 0 ? checkedArray : [],
      link: meetingLink,
      warning_ignore: 1,
      logged_user_id: userId
    };
    setDisabled(true);
    post(MERGED_SCHEDULE_MEETING, data).then((response: any) => {
      if (!response || !response.data) return;
      setShowConfirmationBox(false);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });

        setShowIncubatorSuccessModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };

  const mouseOverEvent = (id: any) => {
    const index = incubatorList.findIndex((x) => x.application_id == id);
    setMouseOverFlag(incubatorList[index].application_id);
  };
  const handleRowChange = (id: any, userId: any, application_id: any, application_index: any, status_id: any) => {
    // console.log(loggedInRole,"mohaaaan")
    let flag = false;
    // console.log(activeIndex,"activeIndex")
    if (activeIndex == "tranche") {
      flag = true;

      // activeTab == '1'
      // localStorage.removeItem("value")
      // localStorage.setItem("value","1")
    } else {
      flag = false;
      // localStorage.removeItem("value")
      // localStorage.setItem("value","1")
    }
    if (loggedInRole == "dpiit") {
      history.replace({
        pathname: "/merged_Incubator_Details_list",
        state: { application_id: id, isFundDetails: flag, userId: userId, id: application_id, activeTab: '1', status_id: status_id, flag: "status" },
      });
      // history.replace({
      //   pathname: "/dpiit_view_incubator",
      //   state: { application_id: id, isFundDetails: flag },
      // });
    } else if (loggedInRole == "secretariat") {
      // console.log("3", "mohaaaaan")
      history.replace({
        pathname: "/merged_Incubator_Details_list",
        state: { application_id: id, isFundDetails: flag, userId: userId, id: application_id, activeTab: '1', status_id: status_id, flag: "status" },
      });
    } else if (loggedInRole == "eac") {
      history.replace({
        pathname: "/merged_Incubator_Details_list",
        state: { application_id: id, isFundDetails: flag, userId: userId, id: application_id, activeTab: '1', status_id: status_id, flag: "status" },
      });
    }
  };
  const quickEditData = (
    name: string,
    date: string,
    status_id: string,
    id: string,
    total_disbursed_amt: any
  ) => {
    setquickEditPopupData({
      application_comments: "",
      application_id: "",
      application_status: "",
      corporate_id_no: "",
      incubator_name: "",
      no_of_tranches: "",
      date_of_evaluation: "",
      total_approved_amt: "",
      incubator_application_no: "",
      total_disbursed_amt: "",
      Amount_Disbursed_to_Startups: ""
    });
    getApplicationStatus();
    post(GET_QUICK_EDIT, { application_id: id }).then((response: any) => {
      if (!response || !response.data) return;
      setquickEditPopupData({
        application_comments: response.data.data.application_comments,
        application_id: response.data.data.application_id,
        incubator_application_no: response.data.data.incubator_application_no,
        application_status: response.data.data.application_status,
        corporate_id_no: response.data.data.corporate_id_no,
        incubator_name: response.data.data.registered_name,
        no_of_tranches:
          response.data.data.no_of_tranches != null
            ? response.data.data.no_of_tranches
            : "0",
        date_of_evaluation:
          (response.data.data.date_of_evaluation != "" || response.data.data.date_of_evaluation != null)
            ? (response.data.data.date_of_evaluation == "" ? "" : formatDate(response.data.data.date_of_evaluation))
            : "",
        total_approved_amt:
          response.data.data.total_approved_amt != null
            ? response.data.data.total_approved_amt
            : "0",
        total_disbursed_amt:
          response.data.data.total_disbursed_amt != null
            ? response.data.data.total_disbursed_amt
            : "0",
        Amount_Disbursed_to_Startups:
          response.data.data.Amount_Disbursed_to_Startups != null
            ? response.data.data.Amount_Disbursed_to_Startups
            : "0"
      });
      // console.log(response.data, "total@@dis")
      if (response.data.data.application_status == "7") {
        setSelectFlag(false);
      } else if (response.data.data.application_status != "7") {
        setSelectFlag(true);
      }
      if (response.data.data.requested_tranchData.length != 0) {
        setShowEditTranche(true);
      } else if (response.data.data.requested_tranchData.length == 0) {
        setShowEditTranche(false);
      }
    });
  };

  const ReapplyquickEditData = (
    name: string,
    date: string,
    status_id: string,
    id: string,
    total_disbursed_amt: any

  ) => {
    setquickEditPopupData({
      application_comments: '',
      application_id: '',
      application_status: '',
      corporate_id_no: '',
      incubator_name: '',
      no_of_tranches: '',
      date_of_evaluation: '',
      total_approved_amt: '',
      incubator_application_no: '',
      total_disbursed_amt: '',
      Amount_Disbursed_to_Startups: ''

      // user_role:'',
      // logged_user_id:''
    });
    getApplicationStatus();
    // console.log(id, 'kkkk');
    post(GET_Re_QUICK_EDIT, { application_id: id }).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data, 'sri@@');
      setRoneIndex(response.data.data[0])
      if (response.data.data[0].application_comments !== '') {
        // setValidationFlag(values => ({
        //   ...values,
        //   total_approved_amt: 'false',
        //   no_of_tranches: 'false',
        //   popup_comments: 'false',
        //   // status_id:"false",
        // }));
      }
      // console.log(response.data.data[0], '1453');
      setquickEditPopupData({
        application_comments: response.data.data[0].application_comments,
        application_id: response.data.data[0].application_id,
        incubator_application_no:
          response.data.data[0].incubator_application_no,
        application_status: response.data.data[0].application_status,
        corporate_id_no: response.data.data[0].corporate_id_no,
        incubator_name: response.data.data[0].incubator_name,
        no_of_tranches:
          response.data.data[0].no_of_tranches != null
            ? response.data.data[0].no_of_tranches
            : '0',
        date_of_evaluation:
          (response.data.data[0].date_of_evaluation != "" || response.data.data[0].date_of_evaluation != null)
            ? response.data.data[0].date_of_evaluation
            : "",
        total_approved_amt:
          response.data.data[0].total_approved_amt != null
            ? response.data.data[0].total_approved_amt
            : '0',
        total_disbursed_amt:
          response.data.data[0].total_disbursed_amt_inc != null
            ? response.data.data[0].total_disbursed_amt_inc
            : '0',
        Amount_Disbursed_to_Startups:
          response.data.data[0].funds_disbursed_startup != null
            ? response.data.data[0].funds_disbursed_startup
            : '0',
      });
      // console.log(quickEditPopupData, "dat######")
      if (response.data.data[0].application_status == '7') {
        setSelectFlag(false);
      } else if (response.data.data[0].application_status != '7') {
        setSelectFlag(true);
      }
      if (response.data.requested_tranchData.length != 0) {
        setShowEditTranche(true);
      } else if (response.data.requested_tranchData.length == 0) {
        setShowEditTranche(false);
      }
    });
  };

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredData = filterByValue(incubatorList, e.target.value);
    const filteredPendingDatainfo = filterByValue(pendingData, e.target.value);
    const filteredPendingDataAllinfo = filterByValue(
      pendingDataAll,
      e.target.value
    );
    const filteredPendingDataReviewinfo = filterByValue(
      pendingDataReview,
      e.target.value
    );
    setincubatorListCountHead(filteredData.length.toString());
    setFilteredIncubatorList(filteredData);
    setFilteredPendingData(filteredPendingDatainfo);
    setFilteredPendingDataAll(filteredPendingDataAllinfo);
    setFilteredPendingDataReview(filteredPendingDataReviewinfo);
    // console.log(filteredData)
    setCurrentPage(0);
    setCurrentPage1(0);
    handlePageChange({ selected: 0 });
  };

  const downloadExcel = () => {

    var payload = {
      "incubator_ids": ExcelpaylodF1,
      "re_incubator_ids": Excelpaylod
    };
    // if (activeIndex == "tranche") {
    //   console.log("Into IF");
    //   payload = {
    //     applications_type: "incubator",
    //     trance_requested: "Yes",
    //     leagel_entity_id:
    //       selectedEntityValue.length != 0 ? selectedEntityValue : null,
    //     state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
    //     sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
    //     startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
    //     no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
    //   };
    // } else if (activeIndex == "cancel") {
    //   console.log("Into Else IF");
    //   payload = {
    //     applications_type: "incubator",
    //     cancellation_requested: "Yes",
    //     leagel_entity_id:
    //       selectedEntityValue.length != 0 ? selectedEntityValue : null,
    //     state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
    //     sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
    //     startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
    //     no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
    //   };
    // } else {
    //   console.log("Into Else");
    //   payload = {
    //     applications_type: "incubator",
    //     status_id: activeIndex,
    //     leagel_entity_id:
    //       selectedEntityValue.length != 0 ? selectedEntityValue : null,
    //     state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
    //     sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
    //     startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
    //     no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
    //   };
    //   console.log(payload);
    // }
    setLoader(true);
    post(DOWNLOAD_EXCEL_MERGED, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        console.log("@@@@@@@@@@@@@EXCEL RESPONSE @@@@@@@@@@@@@@@@@@@@@", response.data);
        var FirstApplicationData = response.data.first_application_data
        var ReApplicationData = response.data.re_application_data
        var Sheet1Name = "First Applications"
        var Sheet2Name = "Second Applications"
        var ExcelName = "Incubator_Applications.xlsx"
        exportToExcelMultipleSheets(FirstApplicationData, ReApplicationData, Sheet1Name, Sheet2Name, ExcelName);
        // console.log(`${File_node_url}` + response.data.data, "data!!!")
        // window.open(`${File_node_url}` + response.data.data)

        // downloadDocument(`${File_node_url}` + response.data.data, "incubator_list");
      }
    });
  };

  const tabActive = ((val: any) => {
    count.current = val;
    setActive_Sub_Index(val);



    if (count.current == 1) {
      // setIncReview(
      //   responsedata.filter(
      //     (item: any) => item.application_index == 'F1',
      //   ),
      // );

      // console.log(incReview);
      const currentDate = new Date(); // Get the current date    
      const formattedDate1 = new Date(currentDate.setDate(currentDate.getDate() + 90)).toString()




      const incReview1 = incReview.map(
        (item: any) => ({
          application_id: item.id,
          incubator_application_no: item.incubator_application_no,
          application_date:
            item.first_submitted_date != null
              ? FormatDate(item.first_submitted_date)
              : null,
          application_index: item.application_index,
          government_assisted: item.government_assisted,
          state_name: item.state_name,
          incubator_name: item.registered_name,
          total_approved_amt: item.amount_approved,
          total_disbursed_amt: item.total_disbursed_amt,
          // meeting_date: ((item.status_id == "3") ? ((item.meeting_date != undefined || item.meeting_date !== "") ? item.meeting_date : 'NA') :
          //   (item.status_id == "7") ? ((item.meeting_date == "" || item.meeting_date == null) ? moment(date.setDate(date.getDate() + 90)).format("YYYY-MM-DD") : item.meeting_date) : 'NA'),

          // meeting_time: ((item.status_id == "3" || item.status_id == "7") ? ((item.meeting_from_time != undefined || item.meeting_from_time !== "") ? item.meeting_from_time : 'NA') : 'NA'),


          // meeting_status:
          //   // meetingstatus,
          //   ((item.status_id == "7") ? ((item.meeting_date == null || item.meeting_date == "") ? 'Due Review' :
          //     ((item.status_id == "3") ? ((item.meeting_date == currentdate) ? 'Today' : 'scheduled') : 'Scheduled'))
          //     : 'Scheduled'),
          meeting_date: (item.status_id === 3)
            ? ((item.meeting_date === undefined) || (item.meeting_date === null))
              ? null
              : item.meeting_date

            : (item.status_id === 7)
              ? ((item.meeting_date == undefined) || (item.meeting_date == null))
                ? formattedDate1
                : item.meeting_date
              : null,
          meeting_time: ((item.status_id == "3" || item.status_id == "7") ? ((item.meeting_from_time != undefined || item.meeting_from_time !== "") ? item.meeting_from_time : 'NA') : 'NA'),
          meeting_status: (item.status_id === 3)
            ? ((item.meeting_date === undefined) || (item.meeting_date === null))
              ? 'NA'
              : (new Date(item.meeting_date).toDateString() === currentDate.toDateString()) ? "Today" : 'Scheduled'

            : (item.status_id === 7)
              ? ((item.meeting_date === undefined) || (item.meeting_date === null))
                ? 'Due Review'
                : (new Date(item.meeting_date).toDateString() === currentDate.toDateString()) ? "Today" : 'Scheduled'
              : 'NA',
          cancellation_status: item.cancellation_status
            ? item.cancellation_status
            : "",
          status_id: item.status_id,
          count_startups_supported:
            item.No_Startups_Supported != null
              ? item.No_Startups_Supported
              : "0",
          amount_disbursed:
            item.Amount_Disbursed_to_Startups != null ? item.Amount_Disbursed_to_Startups : "0",
          returns_approved: item.total_returns_approved != null ? item.total_returns_approved : "0",
          designation: item.designation,
          dipp_number: item.dipp_number,
          email: item.email,
          same_legal_entity: item.same_legal_entity,
          secondapplicationdate: item.secondapplicationdate,
          user_id: item.user_id
          // registered_name: item.registered_name,
        })
      );
      setFilteredIncubatorList([
        {
          application_id: "",
          application_date: "",
          user_id: "",
          application_index: "",
          government_assisted: "",
          state_name: "",
          incubator_name: "",
          total_approved_amt: "",
          total_disbursed_amt: "",
          meeting_date: "",
          meeting_time: "",
          cancellation_status: "",
          meeting_id: "",
          meeting_status: "",
          status_id: "",
          count_startups_supported: "",
          amount_disbursed: "",
          returns_approved: "",
          incubator_application_no: "",
          designation: '',
          dipp_number: '',
          email: '',
          same_legal_entity: '',
          secondapplicationdate: '',
          registered_name: '',
          formattedDate: ''
        },
      ]);
      // console.log(incReview1.length, "revieWW")

      setincubatorList(incReview1)
      setincubatorListCountHead(incReview1.length)

    } else if (count.current == 2) {
      const currentDate = new Date(); // Get the current date
      const formattedDate1 = new Date(currentDate.setDate(currentDate.getDate() + 90)).toString()


      // setReapplyData(
      //   responsedata.filter(
      //     (item: any) => item.application_index == 'R1',
      //   ),
      // );

      // console.log(reapplyData, "res")
      const reapplyData1 = reapplyData.map(
        (item: any) => ({
          application_id: item.id,
          incubator_application_no: item.incubator_application_no,
          application_date:
            item.first_submitted_date != null
              ? FormatDate(item.first_submitted_date)
              : null,
          application_index: item.application_index,
          government_assisted: item.government_assisted,
          state_name: item.state_name,
          incubator_name: item.registered_name,
          total_approved_amt: item.amount_approved,
          total_disbursed_amt: item.total_disbursed_amt,
          // meeting_date: ((item.status_id == "3") ? ((item.meeting_date != undefined || item.meeting_date !== "") ? item.meeting_date : 'NA') :
          //   (item.status_id == "7") ? ((item.meeting_date == "" || item.meeting_date == null) ? moment(date.setDate(date.getDate() + 90)).format("YYYY-MM-DD") : item.meeting_date) : 'NA'),

          // meeting_time: ((item.status_id == "3" || item.status_id == "7") ? ((item.meeting_from_time != undefined || item.meeting_from_time !== "") ? item.meeting_from_time : 'NA') : 'NA'),
          // meeting_status:
          // // meetingstatus,
          // ((item.status_id == "7") ? ((item.meeting_date == null || item.meeting_date == "") ? 'Due Review' :
          //   ((item.status_id == "3") ? ((item.meeting_date == currentdate) ? 'Today' : 'scheduled') : 'Scheduled'))
          //   : 'Scheduled'),

          meeting_date: (item.status_id === 3)
            ? ((item.meeting_date === undefined) || (item.meeting_date === null))
              ? null
              : item.meeting_date

            : (item.status_id === 7)
              ? ((item.meeting_date == undefined) || (item.meeting_date == null))
                ? formattedDate1
                : item.meeting_date
              : null,
          meeting_time: ((item.status_id == "3" || item.status_id == "7") ? ((item.meeting_from_time != undefined || item.meeting_from_time !== "") ? item.meeting_from_time : 'NA') : 'NA'),
          meeting_status: (item.status_id === 3)
            ? ((item.meeting_date === undefined) || (item.meeting_date === null))
              ? 'NA'
              : (new Date(item.meeting_date).toDateString() === currentDate.toDateString()) ? "Today" : 'Scheduled'

            : (item.status_id === 7)
              ? ((item.meeting_date === undefined) || (item.meeting_date === null))
                ? 'Due Review'
                : (new Date(item.meeting_date).toDateString() === currentDate.toDateString()) ? "Today" : 'Scheduled'
              : 'NA',

          cancellation_status: item.cancellation_status
            ? item.cancellation_status
            : "",


          status_id: item.status_id,
          count_startups_supported:
            item.No_Startups_Supported != null
              ? item.No_Startups_Supported
              : "0",
          amount_disbursed:
            item.Amount_Disbursed_to_Startups != null ? item.Amount_Disbursed_to_Startups : "0",
          returns_approved: item.total_returns_approved != null ? item.total_returns_approved : "0",
          designation: item.designation,
          dipp_number: item.dipp_number,
          email: item.email,
          same_legal_entity: item.same_legal_entity,
          secondapplicationdate: item.secondapplicationdate,
          user_id: item.user_id
        })
      );
      setFilteredIncubatorList([
        {
          application_id: "",
          application_date: "",
          user_id: "",
          application_index: "",
          government_assisted: "",
          state_name: "",
          incubator_name: "",
          total_approved_amt: "",
          total_disbursed_amt: "",
          meeting_date: "",
          meeting_time: "",
          cancellation_status: "",
          meeting_id: "",
          meeting_status: "",
          status_id: "",
          count_startups_supported: "",
          amount_disbursed: "",
          returns_approved: "",
          incubator_application_no: "",
          designation: '',
          dipp_number: '',
          email: '',
          same_legal_entity: '',
          secondapplicationdate: '',
          registered_name: '',
          formattedDate: ''
        },
      ]);
      // console.log(reapplyData1.length, "revieWW")
      // console.log(reapplyData1, "reapply")
      setincubatorList(reapplyData1)
      setincubatorListCountHead(reapplyData1.length)

    } else {
      // console.log(responsedata, "1452")
      // setincubatorList(responsedata);

    }

  })
  // const exportToExcel = (FirstApplicationData: any[], ReApplicationData: any[]) => {
  //   const worksheet1 = XLSX.utils.json_to_sheet(FirstApplicationData, {header:[], skipHeader:true});
  //   const worksheet2 = XLSX.utils.json_to_sheet(ReApplicationData, {header:[], skipHeader:true});
  //   const workbook = XLSX.utils.book_new(); 
  //   XLSX.utils.book_append_sheet(workbook, worksheet1, 'First Application Details'); // Sheet1 for the first array
  //   XLSX.utils.book_append_sheet(workbook, worksheet2, 'Second Application Details'); // Sheet2 for the second array
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   const fileName = 'Incubator_Applications.xlsx';
  //     const excelUrl = URL.createObjectURL(excelBlob);
  //     const a = document.createElement('a');
  //     a.href = excelUrl;
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(excelUrl);
  // };
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [itemsPerPage1, setItemsPerPage1] = useState(25);

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setItemsPerPage1(newItemsPerPage);
    setCurrentPage(0); // Reset current page to first page when items per page changes
    setCurrentPage1(0); // Reset current page to first page when items per page changes
    handlePageChange({ selected: 0 });
  };
  const handlePageChange = (selectedItem: { selected: number }) => {
    // console.log(selectedItem.selected,"currenrPage")
    setCurrentPage(selectedItem.selected);
    setCurrentPage1(selectedItem.selected);
  };
  const offset = currentPage * itemsPerPage;
  const offset1 = currentPage1 * itemsPerPage1;
  const currentPageData = incubatorList.slice(offset, offset + itemsPerPage);
  // const currentPageData1 = filteredIncubatorList.slice(offset1, offset1 + itemsPerPage1);
  var currentPageData1 = noDataFilter
    ? filteredIncubatorList.slice(offset1, offset1 + itemsPerPage1)
    : incubatorList.slice(offset1, offset1 + itemsPerPage1);
  const totalPages = Math.ceil((incubatorList.length / itemsPerPage));
  // const totalPages1 = Math.ceil( (filteredIncubatorList.length / itemsPerPage1));
  const totalPages1 = noDataFilter
    ? Math.ceil(filteredIncubatorList.length / itemsPerPage1)
    : Math.ceil(incubatorList.length / itemsPerPage1);



  return (
    <div>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        <div className="container-fluid container-min px-5 pt-5 startup-listing-wrapper">
          <div className="row mb-3">

          </div>

          <div className="tab-content">
            <div className="tab-pane active" id="tabs-1">
              <div className="row mb-3">
                <div className="col-md-6">

                </div>
                <div className="col-md-5 text-right ">
                  {/* <div className="d-flex searchDiv">
              {" "}
              <img src={search} className="search" alt="search" />
              <input
                className="searchBar"
                type="text"
                placeholder=" Search.."
                name="search"
                onKeyUp={searchList}
              />
            </div> */}
                </div>
                {/* <div className="col-md-1 filter-icon">
            <img
              src={filter}
              className="img-fluid filter"
              alt="SISFS Logo"
              data-toggle="modal"
              data-target="#filterModal"
            />
            {showFilterDot == true ? <mark></mark> : null}
          </div> */}
              </div>

              <div className="d-flex flex-row justify-content-center cardDiv">
                <div
                  className="incubator-card"
                  onClick={() =>
                    getDataByStatus(`${incubatorListId.total_id == '' ? 0 : incubatorListId.total_id}`)
                  }
                >
                  <div
                    className={
                      activeIndex == "0"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "0"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.total_id}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "0"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Total Applications
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() =>
                    getDataByStatus(`${incubatorListId.submitted_id == '' ? 2 : incubatorListId.submitted_id}`)
                  }
                >
                  <div
                    className={
                      activeIndex == "2"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "2"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.submitted}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "2"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Applications Submitted
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() =>
                    getDataByStatus(`${incubatorListId.pendingReview_id == '' ? 3 : incubatorListId.pendingReview_id}`)
                  }
                >
                  <div
                    className={
                      activeIndex == "3"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "3"
                          ? "smallIncubatorNumeric reviewed-count"
                          : "incubator-numeric-black reviewed-count"
                      }
                    >
                      {incubatorListingCounts.pendingReview}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "3"
                          ? "incubator-text-selected-small reviewed-text"
                          : "card-text-incubator reviewed-text"
                      }
                    >
                      Applications To be Reviewed by DPIIT
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() =>
                    getDataByStatus(`${incubatorListId.incomplete_id == '' ? 4 : incubatorListId.incomplete_id}`)
                  }
                >
                  <div
                    className={
                      activeIndex == "4"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "4"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.incomplete}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "4"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Applications Incomplete
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() => getDataByStatus(`${incubatorListId.onHold_id == '' ? 5 : incubatorListId.onHold_id}`)}
                >
                  <div
                    className={
                      activeIndex == "5"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "5"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.onHold}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "5"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Applications <br />
                      on Hold
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() =>
                    getDataByStatus(`${incubatorListId.selected_id == '' ? 7 : incubatorListId.selected_id}`)
                  }
                >
                  <div
                    className={
                      activeIndex == "7"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "7"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.selected}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "7"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Applications Selected
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() =>
                    getDataByStatus(`${incubatorListId.rejected_id == '' ? 6 : incubatorListId.rejected_id}`)
                  }
                >
                  <div
                    className={
                      activeIndex == "6"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "6"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.rejected}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "6"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Applications Rejected
                    </span>
                  </div>
                </div>

                <div
                  className="incubator-card"
                  onClick={() => getDataByStatus(`${incubatorListId.closed_id == '' ? 8 : incubatorListId.closed_id}`)}
                >
                  <div
                    className={
                      activeIndex == "8"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "8"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.closed}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "8"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Applications Closed
                    </span>
                  </div>
                </div>

                <div
                  className="incubator-card"
                  onClick={() => getDataByStatus("cancelled")}
                >
                  <div
                    className={
                      activeIndex == "cancelled"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                    style={{ marginRight: "20px" }}
                  >
                    <span
                      className={
                        activeIndex == "cancelled"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.cancelled}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "cancelled"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Applications Cancelled
                    </span>
                  </div>
                </div>

                <span style={{ borderLeft: "2px solid rgb(108 117 125 / 12%)" }}></span>
                <div
                  className="incubator-card"
                  onClick={() => getDataByStatus("tranche")}
                >
                  <div
                    className={
                      activeIndex == "tranche"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                    style={{ marginLeft: "20px" }}>
                    <span
                      className={
                        activeIndex == "tranche"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.trancheRequest}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "tranche"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Tranche Requested
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() => getDataByStatus("cancel")}
                >
                  <div
                    className={
                      activeIndex == "cancel"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "cancel"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.cancel}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "cancel"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Cancellation Request
                    </span>
                  </div>
                </div>
                <div
                  className="incubator-card"
                  onClick={() => getDataByStatus('entity')}>
                  <div
                    className={
                      activeIndex == 'entity'
                        ? 'rectangle-card-selected'
                        : 'rectangle-card'
                    } style={{ padding: '1px' }}>
                    <span
                      className={
                        activeIndex == 'entity'
                          ? 'smallIncubatorNumeric'
                          : 'incubator-numeric-black'
                      }>
                      {incubatorListingCounts.entity}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == 'entity'
                          ? 'incubator-text-selected-small'
                          : 'card-text-incubator'
                      }>
                      Incubators with legal entity
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="details-tabs">
                    {/* <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#tabs-1"
                                    role="tab"
                                    onClick={()=>tabActive(1)}>
                                    <p className=" pt-2 mb-2">Incubator <br /> <span>{incReview.length}</span> (Results) </p>                                    
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#tabs-3"
                                    role="tab"
                                    onClick={()=>tabActive(2)} >
                                    <p className=" pt-2 pl-3">Re-Applied Incubator <br /> <span>{reapplyData.length}</span> (Results)</p>
                                </a>
                            </li>
                        </ul> */}
                    {activeIndex != 'entity' && <div className="d-flex flex-row justify-content-center">
                      {/* {console.log(incReview.length)}
                    {console.log(reapplyData.length)} */}
                      <div
                        className="incubator-card"
                        onClick={() => tabActive(1)}
                        style={{ marginRight: '20px' }}>
                        <div
                          className={
                            Active_Sub_Index == "1"
                              ? "rectangle-card-selected-bottomBox"
                              : "rectangle-card-bottom"
                          }
                        >
                          <span
                            className={
                              Active_Sub_Index == "1"
                                ? "incubator-text-selected-small-buttom"
                                : "card-text-incubator-buttom"
                            }
                          >
                            1<sup>st</sup> Applications
                          </span>
                          <span
                            className={
                              Active_Sub_Index == '1'
                                ? "smallIncubatorNumeric_bottom_bukket"
                                : "incubator-numeric-black-buttom-bucket"
                            }
                          >
                            {incReview.length}
                          </span>
                        </div>
                      </div>
                      <div
                        className="incubator-card"
                        onClick={() => tabActive(2)}
                      >
                        <div
                          className={
                            Active_Sub_Index == '2'
                              ? "rectangle-card-selected-bottomBox"
                              : "rectangle-card-bottom"
                          }
                        >
                          <span
                            className={
                              Active_Sub_Index == '2'
                                ? "incubator-text-selected-small-buttom"
                                : "card-text-incubator-buttom"
                            }
                          >
                            2<sup>nd</sup> Applications
                          </span>
                          <span
                            className={
                              Active_Sub_Index == '2'
                                ? "smallIncubatorNumeric_bottom_bukket"
                                : "incubator-numeric-black-buttom-bucket"
                            }
                          >
                            {reapplyData.length}
                          </span>
                        </div>
                      </div>


                    </div>}

                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>

              <div className="row mt-3">
                {/* <div className="col-md-2"></div> */}


                {/* </div> */}
              </div>


              <div className="row mt-3">
                {/* <div className="col-md-2"></div> */}
                {<div className="col-md-12">
                  {activeIndex != 'entity' && <div className="row incubator-heading-div">
                    <div className="col-md-3 pt-2">
                      {
                        incubatorListCountHead != 0 ?
                          <>
                            {" "}
                            <span className="table-head">{tableHeading}</span>
                            <br />
                            <p className="count-text-incubator">
                              ({incubatorListCountHead} Results)
                            </p>
                          </>
                          : ""
                      }
                    </div>
                    {<div className="col-md-5 text-center mb-3">
                      {/* <button
                  className="tertiary btn-yellow-primary  mr-3"
                  onClick={() => {
                    setShowReport(true);
                  }}
                >
                  Download Report
                </button> */}
                      {incubatorListCountHead != 0 ?
                        <>
                          {loggedInRole !== "eac" ? (
                            <button
                              className={
                                buttonDisable == true
                                  ? "tertiary  btn-yellow-primary btn-yellow-disabled"
                                  : "tertiary  btn-yellow-primary"
                              }
                              disabled={buttonDisable}
                              data-toggle="modal"
                              data-target="#scheduleMeeting"
                            >
                              SCHEDULE A MEETING
                            </button>) : null}

                          <button
                            className="tertiary btn-yellow-primary ml-3 mt-sm-2"
                            onClick={() => downloadExcel()}
                          >
                            DOWNLOAD EXCEL
                          </button>
                        </> : ""
                      }
                    </div>}
                    <div className="col-md-3">
                      {/* {incubatorListCountHead != 0 ? */}
                      <div style={{ float: 'right', marginTop: '10px' }}>
                        <div className="d-flex searchDiv">
                          {" "}
                          <img src={search} className="search" alt="search" />
                          <input
                            className="searchBar"
                            style={{ width: 'auto' }}
                            type="text"
                            placeholder=" Search.."
                            name="search"
                            onKeyUp={searchList}
                          />
                        </div>
                      </div>
                      {/* :''} */}
                    </div>
                    <div className="col-md-1 filter-icon">
                      <div style={{ marginTop: '10px' }}>
                        <img
                          src={filter}
                          className="img-fluid filter"
                          alt="SISFS Logo"
                          data-toggle="modal"
                          data-target="#filterModal"
                        />
                        {showFilterDot == true ? <mark></mark> : null}
                      </div>
                    </div>
                  </div>
                  }
                </div>}
                {/* <div className="col-md-2"></div> */}
              </div>
              {incubatorListCountHead == 0 &&
                <div className="mt-3"></div>
              }
              {/* {console.log(incubatorList,"mainList")} */}
              <div className="row">
                {/* <div className="col-md-2"></div> */}
                <div className="col-md-12">
                  {incompleteApplications.length == 0 ? (
                    <>
                      <div className="table-scroll mb-5">
                        <table className="reviewTable" id="customTable">
                          <thead>
                            <tr>
                              {(checkBoxHide == false && loggedInRole !== "eac") ? (
                                <th className="text-left table-heading">


                                  <div className="custom-control custom-checkbox">
                                    &nbsp;&nbsp;&nbsp;&nbsp;<input
                                      type="checkbox"
                                      id="customcheckbox1"
                                      name="customcheckbox"
                                      className="custom-control-input"
                                      onChange={handleAllCheck}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customcheckbox1"
                                    ></label>
                                  </div>
                                </th>
                              ) : null}
                              <th className="text-left table-heading">
                                Incubator Name
                              </th>

                              {/* {columnHideFlag1 == true ? (
                      <th className="text-left table-heading">DIPP Number</th>
                    ) : null} */}
                              {columnHideFlag1 == true ? (
                                <th className="text-left table-heading">
                                  Second Application Requested Date
                                </th>
                              ) : null}
                              {columnHideFlag == true ? (
                                <th className="text-left table-heading">
                                  Amount Approved (&#8377;)
                                </th>
                              ) : null}
                              {columnHideFlag == true ? (
                                <th className="text-left table-heading">
                                  Amount Disbursed (&#8377;)
                                </th>
                              ) : null}
                              {columnHideFlag == true ? (
                                <th className="text-left table-heading">
                                  No. of Startups Supported{" "}
                                </th>
                              ) : null}
                              {columnHideFlag == true ? (
                                <th className="text-left table-heading custom-td">
                                  Amount Disbursed to Startups (&#8377;)
                                </th>
                              ) : null}
                              {columnHideFlag == true ? (
                                <th className="text-left table-heading custom-td">
                                  Total Returns Approved (&#8377;)
                                </th>
                              ) : null}
                              {columnHideFlag != true ? (
                                <th className="text-left table-heading">State</th>
                              ) : null}
                              {(columnHideFlag != true && columnHideFlag1 != true) ? (
                                <th className="text-left table-heading">
                                  Assisted by Central/State Govt.
                                </th>
                              ) : null}
                              {columnHideFlag != true && columnHideFlag1 != true ? (
                                <th className="text-left table-heading">
                                  Date of Application
                                </th>
                              ) : null}
                              {activeIndex == "cancel" && (
                                <th className="text-left table-heading">Status</th>
                              )}
                              {activeIndex == "cancelled" && (
                                <th className="text-left table-heading">Status</th>
                              )}
                              {(columnHideFlag1 !== true) ? (
                                <th className="text-center custom-td table-heading">
                                  Meeting Details
                                </th>) : null}
                              {/* {columnHideFlag1 == true ? (
                          <th className="text-left table-heading">
                          </th>
                        ) : null}
                        {columnHideFlag1 == true ? (
                          <th className="text-left table-heading">
                          </th>
                        ) : null} */}
                            </tr>
                          </thead>
                          <tbody>
                            {incubatorList.length == 0 ||
                              filteredIncubatorList.length == 0 || incubatorListCountHead == 0 ? (
                              <tr>
                                <td className="text-center table-heading" colSpan={7}>
                                  No data
                                </td>
                              </tr>
                            ) : filteredIncubatorList.length == 0 ||
                              filteredIncubatorList[0].application_id == "" ? (
                              incubatorList.map(
                                ({
                                  application_id,
                                  application_date,
                                  application_index,
                                  user_id,
                                  government_assisted,
                                  state_name,
                                  incubator_name,
                                  total_approved_amt,
                                  total_disbursed_amt,
                                  meeting_date,
                                  meeting_time,
                                  cancellation_status,
                                  meeting_status,
                                  status_id,
                                  count_startups_supported,
                                  amount_disbursed,
                                  returns_approved,
                                  incubator_application_no,
                                  formattedDate,
                                  dipp_number,
                                  // registered_name,

                                  secondapplicationdate,
                                }) => (
                                  <tr>
                                    {(checkBoxHide == false && loggedInRole !== "eac") ? (
                                      <td>
                                        {/* <input
                          type="checkbox"
                          disabled={isChecked}
                          onChange={handleListChange}
                          value={application_id}
                        /> */}
                                        {/* <input className="form-check-input" type="checkbox"  value={application_id}
                            id={application_id}
                            name={application_id}    disabled={isChecked}
                            onChange={handleListChange}/> */}
                                        {/* {console.log(incubator_application_no,"nooooo")} */}
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            value={incubator_application_no}
                                            id={incubator_name + "_" + application_id}
                                            name={incubator_name + "_" + application_id}
                                            className="custom-control-input"
                                            // disabled={isChecked}
                                            checked={checkedArray.includes(incubator_application_no)}
                                            onChange={handleListChange}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={
                                              incubator_name + "_" + application_id
                                            }
                                          ></label>
                                        </div>
                                      </td>
                                    ) : null}
                                    {columnHideFlag1 !== true ? (
                                      <td
                                        className="text-left custom-td"
                                        // style={{width:'300px'}}
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}>
                                        <span className="tableDataHead">
                                          {incubator_name}
                                        </span>
                                        <br />
                                        <span className="tableDataApplication">
                                          No:{' '}
                                          {incubator_application_no == null || ''
                                            ? application_id
                                            : incubator_application_no}
                                        </span>
                                      </td>
                                    ) : null}

                                    {/* {columnHideFlag1 == true ? (
                            <td
                              className="text-left custom-td"
                            //   onClick={() => handleRowChange(application_id)}
                              >
                              <span className="tableDataHead">
                                {incubator_name}
                              </span>
                              <br />
                              <span className="tableDataApplication">
                                No:{' '}
                                {incubator_application_no == null || ''
                                  ? application_id
                                  : incubator_application_no}
                              </span>
                            </td>
                          ) : null} */}
                                    {columnHideFlag1 == true ? (
                                      <td className="text-left custom-td-small customcenter">
                                        {incubator_name}
                                      </td>
                                    ) : null}

                                    {/* {columnHideFlag1 == true ? (
                            <td className="text-center custom-td-small customcenter">
                              {dipp_number}
                            </td>
                          ) : null} */}

                                    {columnHideFlag1 == true ? (
                                      <td className="text-left custom-td-left customcenter">
                                        {/* {FormatDate(secondapplicationdate)} */}
                                        {moment(secondapplicationdate).format("D MMM YYYY, h:mm a")}
                                      </td>
                                    ) : null}

                                    {/* {columnHideFlag1 == true ? (
                            <td className="text-center custom-td-small customcenter">
                              {state_name}
                            </td>
                          ) : null} */}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small customcenter"
                                      >
                                        {total_approved_amt
                                          ? changeNumberFormat(
                                            Number(total_approved_amt),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small custom-td-right"
                                      >
                                        {total_disbursed_amt
                                          ? changeNumberFormat(
                                            Number(total_disbursed_amt),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small"
                                      >
                                        {count_startups_supported
                                          ? count_startups_supported
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small custom-td-right"
                                      >
                                        {amount_disbursed
                                          ? changeNumberFormat(
                                            Number(amount_disbursed),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small custom-td-right"
                                      >
                                        {returns_approved
                                          ? changeNumberFormat(
                                            Number(returns_approved),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {(columnHideFlag != true || columnHideFlag1 == true) ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td"
                                      >
                                        {state_name}
                                      </td>
                                    ) : null}
                                    {columnHideFlag != true && columnHideFlag1 == false ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td-left"
                                      >
                                        {government_assisted}
                                      </td>
                                    ) : null}
                                    {columnHideFlag != true && columnHideFlag1 == false ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td custom-td-width"
                                      >
                                        {application_date}
                                      </td>
                                    ) : null}
                                    {activeIndex == "cancel" && (
                                      <td className="text-left custom-td">
                                        {cancellation_status}
                                      </td>
                                    )}
                                    {activeIndex == "cancelled" && (
                                      <td className="text-left custom-td">
                                        {cancellation_status}
                                      </td>
                                    )}
                                    {(columnHideFlag1 !== true) ? (
                                      <td
                                        onMouseOver={() => mouseOverEvent(application_id)}
                                        onMouseOut={() => setMouseOverFlag("")}
                                        onClick={() => {
                                          (application_index == "F1") ? quickEditData(
                                            incubator_name,
                                            application_date,
                                            status_id,
                                            application_id,
                                            total_disbursed_amt
                                          ) : ReapplyquickEditData(
                                            incubator_name,
                                            application_date,
                                            status_id,
                                            application_id,
                                            total_disbursed_amt
                                          )
                                        }
                                        }
                                        data-toggle="modal"
                                        data-target={loggedInRole !== "eac" ? "#quickEdit" : ""}
                                        className="text-center edit-column"
                                      >
                                        {mouseOverFlag == application_id ? (
                                          <img
                                            src={edit}
                                            className="search"
                                            alt="search"
                                          />
                                        ) :
                                          (meeting_date == '' && meeting_time == '') ||
                                            meeting_status == 'NA' ? (
                                            <div className="badgeGrey">
                                              <div className="badge-grey">
                                                {meeting_status}
                                              </div>
                                            </div>
                                          ) : meeting_status == 'Today' ? (
                                            <>
                                              {/* {console.log(meeting_time, 'meeting_time')} */}
                                              <div className="badge-red">
                                                Today:{' '}
                                                {meeting_time != null
                                                  ? formatTime(meeting_time)
                                                  : ''}
                                              </div>
                                              <span className="tableDataApplication">
                                                {FormatDate(meeting_date)}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <div className="badgeGrey">
                                                <div className="badge-grey">
                                                  {meeting_status}
                                                </div>
                                              </div>
                                              <span className="tableDataApplication">
                                                {FormatDate(meeting_date)}
                                              </span>
                                            </>
                                          )}

                                      </td>) : null}
                                  </tr>
                                )
                              )
                            ) : (
                              filteredIncubatorList.map(
                                ({
                                  application_id,
                                  application_date,
                                  user_id,
                                  application_index,
                                  government_assisted,
                                  state_name,
                                  incubator_name,
                                  total_approved_amt,
                                  total_disbursed_amt,
                                  meeting_date,
                                  meeting_time,
                                  meeting_status,
                                  status_id,
                                  count_startups_supported,
                                  amount_disbursed,
                                  returns_approved,
                                  incubator_application_no,
                                  dipp_number,
                                  cancellation_status,
                                  formattedDate,
                                  // registered_name,

                                  secondapplicationdate,
                                }) => (
                                  <tr>
                                    {(checkBoxHide == false && loggedInRole !== "eac") ? (
                                      <td>

                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            value={incubator_application_no}
                                            id={incubator_name + "_" + application_id}
                                            name={incubator_name + "_" + application_id}
                                            className="custom-control-input"
                                            // disabled={isChecked}
                                            checked={checkedArray.includes(incubator_application_no)}
                                            onChange={handleListChange}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={
                                              incubator_name + "_" + application_id
                                            }
                                          ></label>
                                        </div>
                                      </td>
                                    ) : null}

                                    {columnHideFlag1 !== true ? (
                                      <td
                                        className="text-left custom-td"
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}>
                                        <span className="tableDataHead">
                                          {incubator_name}
                                        </span>
                                        <br />
                                        <span className="tableDataApplication">
                                          No:{' '}
                                          {incubator_application_no == null || ''
                                            ? application_id
                                            : incubator_application_no}
                                        </span>
                                      </td>
                                    ) : null}
                                    {columnHideFlag1 == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td-small customcenter">
                                        {incubator_name}
                                      </td>
                                    ) : null}

                                    {columnHideFlag1 == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td-small customcenter">
                                        {FormatDate(secondapplicationdate)}
                                      </td>
                                    ) : null}
                                    {/* {columnHideFlag1 == true ? (
                            <td
                              onClick={() => handleRowChange(incubator_application_no)}
                              className="text-center custom-td-small customcenter">
                              {state_name}
                            </td>
                          ) : null} */}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-right custom-td-small custom-td-right"
                                      >
                                        {total_approved_amt
                                          ? changeNumberFormat(
                                            Number(total_approved_amt),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-right custom-td-small custom-td-right"
                                      >
                                        {total_disbursed_amt
                                          ? changeNumberFormat(
                                            Number(total_disbursed_amt),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small"
                                      >
                                        {count_startups_supported
                                          ? count_startups_supported
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small custom-td-right"
                                      >
                                        {amount_disbursed
                                          ? changeNumberFormat(
                                            Number(amount_disbursed),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-center custom-td-small custom-td-right"
                                      >
                                        {returns_approved
                                          ? changeNumberFormat(
                                            Number(returns_approved),
                                            1,
                                            0
                                          )
                                          : 0}
                                      </td>
                                    ) : null}
                                    {columnHideFlag != true || columnHideFlag1 == true ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td"
                                      >
                                        {state_name}
                                      </td>
                                    ) : null}
                                    {columnHideFlag != true && columnHideFlag1 == false ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td-left"
                                      >
                                        {government_assisted}
                                      </td>
                                    ) : null}
                                    {columnHideFlag != true && columnHideFlag1 == false ? (
                                      <td
                                        onClick={() => handleRowChange(incubator_application_no, user_id, application_id, application_index, status_id)}
                                        className="text-left custom-td custom-td-width"
                                      >
                                        {application_date}
                                      </td>
                                    ) : null}
                                    {activeIndex == "cancel" && (
                                      <td className="text-left custom-td">
                                        {cancellation_status}
                                      </td>
                                    )}
                                    {activeIndex == "cancelled" && (
                                      <td className="text-left custom-td">
                                        {cancellation_status}
                                      </td>
                                    )}
                                    {(columnHideFlag1 !== true) ? (
                                      <td
                                        onMouseOver={() => mouseOverEvent(application_id)}
                                        onMouseOut={() => setMouseOverFlag("")}
                                        onClick={() => {
                                          application_index == "F1" ? quickEditData(
                                            incubator_name,
                                            application_date,
                                            status_id,
                                            application_id,
                                            total_disbursed_amt
                                          ) : ReapplyquickEditData(
                                            incubator_name,
                                            application_date,
                                            status_id,
                                            application_id,
                                            total_disbursed_amt

                                          )
                                        }
                                        }
                                        data-toggle="modal"
                                        data-target={loggedInRole !== "eac" ? "#quickEdit" : ""}
                                        className="text-center edit-column"
                                      >
                                        {mouseOverFlag == application_id ? (
                                          <img
                                            src={edit}
                                            className="search"
                                            alt="search"
                                          />

                                        ) : (meeting_date == null || meeting_time == null)
                                          ? (
                                            // <>
                                            //   <div className="badge-red">
                                            //     Today:{meeting_time}
                                            //   </div>
                                            //   <span className="tableDataApplication">
                                            //     {meeting_date}
                                            //   </span>
                                            // </>
                                            <div className="badgeGrey">
                                              <div className="badge-grey">
                                                {(status_id == "3" || status_id == "7") ? meeting_status : 'NA'}
                                              </div>
                                            </div>
                                          ) : meeting_status == "Today" ? (
                                            <>
                                              <div className="badgeRed">
                                                <div className="badge-red">
                                                  Today:{" "}
                                                  {meeting_time != null
                                                    ? formatTime(meeting_time)
                                                    : ""}
                                                </div>
                                              </div>
                                              <span className="tableDataApplication">
                                                {(meeting_date !== null) ? FormatDate(meeting_date) : ""}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <div className="badgeGrey">
                                                <div className="badge-grey">
                                                  {/* {meeting_status} */}
                                                  {(status_id == "3" || status_id == "7") ? meeting_status : 'NA'}

                                                </div>
                                              </div>
                                              <span className="tableDataApplication">
                                                {((status_id == "3" || status_id == "7") ? ((meeting_date !== null) ? FormatDate(meeting_date) : "") : "")}

                                              </span>
                                            </>
                                          )}
                                      </td>) : null}
                                  </tr>
                                )
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* { incubatorList.length == 0? <></>
                    :
                        filteredIncubatorList.length == 0 ||
                        filteredIncubatorList[0].application_id == ""?
                        <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                          <span>
                          <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                          <select
                              className="itemsPerPageSelect"
                              id="itemsPerPageSelect"
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                          >
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                              <option value={incubatorList.length}>All</option>
                          </select>
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                              <ReactPaginate
                                  key={itemsPerPage} 
                                  pageCount={totalPages}
                                  pageRangeDisplayed={0}
                                  marginPagesDisplayed={0}
                                  previousLabel="Prev"
                                  nextLabel="Next"
                                  // breakLabel="..."
                                  onPageChange={handlePageChange}
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  disableInitialCallback={true}
                                  forcePage={currentPage}
                              />
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pageNum">
                              Page {(currentPage) + 1} of {totalPages}
                          </span>
                          </div>
                        
                        :<>
                              <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                <span>
                                <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                                <select
                                    className="itemsPerPageSelect"
                                    id="itemsPerPageSelect"
                                    value={itemsPerPage1}
                                    onChange={handleItemsPerPageChange}
                                >
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                    <option value={filteredIncubatorList.length}>All</option>
                                </select>
                                </span>&nbsp;&nbsp;&nbsp;

                                <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                    <ReactPaginate
                                        key={itemsPerPage1} 
                                        pageCount={totalPages1}
                                        pageRangeDisplayed={0}
                                        marginPagesDisplayed={0}
                                        previousLabel="Prev"
                                        nextLabel="Next"
                                        // breakLabel="..."
                                        onPageChange={handlePageChange}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        disableInitialCallback={true}
                                        forcePage={currentPage1}
                                    />
                                </span>&nbsp;&nbsp;&nbsp;

                                <span className="pageNum">
                                    Page {currentPage1 + 1} of {totalPages1}
                                </span>
                                </div>
                        </>
                     } */}
                    </>
                  ) : (
                    <div className="tab-content">
                      <div className="tab-pane active" id="tabs-1">
                        <div className="table-scroll">
                          <table className="reviewTable grievanceTable">
                            <thead>
                              <tr>
                                <th className="text-left table-heading custom-td-left table-heading ">
                                  Incubator Name
                                </th>
                                <th className="text-left table-heading">State</th>

                                <th className="text-left table-heading">
                                  Assisted by Central/State Govt.
                                </th>
                                <th className="text-left table-heading">
                                  Date of Application
                                </th>
                                {(columnHideFlag1 !== true) ? (
                                  <th className="text-left table-heading">
                                    Meeting Details
                                  </th>) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {pendingDataAll.length == 0 ||
                                (filteredPendingDataAll.length == 0 &&
                                  noDataFilter != "") ? (
                                <tr>
                                  <td className="text-center" colSpan={5}>
                                    No data
                                  </td>
                                </tr>
                              ) : filteredPendingDataAll.length == 0 ? (
                                pendingDataAll.map((item: any) => (
                                  <tr>
                                    <td
                                      className="text-left custom-td"
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                    >
                                      <span className="tableDataHead">
                                        {item.incubator_name}
                                      </span>
                                      <br />
                                      <span className="tableDataApplication">
                                        No:{" "}
                                        {item.incubator_application_no == null || ""
                                          ? item.application_id
                                          : item.incubator_application_no}
                                      </span>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                      className="text-left custom-td"
                                    >
                                      {item.state_name}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                      className="text-left custom-td-left"
                                    >
                                      {item.government_assisted}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                      className="text-left custom-td custom-td-width"
                                    >
                                      {FormatDate(item.application_date)}
                                    </td>
                                    {(columnHideFlag1 !== true) ? (

                                      <td
                                        data-toggle="modal"
                                        data-target={loggedInRole !== "eac" ? "#quickEdit" : ""}
                                        className="text-center custom-td"
                                        // onClick={() =>
                                        //   quickEditData(
                                        //     item.incubator_name,
                                        //     item.application_date,
                                        //     item.status_id,
                                        //     item.application_id
                                        //   )
                                        // }
                                        onClick={() => {
                                          item.application_index == "F1" ? quickEditData(
                                            item.incubator_name,
                                            item.application_date,
                                            item.status_id,
                                            item.application_id,
                                            item.total_disbursed_amt
                                          ) : ReapplyquickEditData(
                                            item.incubator_name,
                                            item.application_date,
                                            item.status_id,
                                            item.application_id,
                                            item.total_disbursed_amt

                                          )
                                        }
                                        }
                                        onMouseOver={() =>
                                          mouseOverEvent(item.application_id)
                                        }
                                        onMouseOut={() => setMouseOverFlag("")}
                                      >
                                        {mouseOverFlag == item.application_id ? (
                                          <img
                                            src={edit}
                                            className="search"
                                            alt="search"
                                            data-toggle="modal"
                                            data-target={loggedInRole !== "eac" ? "#quickEdit" : ""}
                                          />
                                        ) : (item.meeting_date == "" &&
                                          typeof item.meeting_time ==
                                          "undefined") ||
                                          item.status == "NA" ? (
                                          // <>
                                          //   <div className="badge-red">
                                          //     Today:{meeting_time}
                                          //   </div>
                                          //   <span className="tableDataApplication">
                                          //     {meeting_date}
                                          //   </span>
                                          // </>
                                          <div className="badgeGrey">
                                            <div className="badge-grey">
                                              {item.status}
                                            </div>
                                          </div>
                                        ) : item.status == "Today" ? (
                                          <>
                                            <div className="badge-red">
                                              Today:{" "}
                                              {item.meeting_time != null
                                                ? formatTime(
                                                  item.meeting_time
                                                )
                                                : ""}
                                            </div>
                                            <span className="tableDataApplication">
                                              {FormatDate(
                                                item.meeting_date
                                              )}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <div className="badgeGrey">
                                              <div className="badge-grey">
                                                {item.status}
                                              </div>
                                            </div>
                                            <span className="tableDataApplication">
                                              {FormatDate(
                                                item.meeting_date
                                              )}
                                            </span>
                                          </>
                                        )}
                                      </td>) : null}
                                  </tr>
                                ))
                              ) : (
                                filteredPendingDataAll.map((item: any) => (
                                  <tr>
                                    <td
                                      className="text-left custom-td"
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                    >
                                      <span className="tableDataHead">
                                        {item.incubator_name}
                                      </span>
                                      <br />
                                      <span className="tableDataApplication">
                                        No:{" "}
                                        {item.incubator_application_no == null || ""
                                          ? item.application_id
                                          : item.incubator_application_no}
                                      </span>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                      className="text-left custom-td"
                                    >
                                      {item.state_name}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                      className="text-left custom-td-left"
                                    >
                                      {item.government_assisted}
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleRowChange(item.incubator_application_no, item.user_id, item.application_id, item.application_index, item.status_id)
                                      }
                                      className="text-left custom-td custom-td-width"
                                    >
                                      {FormatDate(item.application_date)}
                                    </td>
                                    {(columnHideFlag1 !== true) ? (
                                      <td
                                        data-toggle="modal"
                                        data-target={loggedInRole !== "eac" ? "#quickEdit" : ""}
                                        className="text-center custom-td"
                                        // onClick={() =>
                                        //   quickEditData(
                                        //     item.incubator_name,
                                        //     item.application_date,
                                        //     item.status_id,
                                        //     item.application_id
                                        //   )
                                        // }
                                        onClick={() => {
                                          item.application_index == "F1" ? quickEditData(
                                            item.incubator_name,
                                            item.application_date,
                                            item.status_id,
                                            item.application_id,
                                            item.total_disbursed_amt
                                          ) : ReapplyquickEditData(
                                            item.incubator_name,
                                            item.application_date,
                                            item.status_id,
                                            item.application_id,
                                            item.total_disbursed_amt

                                          )
                                        }
                                        }
                                        onMouseOver={() =>
                                          mouseOverEvent(item.application_id)
                                        }
                                        onMouseOut={() => setMouseOverFlag("")}
                                      >
                                        {mouseOverFlag == item.application_id ? (
                                          <img
                                            src={edit}
                                            className="search"
                                            alt="search"
                                            data-toggle="modal"
                                            data-target={loggedInRole !== "eac" ? "#quickEdit" : ""}
                                          />
                                        ) : (item.meeting_date == "" &&
                                          typeof item.meeting_time ==
                                          "undefined") ||
                                          item.status == "NA" ? (
                                          // <>
                                          //   <div className="badge-red">
                                          //     Today:{meeting_time}
                                          //   </div>
                                          //   <span className="tableDataApplication">
                                          //     {meeting_date}
                                          //   </span>
                                          // </>
                                          <div className="badgeGrey">
                                            <div className="badge-grey">
                                              {item.status}
                                            </div>
                                          </div>
                                        ) : item.status == "Today" ? (
                                          <>
                                            <div className="badge-red">
                                              Today:{" "}
                                              {item.meeting_time != null
                                                ? formatTime(
                                                  item.meeting_time
                                                )
                                                : ""}
                                            </div>
                                            <span className="tableDataApplication">
                                              {item.meeting_status !== 'NA' ? FormatDate(
                                                item.meeting_date
                                              ) : null}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <div className="badgeGrey">
                                              <div className="badge-grey">
                                                {item.status}
                                              </div>
                                            </div>
                                            <span className="tableDataApplication">
                                              {item.meeting_status !== 'NA' ? FormatDate(
                                                item.meeting_date
                                              ) : null}
                                            </span>
                                          </>
                                        )}
                                      </td>) : null}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  )}
                </div>
                {/* <div className="col-md-2"></div> */}
              </div>



            </div>


          </div>
        </div>



        {/* modal for filter */}
        <div
          className="modal fade"
          id="filterModal"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-slideout modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal-header-bottom">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pl-5 pr-5 pt-1">
                <div className="popup-head mb-5 ml-3">
                  <div className="main-heading">Filters</div>
                </div>

                <label className="col-sm-4 col-form-head">Location</label>
                <div className="col-md-12 mb-4">
                  <MultiSelect
                    id="countryStates"
                    options={countryStates}
                    defaultText="Select State"
                    onClose={(selectedIds: string[]) =>
                      onMultiSelectChange(selectedIds, "state")
                    }
                    selectedValues={selectedStateValue ? selectedStateValue : []}
                    hasError
                    errorText=""
                    searchable
                  />
                </div>
                <label className="col-sm-4 col-form-head">Sector</label>
                <div className="col-md-12 mb-4">
                  <MultiSelect
                    id="countryStates"
                    options={sectorDetails}
                    defaultText="Select Industry"
                    onClose={(selectedIds: string[]) =>
                      onMultiSelectChange(selectedIds, "sector")
                    }
                    selectedValues={
                      selectedSectorValue ? selectedSectorValue : []
                    }
                    hasError
                    errorText=""
                    searchable
                  />
                </div>
                <label className="col-sm-4 col-form-head">Legal Entity</label>
                <div className="col-md-12 mb-4">
                  <MultiSelect
                    id="countryStates"
                    options={entityDetails}
                    defaultText="Select Industry"
                    onClose={(selectedIds: string[]) =>
                      onMultiSelectChange(selectedIds, "entity")
                    }
                    selectedValues={
                      selectedEntityValue ? selectedEntityValue : []
                    }
                    hasError
                    errorText=""
                    searchable
                  />
                </div>
                {/* <label className="col-sm-4 col-form-head">Due Review</label>
              <div className="col-md-12 mb-4 ">
                <div  className="select-custom">
                <select disabled={true}
                  className="form-control input-height"
                  aria-label="Select Entity Type"
                >
                  <option selected>Incubator Selection</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                </div>
              </div> */}
                <fieldset>
                  {/* <label className="col-sm-4 col-form-head">Tranche</label>
              <div className="col-md-12 mb-4">
              <div  className="select-custom">
                <select disabled={true}
                  className="form-control input-height"
                  aria-label="Select Entity Type"
                >
                  <option selected>Incubator Selection</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                </div>
              </div> */}
                  {/* <div className="row ml-3">
                    <div className="pt-1 mr-2">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="one_startup"
                          className="custom-control-input"
                          onChange={handleOnCheck}
                          checked={oneStartupCheckbox}
                          name="one_startup"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="one_startup"
                        ></label>
                      </div>

                    </div>
                    <div className="checkbox-text">
                      Incubators that have funded atleast 1 startup
                    </div>
                  </div>
                  <div className="row ml-3 mb-4">
                    <div className="pt-1 mr-2">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="any_startup"
                          className="custom-control-input"
                          onChange={handleOnCheck}
                          checked={noStartupCheckbox}
                          name="any_startup"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="any_startup"
                        ></label>
                      </div>

                    </div>
                    <div className="checkbox-text">
                      Incubators that have not funded any startups
                    </div>
                  </div> */}
                </fieldset>
              </div>
              <div className="modal-footer">
                <div className="row modal-row">
                  <div className="col-md-6 text-center">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      data-dismiss="modal"
                      onClick={resetFilter}
                    >
                      RESET ALL
                    </button>
                  </div>
                  <div className="col-md-6 text-left">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal"
                      onClick={applyFilter}
                    >
                      APPLY FILTER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* schedule a meeting tab */}
        <div
          className="modal fade"
          id="scheduleMeeting"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-slideout modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal-header-bottom pr-5">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => (
                    setTimeValidation(false)
                  )}
                >

                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {/* setTimeValidationCurrentTime(false) */}
              <div className="modal-body pl-5 pr-5 pt-2">
                <div className="popup-head mb-5 ml-3">
                  <div className="main-heading">Schedule a Meeting</div>
                  <div className="count-text">
                    {checkedArray.length} Incubators Selected
                  </div>
                </div>

                <label className="col-sm-4 col-form-head">Meeting Type</label>
                <div className="col-md-12 mb-4">
                  <div className="select-custom">
                    <select
                      className="form-control input-height"
                      aria-label="Select Entity Type"
                      onChange={handleOnChange}
                    >
                      {tableHeading == "Applications Selected" ? (
                        <option value="2">Performance Review Meeting</option>
                      ) : null}
                      {tableHeading == "Applications to be Reviewed" ? (
                        <option value="1">Incubator Selection</option>
                      ) : null}
                    </select>
                  </div>
                </div>
                <label className="col-sm-4 col-form-head">Date & Time</label>
                <div className="col-md-6 mb-4">
                  <Calendar
                    id="registrationDate"
                    name="registrationDate"
                    className="form-control input-height"
                    format="dd/MM/yyyy"
                    selectedDate={
                      selectedDateControl ? selectedDateControl : new Date()
                    }
                    onDateChange={(date: Date, field: string) =>
                      handleDateChange(date, field)
                    }
                  // minDate={new Date()}
                  />
                </div>
                <div className="row pl-3">
                  <div className="col-md-6">
                    <label className="col-form-head">From Time</label>
                    <input
                      type="time"
                      value={fromTime}
                      name="fromTime"
                      className="form-control input-height"
                      onChange={onTimeChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-head">To Time</label>
                    <input
                      type="time"
                      value={toTime}
                      name="toTime"
                      className="form-control input-height"
                      onChange={onTimeChange}
                    />
                  </div>
                </div>
                <div className="row text-left pl-4 mb-5">
                  {timeValidation == true ? (
                    <label className="error-message">
                      Proper Input Time is Required
                    </label>
                  ) : null}
                  {/* {timeValidationCurrentTime == true ? (
                  <label className="error-message">
                    Meeting can't be scheduled for past time
                  </label>
                ) : null} */}
                </div>
                <label className="col-sm-4 col-form-head">Meeting Link</label>
                <div className="col-md-12 mb-4">
                  <input
                    type="text"
                    id="meeting_link"
                    name="meeting_link"
                    className="form-control input-height"
                    onChange={(e: any) => setMeetingLink(e.target.value)}
                  />
                  <div className="row text-left pl-3">
                    {linkValidation == true ? (
                      <label className="error-message">
                        Meeting Link is required
                      </label>
                    ) : null}
                  </div>
                </div>
                <label
                  style={{
                    color: "#333333",
                    fontFamily: "Open Sans",
                    fontSize: "15px",
                    fontWeight: "600",
                    letterSpacing: "0.08px",
                    marginLeft: "12px",
                    marginTop: "1px",
                    paddingRight: "15px",
                  }}
                >
                  Meeting Comment
                </label>
                <div className="col-md-12 mb-4">
                  <textarea
                    style={{ height: "100px" }}
                    id="meeting_link"
                    name="meeting_link"
                    className="form-control"
                    onChange={(e: any) => setMeetingComment(e.target.value)}
                  />
                  {/* <div className="row text-left pl-3">
                  {linkValidation == true ? (
                    <label className="error-message">
                      Meeting Comment is required
                    </label>
                  ) : null}
                </div> */}
                </div>
                <div className="row pl-3">
                  <div className="col-md-6">
                    <button
                      className="btn btn-sisfs btn-transparent-primary full-width"
                      data-dismiss="modal"
                      onClick={() => (
                        setTimeValidation(false),
                        setLinkValidation(false)
                      )}
                    >
                      CANCEL
                    </button>
                  </div>
                  {/* setTimeValidationCurrentTime(false), */}
                  <div className="col-md-6">
                    <button
                      className={
                        isDisabled
                          ? "tertiary  btn-yellow-primary full-width custom-disabled"
                          : "tertiary  btn-yellow-primary full-width"
                      }
                      disabled={isDisabled}
                      onClick={scheduleMeetingFunction}
                    >
                      SCHEDULE
                    </button>
                  </div>
                </div>
                {/* {showIncubatorSuccessModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                  <div className="modal-backdrop fade show"></div>

                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionSuccess"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          <i className="fa fa-check-circle-o"></i>
                          <h3 className="modal-message">Thank You!</h3>
                          <div className="modal-text">
                            Your Meeting Has been Scheduled
                                </div>
                          <div className="modal-action">
                            <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                              Ok!!
                                </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
                {/* {showIncubatorFailureModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                  <div className="modal-backdrop fade show"></div>
                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          {/* <i className="fa fa-times-circle-o"></i> 
                          <h3 className="modal-message message-orange">
                            Oops! Something is Wrong
                              </h3><br />
                          <h3 className="modal-message message-orange">
                            Please Try Again!!!
                              </h3>
                          <div className="modal-action">
                            <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                              OK!
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
                {/* <div className="row pl-5">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  className="tertiary  btn-yellow-primary ml-3"
                  onClick={scheduleMeetingFunction}
                >
                  SCHEDULE
                </button>
              </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* quick edit popup */}
        <div
          className="modal fade"
          id="quickEdit"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-slideout modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal-header-bottom pr-5">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSelectFlag(true)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pl-5 pr-5 pt-5">
                <div className="popup-head mb-5 ml-2">
                  <div className="main-heading">
                    {quickEditPopupData.incubator_name}
                  </div>
                  <div className="count-text">
                    No:{" "}
                    {quickEditPopupData.incubator_application_no == null || ""
                      ? quickEditPopupData.application_id
                      : quickEditPopupData.incubator_application_no}
                  </div>
                </div>

                <label className="col-sm-10 col-form-head">
                  Total Grant Approved ({quickEditPopupData.no_of_tranches}{" "}
                  Tranches)
                </label>
                {showEditTranche ? (
                  <span
                    className="col-sm-4 text-left view-all"
                    data-dismiss="modal"
                  // onClick={() =>
                  // handleRowChange(quickEditPopupData.application_id,quickEditPopupData.item.user_id)
                  // }
                  >
                    {/* Edit Tranche */}
                  </span>
                ) : null}
                <div className="row pl-3">
                  <div className="col-md-11 mb-4">
                    <input
                      type="number"
                      id="total_approved_amt"
                      name="total_approved_amt"
                      className="form-control input-height"
                      value={quickEditPopupData.total_approved_amt}
                      onChange={handlePopupChange}
                      disabled={selectFlag}
                      readOnly
                    />
                  </div>
                  {/* <div className="col-md-4 mb-4">
                  <span className="view-all">View</span>
                </div> */}
                </div>

                <label className="col-sm-8 col-form-head">No. of Tranches</label>
                <div className="row pl-3">
                  <div className="col-md-11 mb-4">
                    <input
                      type="number"
                      aria-label="Select State"
                      id="no_of_tranches"
                      name="no_of_tranches"
                      className="form-control input-height"
                      value={quickEditPopupData.no_of_tranches}
                      onChange={handlePopupChange}
                      disabled={selectFlag}
                      readOnly
                    />
                  </div>
                  {/* <div className="col-md-4 mb-4">
                  <span className="view-all" >Edit</span>
                </div> */}
                </div>

                <label className="col-sm-8 col-form-head">
                  Application Status
                </label>
                <div className="row pl-3">
                  <div className="col-md-11 mb-4">
                    <div className="select-custom">
                      <select
                        className="form-control input-height"
                        aria-label="Select State"
                        id="status_id"
                        name="status_id"
                        value={quickEditPopupData.application_status}
                        onChange={handlePopupChange}
                      >
                        {applicationStatus.map(({ label, value }) =>
                          label !== "Draft" ? (
                            <option key={label} value={value}>
                              {label}
                            </option>
                          ) : null
                        )}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-4 mb-4">
                  <span className="view-all" onClick={() => setSelectFlag(false)}>Edit</span>
                </div> */}
                </div>
                <label className="col-sm-8 col-form-head">
                  Date Of Evaluation
                </label>
                {/* {console.log(quickEditPopupData, "dateEval")} */}
                <div className="row pl-3">
                  <div className="col-md-11 mb-4">
                    <fieldset disabled={selectFlag}>
                      <Calendar
                        id="registrationDate"
                        name="registrationDate"
                        className="form-control input-height"
                        format="dd/MM/yyyy"
                        selectedDate={quickEditPopupData.date_of_evaluation == "" ? null : quickEditPopupData.date_of_evaluation}
                        onDateChange={(date: Date, field: string) =>
                          handlePopupDateChange(date, field)
                        }
                        minDate={new Date()}
                      />
                    </fieldset>
                  </div>
                  {/* <div className="col-md-3 text-left mb-4">
                  <span className="view-all">View Details</span>
                </div> */}
                </div>
                <label className="col-sm-8 col-form-head">Comments, if any</label>
                <div className="row pl-3">
                  <div className="col-md-11 mb-4">
                    <textarea
                      value={quickEditPopupData.application_comments}
                      className="form-control textarea-height"
                      name="popup_comments"
                      onChange={handlePopupChange}
                    />
                  </div>
                  <div className="row text-left pl-4 mb-2">
                    {quickEditPopupData.application_comments == "" || quickEditPopupData.application_comments == null ? (
                      <label className="error-message">
                        Comment is Required
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="row pl-3">
                  <div className="col-md-6">
                    <button
                      className="btn btn-sisfs btn-transparent-primary full-width"
                      data-dismiss="modal"
                    >
                      CANCEL
                    </button>
                  </div>
                  <div className="col-md-6">
                    {RoneIndex.application_index == "R1" ? <button
                      className={
                        isDisabled
                          ? "tertiary  btn-yellow-primary full-width custom-disabled"
                          : "tertiary  btn-yellow-primary full-width"
                      }
                      disabled={isDisabled}
                      onClick={ReapplyquickEditCall}
                    >
                      UPDATE
                    </button> : <button
                      className={
                        isDisabled
                          ? "tertiary  btn-yellow-primary full-width custom-disabled"
                          : "tertiary  btn-yellow-primary full-width"
                      }
                      disabled={isDisabled}
                      onClick={quickEditCall}
                    >
                      UPDATE
                    </button>}
                  </div>
                </div>

                {/* <div className="row pl-5">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  className="tertiary  btn-yellow-primary ml-3"
                  onClick={scheduleMeetingFunction}
                >
                  SCHEDULE
                </button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        {showIncubatorSuccessModal && (
          // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
          // <>
          //   <div className="modal-backdrop fade show"></div>

          //   <div
          //     className="modal align-middle modal-sisfs d-block"
          //     id="FormSubmissionSuccess"
          //   >
          //     <div className="modal-dialog modal-dialog-centered modal-md">
          //       <div className="modal-content">
          //         <div className="modal-body text-center">
          //           <i className="fa fa-check-circle-o"></i>
          //           <h3 className="modal-message">Thank You!</h3>
          //           <div className="modal-text">
          //             Your Meeting Has been Scheduled
          //                         </div>
          //           <div className="modal-action">
          //             <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
          //               Ok!!
          //                         </button>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </>
          <ConfirmationDialog
            headerText={dialogData.header}
            bodyText={dialogData.text}
            onDialogClose={dismissModal}
          />
        )}
        {showIncubatorFailureModal && (
          // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
          // <>
          //   <div className="modal-backdrop fade show"></div>
          //   <div
          //     className="modal align-middle modal-sisfs d-block"
          //     id="FormSubmissionError"
          //   >
          //     <div className="modal-dialog modal-dialog-centered modal-md">
          //       <div className="modal-content">
          //         <div className="modal-body text-center">
          //         <img
          //       src={error}
          //       className="search mb-2"
          //       alt="search"

          //     />
          //           <h3 className="modal-message">
          //             {failDialogData.header}
          //                       </h3>
          //           <h3 className="modal-text">
          //           {failDialogData.text}
          //                       </h3>
          //           <div className="modal-action">
          //             <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
          //               OK!
          //                       </button>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </>
          <ConfirmationDialog
            headerText={failDialogData.header}
            bodyText={failDialogData.text}
            onDialogClose={() => setShowIncubatorFailureModal(false)}
            iconClass="test"
          />
        )}
        {showConfirmationBox && (
          <>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                    <h3 className="modal-message mt-2">
                      {confirmationDialogData.header}
                    </h3>
                    <h3 className="modal-text">{confirmationDialogData.text}</h3>
                    <div className="modal-action mt-2">
                      <button
                        className="btn btn-sisfs btn-transparent-primary"
                        onClick={() => setShowConfirmationBox(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={
                          isDisabled
                            ? "btn btn-sisfs btn-yellow-primary ml-2 custom-disabled"
                            : "btn btn-sisfs btn-yellow-primary ml-2"
                        }
                        disabled={isDisabled}
                        onClick={finalSchedule}
                      >
                        Schedule Anyway
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* edit success Modal */}
        {showEditSuccessModal && (
          // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
          <>
            <div className="modal-backdrop fade show"></div>

            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionSuccess"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <i className="fa fa-check-circle-o"></i>
                    <h3 className="modal-message">Thank You!</h3>
                    <div className="modal-text">
                      Application has been Updated Successfully
                    </div>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={dismissModal}
                      >
                        Ok!!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Don't Move To Submit Model*/}
        {DontMoveToSubmit && (
          <>
            <div className="modal-backdrop fade show"></div>

            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionSuccess"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <div className="modal-text">
                      <img
                        src={error}
                        className="search mb-2"
                        alt="search" /><br />
                      <h3 className="modal-message message-orange">Oops!</h3>
                      <h1 className="modal-message message-orange">Application status cannot be changed to Submitted</h1>
                    </div>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={() => {
                          setDontMoveToSubmit(false)
                        }}
                      >
                        Ok!!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* show report */}
        <Modal
          show={showReport}
          onHide={() => setShowReport(false)}
          dialogClassName="modal-90w modal-xl modal-dialog-scrollable table-bordered table-hover"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
              Download Incubator Excel Report
            </Modal.Title>
            <button
              type="button"
              onClick={() => setShowReport(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <IncubatorReportComponent />
          </Modal.Body>
        </Modal>

        {/* edit fail modal */}
        {showEditFailureModal && (
          // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
          <>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    {/* <i className="fa fa-times-circle-o"></i> */}
                    <h3 className="modal-message message-orange">
                      Oops! Something is Wrong
                    </h3>
                    <br />
                    <h3 className="modal-message message-orange">
                      Please Try Again!!!
                    </h3>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={dismissModal}
                      >
                        OK!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </fieldset>
    </div>
  );
}

export default IncAppliedReappliedContainer;
