// Development URL'S
// export const BASE_URL: string = "http://35.154.181.26:8000/api/"; //DE PHP base URL
// export const NODE_URL: string = "http://localhost:3535"; //(DE Dev Node )
// // export const NODE_URL: string =  'http://35.154.181.26:8585'  //(DE Dev Node )
// export const File_base_url: string = "http://35.154.181.26:8000/storage/";
// export const File_node_url: string = "http://35.154.181.26:8585/filestorage/";
// export const UI_URL: string = "https://devseedfund.startupindia.gov.in/"; //(Dev)

// Local Node Url & Php Url
// export const BASE_URL: string = 'http://127.0.0.1:8000/api/' //DE PHP base URL
// export const BASE_URL: string = 'http://35.154.181.26:8087/api/' //Spandana DE PHP base URL
// export const NODE_URL: string = 'http://localhost:3535'  //(Local Node API )

//************************************************************************************/
// Seedfund Dev
// export const BASE_URL: string =
//   "https://dev-sisfs-ext-alb-1867418556.ap-south-1.elb.amazonaws.com/api/"; //(SeedFundDev PHP)
// export const NODE_URL: string = "https://devseedfund.startupindia.gov.in:3535"; //(SeedFundDev Dev Node)
// export const File_base_url: string =
//   "https://dev-sisfs-ext-alb-1867418556.ap-south-1.elb.amazonaws.com/storage/";
// export const UI_URL: string = "https://devseedfund.startupindia.gov.in/"; //(Dev)
// export const File_node_url: string =
//   "https://dev-sisfs-ext-alb-1867418556.ap-south-1.elb.amazonaws.com:3535/filestorage/";
//************************************************************************************/
// // Seedfund Prod
export const BASE_URL: string = 'https://seedfundapi.startupindia.gov.in/api/' //(Production)
export const NODE_URL: string = 'https://seedfundapi.startupindia.gov.in:3535' //(SeedFundDev Prod Node)
export const File_base_url: string = 'https://seedfundapi.startupindia.gov.in/storage/'
export const UI_URL: string = "https://seedfund.startupindia.gov.in/" //(Production)
export const File_node_url: string = 'https://seedfundapi.startupindia.gov.in:3535/filestorage/'
//************************************************************************************/

// Incubator checkbox download field
// export const EVAL_REMOVE: string = BASE_URL + 'startup/detail/removeFile';
export const CHECK_BOX_DATA: string =
  BASE_URL + "applications/incubator/downloadExcelFields";
export const INCUBATOR_DOWNLOAD_EXCEL: string =
  BASE_URL + "applications/incubator/downloadExcel";
export const INCUBATOR_DOWNLOAD_EXCEL_DATA: string = `http://local.api.seedfund.com/ApplicationsdownlodExcel/temp/Incubator_Applications_20220503
190039.xlsx`;
// startup Check box download fields
// export const STARTUP_CHECK_BOX_DATA: string = BASE_URL + 'applications/startup/downloadExcelFields'
export const STARTUP_CHECK_BOX_DATA: string =
  NODE_URL + "/applications/startup/downloadExcelFields";
// export const STARTUP_DOWNLOAD_EXCEL: string = BASE_URL + 'applications/startup/downloadExcel'
export const STARTUP_DOWNLOAD_EXCEL: string =
  NODE_URL + "/applications/startup/downloadExcel/0";

// export const INCUBATOR_PREFRENCE_LIST: string = BASE_URL + 'incubator/prefrence/list'
// export const INCUBATOR_PREFRENCE_LIST_POST: string = BASE_URL + 'incubator/prefrence/post'
// export const INCUBATOR_PREFRENCE_GETLOGS: string = BASE_URL + 'incubator/prefrence/getLogs'
// NODE URL'S:
// export const sec_re_updateTranche: string = NODE_URL + '/api/dpiit/reapply_sec_updateTranche';
export const GET_APPLCAITON_COMMENT_NODE: string =
  NODE_URL + "/api/meetings/getApplicationComment";
export const GET_ALL_TRANCHES_NODE: string =
  NODE_URL + "/api/getAllForApplication";
export const GET_APPLICATION_LIST_NODE: string =
  NODE_URL + "/api/getIncubatorApplications/reapply_inc_list";
export const STORE_TRANCHE_REQUEST_NODE: string =
  NODE_URL + "/api/incubator/fundDetails/reapply_storeTrancheRequest";
export const GET_ACTIVE_TRANCHE_REQUEST_NODE: string =
  NODE_URL + "/api/incubator/fundDetails/reapply_getActiveTrancheRequest";
export const GET_Re_TRANCHE: string = NODE_URL + "/api/getAllForApplication";
export const Tranche_amount_Re_update: string =
  NODE_URL + "/api/dpiit/reapply_sec_updatetranche";
export const MEETINGS_RE_STORE: string =
  NODE_URL + "/api/dpiit/meetings/reapply_sec_store";
export const QUICK_Re_EDIT: string =
  NODE_URL + "/api/dashboard/incubator/quickEdit";
export const sec_re_updateTranche: string =
  NODE_URL + "/api/dpiit/reapply_sec_updateTranche";
export const ADD_TRANCHE_WITH_Re_AMOUNT: string =
  NODE_URL + "/api/dpiit/addTrancheWithAmount";
export const GET_STORE_INCUBATOR_INFO: string =
  NODE_URL + "/api/incubator/getincubatorinfo";
export const GET_STORE_INCUBATOR_DATA: string =
  NODE_URL + "/api/incubator/reapplystore";
export const GET_INCUBATOR_DETAILS_NODE: string =
  NODE_URL + "/api/dashboard/incubator";
export const GET_RE_APPLICATION_VIEW: string =
  NODE_URL + "/api/incubator/getreapplylistdtls";
export const GET_Re_APPLICATION_LIST: string =
  NODE_URL + "/api/incubator/getreapplylist";
export const getsecincubatorslist: string =
  NODE_URL + "/api/SecReapply_incubator";
export const SecReapplyincubatorapplications: string =
  NODE_URL + "/api/SecReapply_incubator_applications";
export const GET_Re_QUICK_EDIT: string =
  NODE_URL + "/api/dashboard/incubator/reapplygetQuickEdit ";
export const DELETE_Re_APPLICATION: string =
  NODE_URL + "/api/incubator/reapply_draft_applc_del";
export const GET_Re_ALL_INCUBATORS: string =
  NODE_URL + "/api/reviews/getAllIncubators";
export const GET_SUM_DISBURSED_AMOUNT: string =
  NODE_URL + "/api/incubator/sum_disbursed_amount";

export const CANCELLATION_Re_REQUEST: string =
  NODE_URL + "/api/incubator/reapply_application_cancel";
export const LEGAL_RE_ENTITY: string =
  NODE_URL + "/api/incubator/reapply_legal_entity_approval";
export const secappdetailsform3_NODE: string =
  NODE_URL + "/api/sec_app_detailsform3";
export const secappdetails_RE_FORM2: string =
  NODE_URL + "/api/sec_app_detailsform2";

export const STORE_BANK_DETAILS_Reapply_Node: string =
  NODE_URL + "/api/incubator/reapply_bank_details_update";
export const GET_BANK_DETAILS_REAPPLY_NODE: string =
  NODE_URL + "/api/incubator/get_reapply_bank_details";
// export const DOWNLOAD_EXCEL_REAPPLY_TAB: string = BASE_URL + 're-applications/downloadExcel'

export const getApplicationDetails2_3_NODE: string =
  NODE_URL + "/api/getApplicationDetails2";
export const getApplicationDetails_3_NODE: string =
  NODE_URL + "/api/getApplicationDetails3";

export const GENERATE_REPORT_RE_EXCEL: string =
  BASE_URL + "re-applications/incubator/downloadExcel";
// export const CHECK_BOX_Re_DATA: string = BASE_URL + 're-applications/incubator/downloadExcelFields'
export const MERGED_BUCKET_COUNT: string = NODE_URL + "/sec_bucketsCount";
export const MERGED_ALL_TABLE_LIST: string = NODE_URL + "/sec_application_list";
export const MERGED_SCHEDULE_MEETING: string =
  NODE_URL + "/sec_meeting_schedule";
export const mergedfirstapplicationdetails: string =
  NODE_URL + "/api/sec/merged_first_application_details";
export const mergedsecndapplicationdetails: string =
  NODE_URL + "/api/dashboard/incubator";
export const getallDocuments: string = NODE_URL + "/api/sec/getallDocuments";
export const getallEACComments: string =
  NODE_URL + "/api/sec/getallEACComments";
export const secgreybox: string = NODE_URL + "/sec_greybox";
export const secincapplicationdetails: string =
  NODE_URL + "/sec_inc_application_details";
export const MERGED_COMMENTS: string = NODE_URL + "/api/sec/getallcomments";
export const GET_ALL_INCUBATORS: string =
  NODE_URL + "/api/reviews/getAllIncubators";
export const MERGED_FUND_DETAILS: string = NODE_URL + "/sec_fundDetails";
export const MERGED_BANK_DETAILS: string =
  NODE_URL + "/sec_funddetails_bankdetails";
export const ADD_INCUBATOR: string = NODE_URL + "/sec_EAC_view_Addbutton";
// export const SELECTED_STARTUP_DOWNLOAD_EXCEL: string = BASE_URL + 'applications/startup/downloadExcel/7'
export const TRANCHES_EDIT: string = NODE_URL + "/sec_funddetails_overalledit";
export const TRANCHE_UPLOAD_SANCTION_NODE: string =
  NODE_URL + "/sec_funddetails_sancation_upload";

export const NEW_EVALUATION_FILE: string =
  NODE_URL + "/startup_form_samplefile";
export const SEC_DASHBOARD_COUNT: string =
  NODE_URL + "/sec_dashboard_bucketcount";
export const GET_ALL_INCUBATORS_NODE: string =
  NODE_URL + "/sec_dashboard_meetings_view";

export const Node_GET_PORTFOLIO: string = NODE_URL + "/portfolioList";
export const Node_GET_PORTFOLIO_DETAIL: string = NODE_URL + "/portfolioDesc/";
export const DELETE_REAPPLY_TRANCHE: string =
  NODE_URL + "/deleteINCReapplyTranche";
export const DELETE_TRANCHE: string = NODE_URL + "/deleteINCTranche";
export const DELETE_STARTUP_TRANCHE: string =
  NODE_URL + "/deletestartupTranche";

export const GET_ALL_STATES: string = NODE_URL + "/api/getStates";
export const CONTACT_STATE: string = NODE_URL + "/api/getContactUsStates";
export const GET_ALL_INDUSTRIES: string =
  NODE_URL + "/api/getAttributeData/industry";
export const GET_ALL_INDUSTRY_SECTORS: string =
  NODE_URL + "/api/getAttributeData/sector";
export const GET_ALL_DISTRICTS: string = NODE_URL + "/api/getDistrict";

//startup APIs
export const STARTUP_ALL_APPLICATIONS: string =
  NODE_URL + "/api/startupMyApplications";
export const NOTIFICATION_LIST: string = NODE_URL + "/api/notification/list";
export const GET_GRIEVANCE_LIST: string = NODE_URL + "/api/grievance/list";
export const CONTACT_LIST: string = NODE_URL + "/api/contact/data";
export const GET_STARTUP_MEETINGS: string =
  NODE_URL + "/startup/meetings/getMeetingDetails";
export const EVALUATION_FILE: string = NODE_URL + "/api/evaluation-file";
export const ALLOW_INCUBATOR_CREATION: string =
  NODE_URL + "/incubatorApplication/allowCreation";
export const STARTUP_GET_BANK_DETAILS: string =
  NODE_URL + "/startup/bankDetails/get";
export const STARTUP_STORE_BANK_DETAILS: string =
  NODE_URL + "/startup/bankDetails/post";
export const STARTUP_ACCOUNT_DOCUMENT_UPLOAD: string =
  NODE_URL + "/startup/bankDetails/upload";
export const STARTUP_ACCOUNT_DOCUMENT_DELETE: string =
  NODE_URL + "/startup/bankDetails/remove";
export const CANCELLATION_REQUEST_STARTUP: string =
  NODE_URL + "/startup/cancel/raise";
export const PROGRESS_STAGES: string =
  NODE_URL + "/api/startup/progressOverview/stages";
export const PROGRESS_PERCENTAGE: string =
  NODE_URL + "/api/startup/progressOverview/percentage";
export const PROGRESS_DATA_BY_PROGRESS_ID: string =
  NODE_URL + "/api/startup/progressOverview/getById";
export const PROGRESS_OVERVIEW_DETAIL_BY_APPLICATION_ID: string =
  NODE_URL + "/api/startup/progressOverview/get?startup_application_id=";
export const STARTUP_GET_DETAILS: string =
  NODE_URL + "/api/startupGetEntityDetail";
export const NOTIFICATION_LIST_STATUS_CHANGE_ALL: string =
  NODE_URL + "/api/notification/statusChangeAll";
export const STARTUP_GET_TRANCHE_STATUS: string =
  NODE_URL + "/api/startup/getTrancheStatus";
export const PROGRESS_OVERVIEW_FORM: string =
  NODE_URL + "/api/startup/new/progressOverview";
export const PROGRESS_OVERVIEW_FILE_UPLOAD: string =
  NODE_URL + "/api/startup/progressOverview/upload";
export const PROGRESS_OVERVIEW_EDIT_FORM: string =
  NODE_URL + "/api/startup/progressOverview/updateRow";
export const GET_ALL_STARTUP_TRANCHES: string =
  NODE_URL + "/startup/getAllTranchesForApplication";
export const STARTUP_GET_INCUBATORS: string = NODE_URL + "/api/startup";
export const STORE_STARTUP_APPLICATION_NODE: string = NODE_URL + "/api/startup";
export const STORE_STARTUP_APPLICATION_DELETE: string = NODE_URL + "/startup";
export const STARTUP_STORE_TRANCHE_REQUEST: string =
  NODE_URL + "/startup/storeTrancheRequest";
export const EXPENSE_BUCKET_OPTION: string =
  NODE_URL + "/startup/expenseBucketOptions";
export const GET_STARTUP_ELIGIBILITY: string =
  NODE_URL + "/startup/eligibility";
export const STARTUP_DETAIL_UPLOAD: string =
  NODE_URL + "/startup/detail/uploadFiles";
export const STARTUP_FILE_REMOVE: string =
  NODE_URL + "/api/startup/file/remove";
export const STARTUP_GET_ACTIVE_TRANCHE_REQUEST: string =
  NODE_URL + "/startup/GetActiveTrancheRequest";
export const PROGRESS_OVERVIEW_VALIDATE: string =
  NODE_URL + "/startup/progressOverview/approve";
export const UPLOAD_DISBURSEMENT_PROOF_STARTUP: string =
  NODE_URL + "/api/startup/cancel/UploadDisbursementProof"; //have to check in UI
export const STARTUP_TRANCHE_UPLOAD_DOCUMENT: string =
  NODE_URL + "/startup/uploadTrancheDocument";
export const STARTUP_TRANCHE_REMOVE_DOCUMENT: string =
  NODE_URL + "/startup/removeTrancheDocument";
export const REPLACE_FILE: string = NODE_URL + "/api/incubatorFiles/replace"; //have to check in UI
export const STARTUP_FILE_UPLOAD: string =
  NODE_URL + "/api/startup/file/upload";
export const STORE_STARTUP_APPLICATION_SAVE: string =
  NODE_URL + "/startup/save";
export const CANCEL_STATUS_UPDATE_StARTUP: string =
  NODE_URL + "/startup/cancel/incubatorComment";
export const ADD_COMMENT: string = NODE_URL + "/api/grievance/comment";
// export const GRIEVANCE_DETAILS: string = NODE_URL + '/api/grievance/detail';
export const EDIT_STATUS: string = NODE_URL + "/api/grievance/statusChange";
export const CONTACT_US: string = NODE_URL + "/api/contact_us";

//incubator APIs
export const CONTACT_CITY: string = NODE_URL + "/api/getCountactusDistrict";
export const GRIEVANCE_STORE: string = NODE_URL + "/api/grievance/store";
export const GET_TRANCHE_STATUS: string = NODE_URL + "/api/tranche/getStatus";
export const GET_BANK_DETAILS: string =
  NODE_URL + "/api/incubator/bankDetails/get";
export const GET_ALL_TRANCHES: string =
  NODE_URL + "/api/tranche/getAllForApplication";
export const GET_ACTIVE_TRANCHE_REQUEST: string =
  NODE_URL + "/api/incubator/fundDetails/getActiveTrancheRequest";
// export const GET_STARTUP_LISTING_NODE: string = NODE_URL + '/api/dashboard/startup/application_list';
// export const GET_STARTUP_LISTING_COUNT: string = NODE_URL + '/api/dashboard/startup/mainCount';
export const STARTUP_SCHEDULE_MEETING: string =
  NODE_URL + "/api/startup/meetings/store";
export const GET_MEETING_DETAILS: string =
  NODE_URL + "/reviews/getMeetingDetails";
export const GET_MEETINGS: string = NODE_URL + "/api/incubator/meetings";
export const STARTUP_SEARCH: string =
  NODE_URL + "/startup/meetings/startupsSearch";
export const GET_ALL_STARTUPS: string =
  NODE_URL + "/startup/meetings/getAllStartups";
export const STARTUP_MEETING_GET_BY_ID: string =
  NODE_URL + "/startup/meetings/getById";
export const STARTUP_MEETING_UPDATE_BY_ID: string =
  NODE_URL + "/meetings/updateById";
export const GET_REAPPLY_APPLICATION: string =
  NODE_URL + "/incubatorApplication/allowReApplication";
export const GET_APPLICATION_LIST: string =
  NODE_URL + "/getIncubatorApplications/list";
export const GET_ASSISTED_INCUBATOR: string =
  NODE_URL + "/api/getAttributeData/government_assisted_incubator";
export const GET_LEGAL_ENTITY: string =
  NODE_URL + "/api/getAttributeData/legal_entity";
export const GET_ALL_LEGAL_ENTITY: string =
  NODE_URL + "/api/getAttributeData/legal_entity";
export const GET_ALL_SECTOR: string = NODE_URL + "/api/getAttributeData/sector";
export const GET_APPLICATION_VIEW: string =
  NODE_URL + "/api/incubatorApplication/view";
export const CREATE_EMPTY_APPLICATION: string =
  NODE_URL + "/api/incubatorApplication/createEmptyApplication";
export const FILE_UPLOAD: string = NODE_URL + "/api/incubatorFiles/upload";
// export const DOWNLOAD_EXCEL: string = NODE_URL + '/api/applications/downloadExcel'
export const STARTUP_DOCUMENT_UPLOAD: string =
  NODE_URL + "/api/startup/meetings/uploadFile";
export const STARTUP_DOCUMENT_REMOVE: string =
  NODE_URL + "/api/startup/meetings/remove";
export const GET_STARTUP_APPLICATION_STATUS: string =
  NODE_URL + "/api/startup/getStatus";
export const STARTUP_CANCELLATION_DETAIL: string =
  NODE_URL + "/startup/cancel/details";
export const CANCEL_DATE_UPDATE_INCUBATOR: string =
  NODE_URL + "/api/startup/cancel/incubatorUpdateDateFund";
export const UPDATE_FILE_CANCELLATION_INCUBATOR: string =
  NODE_URL + "/startup/cancel/incubatorUploadProofFund";
export const UPLOAD_FILE1: string =
  NODE_URL + "/startup/detail/uploadFilesMultiple";
// export const QUICK_STARTUP_MEETING: string = NODE_URL + '/api/startup/meetings/quick';
export const GET_APPLICATION_COMMENT_STARTUP: string =
  NODE_URL + "/startup/meetings/getApplicationComment";
export const SAVE_MEETING_COMMENT_STARTUP: string =
  NODE_URL + "/startup/meetings/saveApplicationComment";
// export const FILE_REMOVE: string = NODE_URL + '/api/incubatorFiles/remove';
export const VIEW_STARTUP_APPLICATION: string =
  NODE_URL + "/startup/app_detail";

export const FILE_REMOVE: string = NODE_URL + "/api/incubatorFiles/remove";
export const REVIEW_UPDATE: string =
  NODE_URL + "/api/startup/meetings/reveiwUpdate";
export const STARTUP_ADD_TRANCHE_WITH_AMOUNT: string =
  NODE_URL + "/startup/addTrancheWithAmount";
export const GET_STARTUP_ENGAGEMENT_STATUS: string =
  NODE_URL + "/api/startup/getEngagementStatus";
export const STARTUP_UPDATE_TRANCHE: string =
  NODE_URL + "/startup/updateTranche";
export const DELETE_APPLICATION: string =
  NODE_URL + "/incubatorApplication/delete";
export const EVAL_REMOVE: string = NODE_URL + "/startup/detail/removeFile";

export const TRANCHES_STARTUP_EDIT: string =
  NODE_URL + "/api/dpiit/editTrancheWithAmount";
export const UPDATE_TRANCHE: string = NODE_URL + "/api/dpiit/updateTranche";
export const DPIIT_TRANCHE: string = NODE_URL + "/api/dpiit/tranche/addComment";
export const TRANCHE_UPLOAD_SANCTION: string =
  NODE_URL + "/sec_funddetails_sancation_upload";

export const UPLOAD_DISBURSEMENT_PROOF: string =
  NODE_URL + "/incubator/cancel/UploadDisbursementProof";

export const RE_CANCEL_STATUS_UPDATE: string =
  NODE_URL + "/re-incubator/cancel/dpiitComment";
export const Re_TRANCHE_UPLOAD_DOCUMENT: string =
  NODE_URL + "/re-incubator/fundDetails/uploadDocument";
export const RE_CANCEL_DATE_UPDATE: string =
  NODE_URL + "/re-incubator/cancel/dpiitUpdateDateFund";
export const RE_UPDATE_FILE_CANCELLATION: string =
  NODE_URL + "/re-incubator/cancel/dpiitUploadProofFund";

export const CANCEL_STATUS_UPDATE: string =
  NODE_URL + "/incubator/cancel/dpiitComment";
export const CANCEL_DATE_UPDATE: string =
  NODE_URL + "/incubator/cancel/dpiitUpdateDateFund";
export const UPDATE_FILE_CANCELLATION: string =
  NODE_URL + "/incubator/cancel/dpiitUploadProofFund";

export const GET_QUICK_EDIT: string =
  NODE_URL + "/dashboard/incubator/getQuickEdit";
export const GET_MEETINGS_BY_ID: string = NODE_URL + "/meetings/getById";

export const ACCOUNT_DOCUMENT_UPLOAD: string =
  NODE_URL + "/api/incubator/bankDetails/upload";
export const ACCOUNT_DOCUMENT_REMOVE: string =
  NODE_URL + "/api/incubator/bankDetails/remove";
export const CANCELLATION_REQUEST: string =
  NODE_URL + "/api/incubator/cancel/raise";
export const STORE_BANK_DETAILS: string =
  NODE_URL + "/api/incubator/bankDetails/post";

export const STORE_INCUBATOR_APPLICATION: string =
  NODE_URL + "/api/incubatorApplication/store";
export const GRAPH_BAR: string = NODE_URL + "/dashboard/startupGraph";
export const GET_STARTUP_LISTING: string =
  NODE_URL + "/api/dashboard/startup/applications";
export const GET_STARTUP_LISTING_DUPLICATE_API: string =
  NODE_URL + "/dashboard/startup/sec_startup_applications";

export const QUICK_EDIT: string = NODE_URL + "/dashboard/incubator/quickEdit";
export const ADD_TRANCHE_WITH_AMOUNT: string =
  NODE_URL + "/dpiit/addTrancheWithAmount";
//secretariat
export const GET_APPLICATION_STATUS: string =
  NODE_URL + "/api/getApplicationStatus";
export const RE_INCUBATOR_CANCEL_DETAIL: string =
  NODE_URL + "/api/re-incubator/cancel/detail";
export const PENDING_STARTUP_COUNT: string =
  NODE_URL + "/api/startup/meetings/pendingStartupsCount";
// export const QUICK_EDIT: string = NODE_URL + '/dashboard/incubator/quickEdit'; // response is different from php need to bind
export const SCHEDULE_MEETING_COUNT: string =
  NODE_URL + "/api/meetings/getCounts"; // data wrong
export const PENDING_INCUBATOR_COUNT: string =
  NODE_URL + "/api/meetings/pendingIncubatorCount";
export const GET_DPIIT_DASHBOARD_COUNT: string = NODE_URL + "/dashboard/count";

// export const DOWNLOAD_EXCEL_MERGED: string = BASE_URL + 'all-inc-applications/downloadExcel';
// export const LOGIN: string = BASE_URL + 'login';
export const LOGIN: string = NODE_URL + "/api/login";
// export const LOGIN_STARTUP: string = BASE_URL + 'investindiaLogin';

// export const STARTUP_ALL_APPLICATIONS: string = BASE_URL + 'startupMyApplications';
// export const NOTIFICATION_LIST: string = BASE_URL + 'notification/list';
// export const GET_GRIEVANCE_LIST: string = BASE_URL + 'grievance/list';
// export const CONTACT_LIST: string = BASE_URL + 'contact/data';

// export const CONTACT_STATE: string = BASE_URL + 'getContactUsStates'
// export const GET_ALL_STATES: string = BASE_URL + 'getStates';
// export const GET_ALL_DISTRICTS: string = BASE_URL + 'getDistrict';
// export const GET_ALL_INDUSTRY_SECTORS: string = BASE_URL + 'getAttributeData/sector';
// export const GET_ALL_INDUSTRIES: string = BASE_URL + 'getAttributeData/industry';
// export const GET_STARTUP_MEETINGS: string = BASE_URL + 'startup/meetings/getMeetingDetails';
// export const EVALUATION_FILE: string = BASE_URL + 'evaluation-file';
// export const ALLOW_INCUBATOR_CREATION: string = BASE_URL + 'incubatorApplication/allowCreation';
// export const STARTUP_GET_BANK_DETAILS: string = BASE_URL + 'startup/bankDetails/get';
// export const STARTUP_STORE_BANK_DETAILS: string = BASE_URL + 'startup/bankDetails/post';
// export const STARTUP_ACCOUNT_DOCUMENT_UPLOAD: string = BASE_URL + 'startup/bankDetails/upload';
// export const CANCELLATION_REQUEST_STARTUP: string = BASE_URL + 'startup/cancel/raise';
// export const PROGRESS_STAGES: string = BASE_URL + 'startup/progressOverview/stages';
// export const PROGRESS_PERCENTAGE: string = BASE_URL + 'startup/progressOverview/percentage';
// export const PROGRESS_DATA_BY_PROGRESS_ID: string = BASE_URL + 'startup/progressOverview/getById';
// export const PROGRESS_OVERVIEW_DETAIL_BY_APPLICATION_ID: string = BASE_URL + 'startup/progressOverview/get?startup_application_id=';
// export const STARTUP_GET_DETAILS: string = BASE_URL + 'startupGetEntityDetail';
// export const NOTIFICATION_LIST_STATUS_CHANGE_ALL: string = BASE_URL + 'notification/statusChangeAll';
// export const STARTUP_GET_TRANCHE_STATUS: string = BASE_URL + 'startup/getTrancheStatus';
// export const PROGRESS_OVERVIEW_FORM: string = BASE_URL + 'startup/progressOverview';
// export const PROGRESS_OVERVIEW_FILE_UPLOAD: string = BASE_URL + 'startup/progressOverview/upload'
// export const PROGRESS_OVERVIEW_EDIT_FORM: string = BASE_URL + 'startup/progressOverview/updateRow'
// export const GET_ALL_STARTUP_TRANCHES: string = BASE_URL + 'startup/getAllTranchesForApplication';
// export const STARTUP_GET_INCUBATORS_BASE: string = BASE_URL + 'startup';
// export const STARTUP_STORE_TRANCHE_REQUEST: string = BASE_URL + 'startup/storeTrancheRequest';
// export const UPLOAD_DISBURSEMENT_PROOF_STARTUP: string = BASE_URL + 'startup/cancel/UploadDisbursementProof';
export const STORE_STARTUP_APPLICATION: string = BASE_URL + "startup";
// export const STARTUP_TRANCHE_UPLOAD_DOCUMENT: string = BASE_URL + 'startup/uploadTrancheDocument';
// export const STARTUP_TRANCHE_REMOVE_DOCUMENT: string = BASE_URL + 'startup/removeTrancheDocument';
// export const REPLACE_FILE: string = BASE_URL + 'incubatorFiles/replace';
// export const STARTUP_FILE_UPLOAD: string = BASE_URL + 'startup/file/upload';
// export const CANCEL_STATUS_UPDATE_StARTUP: string = BASE_URL + 'startup/cancel/incubatorComment';

// export const ADD_COMMENT: string = BASE_URL + 'grievance/comment';
// export const GRIEVANCE_DETAILS: string = BASE_URL + 'grievance/detail';
// export const EDIT_STATUS: string = BASE_URL + 'grievance/statusChange';
// export const CONTACT_US: string = BASE_URL + 'contact_us';

//incubator incubator incubator incubator incubator incubator//

// export const CONTACT_CITY: string = BASE_URL + 'getCountactusDistrict'
// export const GRIEVANCE_STORE: string = BASE_URL + 'grievance/store';
// export const GET_TRANCHE_STATUS: string = BASE_URL + 'tranche/getStatus';
// export const GET_BANK_DETAILS: string = BASE_URL + 'incubator/bankDetails/get';
// export const GET_ALL_TRANCHES: string = BASE_URL + 'tranche/getAllForApplication';
// export const GET_ACTIVE_TRANCHE_REQUEST: string = BASE_URL + 'incubator/fundDetails/getActiveTrancheRequest';
// export const GET_STARTUP_LISTING: string = BASE_URL + 'dashboard/startup/applications';
// export const STARTUP_SCHEDULE_MEETING: string = BASE_URL + 'startup/meetings/store';
// export const GET_MEETING_DETAILS: string = BASE_URL + 'reviews/getMeetingDetails';
// export const GET_MEETINGS: string = BASE_URL + 'incubator/meetings';
// export const STARTUP_MEETING_GET_BY_ID: string = BASE_URL + 'startup/meetings/getById';
// export const STARTUP_MEETING_UPDATE_BY_ID: string = BASE_URL + 'startup/meetings/updateById';
// export const GET_ALL_STARTUPS: string = BASE_URL + 'startup/meetings/getAllStartups';
// export const STARTUP_SEARCH: string = BASE_URL + 'startup/meetings/startupsSearch';
// export const GET_APPLICATION_LIST: string = BASE_URL + 'getIncubatorApplications/list';
// export const EXPENSE_BUCKET_OPTION: string = BASE_URL + 'startup/expenseBucketOptions';
// export const GET_STARTUP_ELIGIBILITY: string = BASE_URL + 'startup/eligibility';
// export const STARTUP_DETAIL_UPLOAD: string = BASE_URL + 'startup/detail/uploadFiles'
// export const STARTUP_FILE_REMOVE: string = BASE_URL + 'startup/file/remove';
// export const STARTUP_GET_ACTIVE_TRANCHE_REQUEST: string = BASE_URL + 'startup/getActiveTrancheRequest';
// export const PROGRESS_OVERVIEW_VALIDATE: string = BASE_URL + 'startup/progressOverview/approve';
// export const SCHEDULE_MEETING_COUNT: string = BASE_URL + 'meetings/getCounts';
// export const PENDING_INCUBATOR_COUNT: string = BASE_URL + 'meetings/pendingIncubatorCount';
// export const GET_DPIIT_DASHBOARD_COUNT: string = BASE_URL + 'dashboard/count';
// export const STARTUP_CANCELLATION_DETAIL: string = BASE_URL + 'startup/cancel/details';
// export const CANCEL_DATE_UPDATE_INCUBATOR: string = BASE_URL + 'startup/cancel/incubatorUpdateDateFund';
// export const UPDATE_FILE_CANCELLATION_INCUBATOR: string = BASE_URL + 'startup/cancel/incubatorUploadProofFund';
// export const UPLOAD_FILE1: string = BASE_URL + 'startup/detail/uploadFilesMultiple';
export const QUICK_STARTUP_MEETING: string =
  BASE_URL + "startup/meetings/quick";
// export const GET_APPLICATION_COMMENT_STARTUP: string = BASE_URL + 'startup/meetings/getApplicationComment';
// export const SAVE_MEETING_COMMENT_STARTUP: string = BASE_URL + 'startup/meetings/saveApplicationComment';
// export const FILE_REMOVE: string = BASE_URL + 'incubatorFiles/remove';

// export const GET_ASSISTED_INCUBATOR: string = BASE_URL + 'getAttributeData/government_assisted_incubator';
// export const GET_LEGAL_ENTITY: string = BASE_URL + 'getAttributeData/legal_entity';
// export const GET_ALL_LEGAL_ENTITY: string = BASE_URL + 'getAttributeData/legal_entity';
// export const GET_ALL_SECTOR: string = BASE_URL + 'getAttributeData/sector';
// export const GET_APPLICATION_VIEW: string = BASE_URL + 'incubatorApplication/view';
// export const CREATE_EMPTY_APPLICATION: string = BASE_URL + 'incubatorApplication/createEmptyApplication';
// export const FILE_UPLOAD: string = BASE_URL + 'incubatorFiles/upload';
// export const GET_APPLICATION_STATUS: string = BASE_URL + 'getApplicationStatus';
// export const RE_INCUBATOR_CANCEL_DETAIL: string = BASE_URL + 're-incubator/cancel/detail';
// export const PENDING_STARTUP_COUNT: string = BASE_URL + 'startup/meetings/pendingStartupsCount';
// export const QUICK_EDIT: string = BASE_URL + 'dashboard/incubator/quickEdit';
// export const DOWNLOAD_EXCEL: string = BASE_URL + 'applications/downloadExcel'
// export const STARTUP_DOCUMENT_UPLOAD: string = BASE_URL + 'startup/meetings/uploadFile';
// export const STARTUP_DOCUMENT_REMOVE: string = BASE_URL + 'startup/meetings/removeFile';
// export const GET_STARTUP_APPLICATION_STATUS: string = BASE_URL + 'startup/getStatus';
// export const VIEW_STARTUP_APPLICATION: string = BASE_URL + 'startup/app_detail';

// export const STORE_INCUBATOR_APPLICATION: string = BASE_URL + 'incubatorApplication/store';
export const GET_ACTIVE_APPLICATION: string =
  BASE_URL + "incubatorApplication/activeApplication";
// export const GET_DPIIT_DASHBOARD_CHART_COUNT: string = BASE_URL + 'dashboard/incubatorCount';
// export const GET_DPIIT_DASHBOARD_CHART_COUNT_STARTUP: string = BASE_URL + 'dashboard/startupCount';
export const GET_INCUBATOR_LISTING_COUNT: string =
  BASE_URL + "dashboard/incubator";
export const SCHEDULE_MEETING: string = BASE_URL + "meetings/store";
// export const TRANCHES_STARTUP_EDIT: string = BASE_URL + 'dpiit/editTrancheWithAmount';
export const INCUBATOR_APPLICATION_COUNT: string =
  NODE_URL + "/sec_dashboard_incApplication_bar";
export const MERGED_FILTER: string = NODE_URL + "/sec_filter_submit";
export const MERGED_FILTER_BUCKET_LIST: string =
  NODE_URL + "/sec_filter_bucket_list";
export const EAC_VIEW_SEARCH: string = NODE_URL + "/sec_EAC_view_searchbutton";
export const REMOVE_INCUBATOR_NODE: string =
  NODE_URL + "/sec_EAC_remove_button";

export const NEWSLETTER_SUBSCRIBE: string = NODE_URL + "/newsletter/subscribe";
export const INCUBATOR_SEARCH: string = NODE_URL + "/meetings/incubatorSearch";
export const EAC_REVIEW_UPDATE: string = NODE_URL + "/meetings/eacReveiwUpdate";
export const DOWNLOAD_EXCEL_MERGED: string =
  NODE_URL + "/all-inc-applications/downloadExcel";
export const SAVE_APPLCAITON_COMMENT: string =
  NODE_URL + "/meetings/saveApplicationComment";
// export const QUICK_MEETINGS: string = NODE_URL + '/meetings/quick';
export const MEETING_FILE_UPLOAD: string = NODE_URL + "/meetings/upload";
export const MEETING_FILE_REMOVE: string = NODE_URL + "/meetings/remove";
export const USER_LIST: string = NODE_URL + "/admin/list";
export const INCUBATOR_PREFRENCE_LIST: string =
  NODE_URL + "/incubator/prefrence/list";
export const INCUBATOR_PREFRENCE_LIST_POST: string =
  NODE_URL + "/incubator/prefrence/post";
export const INCUBATOR_PREFRENCE_GETLOGS: string =
  NODE_URL + "/incubator/prefrence/getLogs";
export const GRIEVANCE_DETAILS: string = NODE_URL + "/grievance/detail";

// export const REMOVE_INCUBATOR: string = BASE_URL + 'meetings';
export const REMOVE_INCUBATOR: string = NODE_URL + "/api/meetings";
// export const QUICK_MEETINGS: string = BASE_URL + 'meetings/quick';
export const GET_INCUBATOR_DETAILS: string = BASE_URL + "dashboard/incubator";
export const GET_EAC_EVALUATION_QUESTIONS: string =
  BASE_URL + "eac/getEvaluationQuestion";
export const SAVE_EAC_EVALUATION_QUESTIONS: string =
  BASE_URL + "eac/evaluation";
// export const GET_QUICK_EDIT: string = BASE_URL + 'dashboard/incubator/getQuickEdit';
export const GET_EAC_QUESTION_DETAILS: string =
  BASE_URL + "eac/getEvaluationQuestionDetails";
// export const DELETE_APPLICATION: string = BASE_URL + 'incubatorApplication/delete';
export const COUNT_SUBIMTTED: string =
  BASE_URL + "incubatorApplication/countSubmitted";
// export const GET_MEETINGS_BY_ID: string = BASE_URL + 'meetings/getById';
// export const UPDATE_MEETING: string = BASE_URL + 'meetings/updateById';
export const UPDATE_MEETING: string = NODE_URL + "/api/meetings/updateById";
// export const EAC_REVIEW_UPDATE: string = BASE_URL + 'meetings/eacReveiwUpdate';
// export const INCUBATOR_SEARCH: string = BASE_URL + 'meetings/incubatorSearch';
// export const MEETING_FILE_UPLOAD: string = BASE_URL + 'meetings/upload';
// export const MEETING_FILE_REMOVE: string = BASE_URL + 'meetings/remove';
// export const TRANCHE_UPLOAD_DOCUMENT: string = BASE_URL + 'incubator/fundDetails/uploadDocument';
export const TRANCHE_REMOVE_DOCUMENT: string =
  BASE_URL + "incubator/fundDetails/removeDocument";
// export const STORE_TRANCHE_REQUEST: string = BASE_URL + 'incubator/fundDetails/storeTrancheRequest';
export const GET_TRANCHE_REQUEST: string =
  BASE_URL + "incubator/fundDetails/getTrancheRequest";
// export const SAVE_APPLCAITON_COMMENT: string = BASE_URL + 'meetings/saveApplicationComment ';
export const GET_APPLCAITON_COMMENT: string =
  BASE_URL + "meetings/getApplicationComment";
// export const FULL_REPORT: string = BASE_URL + 'dashboard/fullReport';
// export const STORE_BANK_DETAILS: string = BASE_URL + 'incubator/bankDetails/post';
export const ADD_MORE_TRANCHE: string = BASE_URL + "dpiit/addMoreTranche";
// export const UPDATE_TRANCHE: string = BASE_URL + 'dpiit/updateTranche';
// export const ADD_TRANCHE_WITH_AMOUNT: string = BASE_URL + 'dpiit/addTrancheWithAmount';
// export const GET_TRANCHE_BY_APPLICATION_ID: string = BASE_URL + 'tranche/getAllForApplication';
// export const NEWSLETTER_SUBSCRIBE: string = BASE_URL + 'newsletter/subscribe';
// export const DPIIT_TRANCHE: string = BASE_URL + 'dpiit/tranche';
export const INCUBATOR_FUNDDETAILS: string = BASE_URL + "incubator/fundDetails";
// export const FORGOT_PASSWORD: string = BASE_URL + 'forgot-password';
export const FORGOT_PASSWORD: string = NODE_URL + "/forgot-password";
// export const RESET_PASSWORD: string = BASE_URL + 'reset-password';
export const RESET_PASSWORD: string = NODE_URL + "/reset-password";
// export const ACCOUNT_DOCUMENT_UPLOAD: string = BASE_URL + 'incubator/bankDetails/upload';
// export const ACCOUNT_DOCUMENT_REMOVE: string = BASE_URL + 'incubator/bankDetails/remove';
// export const REGISTER_USER: string = BASE_URL + 'admin/register';
export const REGISTER_USER: string = NODE_URL + "/admin/register";
// export const USER_LIST: string = BASE_URL + 'admin/list';
// export const USER_ACTIVE_INACTIVE: string = BASE_URL + 'admin/activeInactive';
export const USER_ACTIVE_INACTIVE: string =
  NODE_URL + "/api/admin/activeInactive";
export const GET_STARTUP_LISTING_FILTER: string =
  BASE_URL + "dashboard/startup/application";
// export const REVIEW_UPDATE: string = BASE_URL + 'startup/meetings/reveiwUpdate';
// export const GET_STARTUP_ENGAGEMENT_STATUS: string = BASE_URL + 'startup/getEngagementStatus';
// export const CANCELLATION_REQUEST: string = BASE_URL + 'incubator/cancel/raise';
// export const INCUBATOR_CANCEL_DETAIL: string = BASE_URL + 'incubator/cancel/detail';
export const INCUBATOR_CANCEL_DETAIL: string =
  NODE_URL + "/api/incubator/cancel/detail";
// export const STARTUP_ADD_TRANCHE_WITH_AMOUNT: string = BASE_URL + 'startup/addTrancheWithAmount';
// export const CANCEL_STATUS_UPDATE: string = BASE_URL + 'incubator/cancel/dpiitComment';
// export const CANCEL_DATE_UPDATE: string = BASE_URL + 'incubator/cancel/dpiitUpdateDateFund';
// export const UPDATE_FILE_CANCELLATION: string = BASE_URL + 'incubator/cancel/dpiitUploadProofFund';
// export const NOTIFICATION_LIST_STATUS_CHANGE: string = BASE_URL + 'notification/statusChange';
export const NOTIFICATION_LIST_STATUS_CHANGE: string =
  NODE_URL + "/api/notification/statusChange";
// export const STARTUP_UPDATE_TRANCHE: string = BASE_URL + 'startup/updateTranche';
// export const UPLOAD_DISBURSEMENT_PROOF: string = BASE_URL + 'incubator/cancel/UploadDisbursementProof';
export const STARTUP_ADD_COMMENT: string = BASE_URL + "startup/tranche";
export const UPLOAD_FILE: string = BASE_URL + "startup/detail/uploadFiles";
// export const SCHEDULE_MEETING_COUNT_STARTUP: string = BASE_URL + 'startup/meetings/getCounts';
export const SCHEDULE_MEETING_COUNT_STARTUP: string =
  NODE_URL + "/api/startup/meetings/getCounts";
// export const GET_TOP_INCUBATORS: string = BASE_URL + 'dashboard/topIncubators';
// export const GRAPH_BAR: string = BASE_URL + 'dashboard/startupGraph'
// export const GET_PORTFOLIO: string = BASE_URL + 'portfolio'
// export const GET_PORTFOLIO_DETAIL: string = BASE_URL + 'portfolio/detail/'
// export const SHOW_DASHBOARD: string = BASE_URL + 'incubator/dashboard'
// export const TRANCHE_UPLOAD_SANCTION: string = BASE_URL + 'dpiit/tranche/uploadSanction'
export const TRANCHE_FILE_REMOVE: string = BASE_URL + "dpiit/tranche/remove";

// export const RE_CANCEL_STATUS_UPDATE: string = BASE_URL + 're-incubator/cancel/dpiitComment';
// export const RE_CANCEL_DATE_UPDATE: string = BASE_URL + 're-incubator/cancel/dpiitUpdateDateFund';
// export const RE_UPDATE_FILE_CANCELLATION: string = BASE_URL + 're-incubator/cancel/dpiitUploadProofFund';

// export const Re_TRANCHE_UPLOAD_DOCUMENT: string = BASE_URL + 're-incubator/fundDetails/uploadDocument';

// export const DOWNLOAD_EXCEL: string = NODE_URL + '/applications/downloadExcel'

export const REMOVE_INCUBATOR_ADD_NODE: string = NODE_URL + "/api/meetings";

export const GET_DPIIT_DASHBOARD_CHART_COUNT: string =
  NODE_URL + "/dashboard/incubatorCount";
export const SHOW_DASHBOARD: string = NODE_URL + "/incubator/dashboard";
export const GET_TOP_INCUBATORS: string = NODE_URL + "/dashboard/topIncubators";
export const FULL_REPORT: string = NODE_URL + "/api/dashboard/fullReport";

export const STORE_TRANCHE_REQUEST: string =
  NODE_URL + "/incubator/fundDetails/storeTrancheRequest";
export const TRANCHE_UPLOAD_DOCUMENT: string =
  NODE_URL + "/incubator/fundDetails/uploadDocument";
export const DOWNLOAD_EXCEL: string = NODE_URL + "/applications/downloadExcel";
export const DOWNLOAD_EXCEL_REAPPLY_TAB: string =
  NODE_URL + "/re-applications/downloadExcel";
export const CHECK_BOX_Re_DATA: string =
  NODE_URL + "/re-applications/incubator/downloadExcelFields";
export const SELECTED_STARTUP_DOWNLOAD_EXCEL: string =
  NODE_URL + "/applications/startup/downloadExcel/7";
export const CLOSED_STARTUP_DOWNLOAD_EXCEL: string =
  NODE_URL + "/applications/startup/downloadExcel/8";

// export const GRAPH: string = BASE_URL + 'dashboard/graph';
// export const GRAPH: string = NODE_URL + 'dashboard/graph';

export const STARTUP_GET_INCUBATORS_BASE: string = NODE_URL + "/startup";
export const GRAPH_NODE: string = NODE_URL + "/dashboard/graph";

export const LOGIN_STARTUP: string = NODE_URL + "/api/investindiaLogin";

export const GET_DPIIT_DASHBOARD_CHART_COUNT_STARTUP: string =
  NODE_URL + "/dashboard/startupCount";
export const INCUBATOE_RETURNS_FORM: string =
  NODE_URL + "/api/startup/report_returns/store";
export const INCUBATOE_RETURNS_GET_FORM_DATA: string =
  NODE_URL + "/get/report_returns";
export const INCUBATOE_RETURNS_FORM_Edit: string =
  NODE_URL + "/api/startup/report_returns/edit";
export const INCUBATOE_RETURNS_FORM_Delete: string =
  NODE_URL + "/api/startup_report_returns/delete";
export const SEC_RETURNS_GET_FORM_DATA: string =
  NODE_URL + "/get/approved/incubator/returns";
export const SEC_RETURNS_FORM_DATA: string =
  NODE_URL + "/api/approved/incubator/returns";
export const SEC_RETURNS_FORM_Delete: string =
  NODE_URL + "/api/approved/incubator/returns/delete";
export const SEC_RETURNS_FORM_Edit: string =
  NODE_URL + "/api/approved/incubator/returns/update";
export const FUNDDETAILS_FILEUPLOAD: string =
  NODE_URL + "/api/tra_statement/fileuploads";
export const FUNDDETAILS_GET_API_FILEUPLOAD: string =
  NODE_URL + "/api/tra_statement_get_files";
export const QUARTERLY_REPORTS_PROGRESS_TAB: string =
  NODE_URL + "/api/quarterlyreports";
export const QUATERLY_REVIEW_FUNDDETAILS: string =
  NODE_URL + "/api/fund_details/quaterly_review";
export const QUATERLY_REVIEW_RETURNS: string =
  NODE_URL + "/api/returns/quaterly_review";
export const QUATERLY_SUCCESS: string =
  NODE_URL + "/api/incubator/quaterly_review/confirmation";
export const SEC_RETURNS_EXCEL_DOWNLOAD: string =
  NODE_URL + "/api/report_returns_download";

export const QUARTERLY_R_ACTIVATE: string =
  NODE_URL + "/api/quarterly_review/activate";
export const QUARTERLY_R_DEACTIVATE: string =
  NODE_URL + "/api/quarterly_review/deactivate";
export const QUARTERLY_R_BUCKET_COUNT: string =
  NODE_URL + "/api/quarterly_review/get_buckets_count";
export const QUARTERLY_R_GET_ACTIVE: string =
  NODE_URL + "/api/quarterly_review/get_active_QR";
export const QUARTERLY_R_TABLE_LIST: string =
  NODE_URL + "/api/quarterly_review/get_incubators_list";
export const QUARTERLY_R_DETAILS: string =
  NODE_URL + "/QR_Deatils/year_quarter";
export const QUARTERLY_R_CLOSE: string =
  NODE_URL + "/api/close/QuarterlyReview";

export const QUARTERLY_R_REMINDER: string = NODE_URL + "/api/send/reminders/QR";
export const PRPGRESS_REVIEW_FORM_GET_API: string =
  NODE_URL + "/api/get/quarterlyreports";
export const PRPGRESS_REVIEW_DROUPDOWN_API: string =
  NODE_URL + "/api/get_dropdown_values";
export const INSTRUMENT_ANALYSIS_GRAPH: string =
  NODE_URL + "/api/instrument_analysis_graph";
export const QR_EXCEL_DOWNLOAD: string = NODE_URL + "/api/download/QR_excel";
export const REMOVE_MEETING: string = NODE_URL + "/api/removeMeeting";
export const GET_PORTFOLIO_LIST: string = NODE_URL + "/api/getportfoliolist";
export const GET_PORTFOLIO_LIST_DESC: string =
  NODE_URL + "/api/getportfoliolistDesc/";
export const CHECK_PORTFOLIO_ELIGIBILTY: string =
  NODE_URL + "/api/IncubatorPortfolioCheckEligibility";
export const INC_NAMES_DROPDOWN: string = NODE_URL + "/api/getIncubatorNames";
export const POST_PORTFOLIODATA: string =
  NODE_URL + "/api/IncubatorPostPortfolio";
export const GET_PORTFOLIO_DETAILS: string =
  NODE_URL + "/api/getprortfolioDetails";
export const PORTFOLIO_REQUEST: string =
  NODE_URL + "/api/portfoliorequestreview";
export const REMOVE_IMAGE: string = NODE_URL + "/api/removeportfoliofilepath";
export const GET_CITIES: string = NODE_URL + "/api/getMultipleCities";
export const PREVIOUS_QR_DETAILS: string =
  NODE_URL + "/api/get/previousQuarterDetails";
export const GRANT_BALANCE: string =
  NODE_URL + "/api/getIncubatorInstrumentTypeDetails";
export const GET_ASSOCIATION_DATE: string =
  NODE_URL + "/api/getIncubatorDetails";
export const PORTFOLIO_FILTER: string = NODE_URL + "/api/portfoliofilter";
export const SEND_TOKEN_TO: string = NODE_URL + "/logintoseedfund";
export const SUBSEQUENT_UPLOAD: string =
  NODE_URL + "/api/incubator/tranche/subsequent/upload";
export const GET_SUBSEQUENT_UPLOADS: string =
  NODE_URL + "/api/incubator/tranche/subsequent/getFiles";
export const Subsequent_ST_File_upload: string =
  NODE_URL + "/api/dashboard/startup/applications/upload";
export const Get_St_Application_File: string =
  NODE_URL + "/api/dashboard/startup/Getapplications";
export const UPDATE_FILE_STATUS: string =
  NODE_URL + "/api/sec/tranche/subsequent/updateStatus";
export const INC_TRANCHE1_UPLOAD: string =
  NODE_URL + "/api/incubator/trancheOne/subsequent/upload";
export const INC_TRANCHE1_GETFILES: string =
  NODE_URL + "/api/incubator/trancheOne/subsequent/getFiles";
export const SEC_UPDATE_STATUS: string =
  NODE_URL + "/api/sec/trancheOne/subsequent/updateStatus";
export const SEC_TRANCHE1_SUBMIT: string =
  NODE_URL + "/api/sec/trancheOne/subsequent/submit";
export const TRANCHE_STATUS_LOGS: string =
  NODE_URL + "/api/getIncubatorTranchelogs";
export const FETCH_ST_CANCEL_DETAILS: string =
  NODE_URL + "/api/FetchStartupcancelationdetails";
export const SUBSEQUENT_ST_APPLICATIONS: string =
  NODE_URL + "/api/subsequent/startup/applications";
export const SPOC_DETAILS: string = NODE_URL + "/api/getIncubatorSpocDetails";
export const UPDATE_SPOC_DETAILS: string =
  NODE_URL + "/api/UpdateIncubatorSpocDetails";
export const GET_NOTIFICATIONS: string = NODE_URL + "/api/GetNotices";
export const POST_NOTIFICATIONS: string = NODE_URL + "/api/PostNotices";
export const UPDATE_NOTIFICATIONS: string = NODE_URL + "/api/UpdateNotices";
export const DELETE_NOTIFICATIONS: string = NODE_URL + "/api/DeleteNotice";
export const GET_STARTUP_PORTFOLIO_LIST: string =
  NODE_URL + "/api/getStartupportfoliolist";
export const GET_ALL_CITIES: string = NODE_URL + "/api/getCities";
export const GET_STARTUP_PORTFOLIO_LIST_DESC: string =
  NODE_URL + "/api/getStartupportfolioportfolioDesc/";
export const INC_SEND_TOKEN_TO: string = NODE_URL + "/Inclogintoseedfund";
